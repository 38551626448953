import { EMAIL_LOGOUT_SUCCESS } from "../actions/auth";
import {
  GET_VENDORS_ATTEMPT,
  GET_VENDORS_SUCCESS,
  GET_VENDORS_ERROR,
  ADD_VENDOR_ATTEMPT,
  ADD_VENDOR_SUCCESS,
  ADD_VENDOR_ERROR,
  RESET_ADD_VENDOR,
  EDIT_VENDOR_ATTEMPT,
  EDIT_VENDOR_SUCCESS,
  EDIT_VENDOR_ERROR,
  RESET_EDIT_VENDOR,
} from "../actions/vendors";

const defaultState = {
  vendors: [],

  getVendorsAttempt: false,
  getVendorsSuccess: false,
  getVendorsError: false,
  getVendorsErrorMessage: "",

  addVendorAttempt: false,
  addVendorSuccess: false,
  addVendorError: false,
  addVendorErrorMessage: "",

  editVendorAttempt: false,
  editVendorSuccess: false,
  editVendorError: false,
  editVendorErrorMessage: "",
};

export const vendorsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case GET_VENDORS_ATTEMPT:
      return {
        ...state,
        getVendorsAttempt: true,
        getVendorsSuccess: false,
        getVendorsError: false,
        getVendorsErrorMessage: "",
      };
    case GET_VENDORS_SUCCESS:
      return {
        ...state,
        getVendorsAttempt: false,
        getVendorsSuccess: true,
        getVendorsError: false,
        getVendorsErrorMessage: "",
        vendors: action.data,
      };
    case GET_VENDORS_ERROR:
      return {
        ...state,
        getVendorsAttempt: false,
        getVendorsSuccess: false,
        getVendorsError: true,
        getVendorsErrorMessage: action.data,
      };

    case ADD_VENDOR_ATTEMPT:
      return {
        ...state,
        addVendorAttempt: true,
        addVendorSuccess: false,
        addVendorError: false,
        addVendorErrorMessage: "",
      };

    case ADD_VENDOR_SUCCESS:
      return {
        ...state,
        addVendorAttempt: false,
        addVendorSuccess: true,
        addVendorError: false,
        addVendorErrorMessage: "",
        vendors: [...state.vendors, action.data],
      };

    case ADD_VENDOR_ERROR:
      return {
        ...state,
        addVendorAttempt: false,
        addVendorSuccess: true,
        addVendorError: true,
        addVendorErrorMessage: action.data,
      };

    case RESET_ADD_VENDOR:
      return {
        ...state,
        addVendorAttempt: false,
        addVendorSuccess: false,
        addVendorError: false,
        addVendorErrorMessage: "",
      };

    case EDIT_VENDOR_ATTEMPT:
      return {
        ...state,
        editVendorAttempt: true,
        editVendorSuccess: false,
        editVendorError: false,
        editVendorErrorMessage: "",
      };

    case EDIT_VENDOR_SUCCESS:
      return {
        ...state,
        editVendorAttempt: false,
        editVendorSuccess: true,
        editVendorError: false,
        editVendorErrorMessage: "",
        vendors: state.vendors.map((vendor) => {
          if (vendor.id === action.data.id) {
            return { ...action.data };
          }
          return vendor;
        }),
      };

    case EDIT_VENDOR_ERROR:
      return {
        ...state,
        editVendorAttempt: false,
        editVendorSuccess: false,
        editVendorError: true,
        editVendorErrorMessage: action.data,
      };

    case RESET_EDIT_VENDOR:
      return {
        ...state,
        editVendorAttempt: false,
        editVendorSuccess: false,
        editVendorError: false,
        editVendorErrorMessage: "",
      };

    case EMAIL_LOGOUT_SUCCESS:
      return defaultState;

    default:
      return state;
  }
};
