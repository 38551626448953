/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import "./HomeScreen.css";
import MyCarousel from "../../components/Carousel/Carousel";
import HomeScreenProducts from "../../components/HomeScreenProducts/HomeScreenProducts";
import { useDispatch } from "react-redux";
import {
  getProductsAttempt,
  getSpecialProductsAttempt,
  getTopSellingProductsAttempt,
  getNewProductsAttempt,
} from "../../redux/actions/products";
import { getCategoryAttempt } from "../../redux/actions/categories";
import { getProductsForOfflineSaveAttempt } from "../../redux/actions/offlineData";
import { useNetwork } from "../../utils/checkInternet";

const HomeScreen = () => {
  const dispatch = useDispatch();
  const isConnected = useNetwork();

  useEffect(() => {
    document.title = "Miraco - Home";
    window.scrollTo({
      top: 0,
    });
    dispatch(getProductsAttempt({ search: "" }));
    dispatch(getNewProductsAttempt());
    dispatch(getSpecialProductsAttempt());
    dispatch(getTopSellingProductsAttempt());
    dispatch(getCategoryAttempt());
  }, [dispatch]);

  useEffect(() => {
    if (isConnected) {
      dispatch(getProductsForOfflineSaveAttempt());
    }
  }, [isConnected]);

  return (
    <>
      <MyCarousel />
      <HomeScreenProducts />
    </>
  );
};

export default HomeScreen;
