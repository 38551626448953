import React, { useState, useEffect } from "react";
import { Form, Row, Col, Container, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import styles from "./GetResetCodeScreen.module.css";
import { useDispatch, useSelector } from "react-redux";
import { getResetCodeAttempt } from "../../../redux/actions/auth";
import { canBeSubmitted, showEmailError } from "../../../utils/validate";
import Loader from "../../../components/Loader/Loader";
import ErrorMessage from "../../../components/ErrorMessage/ErrorMessage";
import { useNetwork } from "../../../utils/checkInternet";

const GetResetCodeScreen = () => {
  const isConnected = useNetwork();

  useEffect(() => {
    document.title = "Miraco - Enter your email";
    window.scrollTo({
      top: 0,
    });
  }, []);

  const dispatch = useDispatch();
  const { getResetCodeAttempt: loading, getResetCodeErroMessage: error } =
    useSelector((state) => state.auth);
  const [email, setEmail] = useState("");
  const [emailChanged, setEmailChanged] = useState(false);
  const [change, setChange] = useState(false);

  const isDisabled = !canBeSubmitted(email) || loading || !isConnected;

  const submitHandler = (e) => {
    e.preventDefault();
    if (!isDisabled) {
      setChange(true);
      dispatch(
        getResetCodeAttempt({
          email: email,
        })
      );
    }
  };

  return (
    <div className={styles.loginPage}>
      <Container>
        <Row className="justify-content-md-center">
          <Col xs={12} md={6}>
            <Form onSubmit={submitHandler} className={styles.loginForm}>
              <h3 className={styles.signInText}>
                ENTER EMAIL TO RECEIVE VALIDATION CODE
              </h3>
              <Form.Group controlId="email">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  className={styles.myInput}
                  type="email"
                  placeholder="Enter email"
                  value={email}
                  onBlur={() => setEmailChanged(true)}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setChange(false);
                  }}
                ></Form.Control>
                {showEmailError(email, emailChanged)}
              </Form.Group>
              <div className={styles.commonGoToDiv}>
                <Link className={styles.commonGoTLink} to={"/login"}>
                  Back to login
                </Link>
              </div>
              {change && error && <ErrorMessage>{error}</ErrorMessage>}
              <Button
                type="submit"
                disabled={isDisabled}
                variant="btn btn-sm btn-outline-success rounded notInTableButton"
              >
                {loading ? <Loader /> : "Get my code"}
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default GetResetCodeScreen;
