import ErrorMessage from "../components/ErrorMessage/ErrorMessage";

function emailchecker(email) {
  // eslint-disable-next-line no-useless-escape
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}
export const validateEmailAndPassword = (email, password) => {
  // true means invalid, so our conditions got reversed
  return {
    email: email?.length === 0 || !emailchecker(email),
    password: password?.length === 0,
  };
};

export const canBeSubmitted = (email, password) => {
  let isDisabled = true;
  const errors = validateEmailAndPassword(email, password);
  isDisabled = Object.keys(errors).some((x) => errors[x]);
  return !isDisabled;
};

export const showEmailError = (email, emailChanged) => {
  if (!emailchecker(email) && emailChanged) {
    return <ErrorMessage>Please enter valid email address</ErrorMessage>;
  }
};

export const showPasswordError = (password, passwordChanged) => {
  if (password.length < 1 && passwordChanged) {
    return <ErrorMessage>Minimum password length is 1 character</ErrorMessage>;
  }
};

export const validateBillingForm = (
  country,
  address,
  townCity,
  state,
  zip,
  email,
  phone
) => {
  // true means invalid, so our conditions got reversed
  return {
    country: country?.length === 0,
    address: address?.length === 0,
    townCity: townCity?.length === 0,
    state: state?.length === 0,
    zip: zip?.length === 0,
    email: email?.length === 0 || !emailchecker(email),
    phone: phone?.length === 0,
  };
};

export const billingCanBeSubmited = (
  country,
  address,
  townCity,
  state,
  zip,
  email,
  phone
) => {
  let isDisabled = true;
  const errors = validateBillingForm(
    country,
    address,
    townCity,
    state,
    zip,
    email,
    phone
  );
  isDisabled = Object.keys(errors).some((x) => errors[x]);
  return !isDisabled;
};
