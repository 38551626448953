export const GET_VENDORS_ATTEMPT = "GET_VENDORS_ATTEMPT";
export const GET_VENDORS_SUCCESS = "GET_VENDORS_SUCCESS";
export const GET_VENDORS_ERROR = "GET_VENDORS_ERROR";

export const getVendorsAttempt = (data) => ({
  type: GET_VENDORS_ATTEMPT,
  data,
});

export const getVendorsSuccess = (data) => ({
  type: GET_VENDORS_SUCCESS,
  data,
});

export const getVendorsError = (data) => ({
  type: GET_VENDORS_ERROR,
  data,
});

export const ADD_VENDOR_ATTEMPT = "ADD_VENDOR_ATTEMPT";
export const ADD_VENDOR_SUCCESS = "ADD_VENDOR_SUCCESS";
export const ADD_VENDOR_ERROR = "ADD_VENDOR_ERROR";
export const RESET_ADD_VENDOR = "RESET_ADD_VENDOR";

export const addVendorAttempt = (data) => ({
  type: ADD_VENDOR_ATTEMPT,
  data,
});

export const addVendorSuccess = (data) => ({
  type: ADD_VENDOR_SUCCESS,
  data,
});

export const addVendorError = (data) => ({
  type: ADD_VENDOR_ERROR,
  data,
});

export const resetAddVendor = (data) => ({
  type: RESET_ADD_VENDOR,
});

export const EDIT_VENDOR_ATTEMPT = "EDIT_VENDOR_ATTEMPT";
export const EDIT_VENDOR_SUCCESS = "EDIT_VENDOR_SUCCESS";
export const EDIT_VENDOR_ERROR = "EDIT_VENDOR_ERROR";
export const RESET_EDIT_VENDOR = "RESET_EDIT_VENDOR";

export const editVendorAttempt = (data) => ({
  type: EDIT_VENDOR_ATTEMPT,
  data,
});

export const editVendorSuccess = (data) => ({
  type: EDIT_VENDOR_SUCCESS,
  data,
});

export const editVendorError = (data) => ({
  type: EDIT_VENDOR_ERROR,
  data,
});

export const resetEditVendor = (data) => ({
  type: RESET_EDIT_VENDOR,
});
