/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import styles from "./AddCategoryScreen.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  createCategoryAttempt,
  resetCreateCategory,
} from "../../../redux/actions/categories";
import { useToasts } from "react-toast-notifications";
import Select from "react-select";
import { createSlug } from "../../../utils/createSlug";
import { Form, Row, Col, Container, Button } from "react-bootstrap";
import { SiteMap } from "../../../components/BreadCrumbs/BreadCrumbs";

const AddProductScreen = () => {
  const navLinks = [
    { href: "/admin/categories", name: "Categories" },
    { href: `/admin/add-category`, name: "Add category" },
  ];
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { createCategorySuccess, categories } = useSelector(
    (state) => state.categories
  );

  const [category, setCategory] = useState();
  const [categoryName, setCategoryName] = useState("");

  const groupProducts = () => {
    return categories
      .map((kategorija) => {
        const saParentom = categories.filter(
          (dete) => dete.parent_category?.id === kategorija.id
        );

        if (kategorija.parent_category === null) {
          return {
            value: kategorija.id,
            label: kategorija.name,
            children: saParentom.map((podkategorija) => {
              return {
                value: podkategorija.id,
                label: podkategorija.name,
              };
            }),
          };
        }
      })
      .filter(function (el) {
        return el != null;
      });
  };

  const renderSelect = () => {
    const style = {
      control: (base) => ({
        ...base,
        border: 0,
        // This line disable the blue border
        boxShadow: "none",
      }),
    };

    let categories = groupProducts();

    const handleCategoryChange = (selectedOption) => {
      setCategory(selectedOption);
    };

    return (
      <>
        <Form.Label>Product main category</Form.Label>
        <Select
          value={category}
          onChange={handleCategoryChange}
          options={categories}
          placeholder="None - click here if your category have main category"
          className="abc"
          classNamePrefix="react-select"
          isClearable
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary25: "rgba(27, 154, 62, 0.2)",
              primary: "rgba(27, 154, 62, 0.4)",
              primary50: "#DEEBF4",
            },
          })}
          styles={style}
        />
      </>
    );
  };

  useEffect(() => {
    if (createCategorySuccess) {
      setCategoryName("");
      setCategory("");
      addToast(`Category added succesfully`, {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 2000,
        onDismiss: () => {
          dispatch(resetCreateCategory());
        },
      });
    }
  }, [createCategorySuccess]);

  const addCategoryHandler = (e) => {
    e.preventDefault();
    if (category?.value) {
      dispatch(
        createCategoryAttempt({
          name: categoryName,
          slug: createSlug(categoryName),
          parent_category: category?.value,
        })
      );
    } else {
      dispatch(
        createCategoryAttempt({
          name: categoryName,
          slug: createSlug(categoryName),
        })
      );
    }
  };

  return (
    <div className={styles.addCategoryScreen}>
      <Container>
        {/* <h3 className={styles.addCategoryHeading}>Add new category</h3> */}
        <SiteMap hrefIn={`/admin/add-category`} items={navLinks} />
        <Row className="justify-content-md-center">
          <Col xs={12} md={6}>
            <Form onSubmit={addCategoryHandler}>
              <Form.Group controlId="desc">
                <Form.Label>Category name</Form.Label>
                <Form.Control
                  className={styles.myInput}
                  type="text"
                  placeholder="Enter category name"
                  value={categoryName}
                  onChange={(e) => {
                    setCategoryName(e.target.value);
                  }}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId="productCategory">
                {renderSelect()}
              </Form.Group>
              <Button
                variant="btn btn-outline-success rounded notInTableButton"
                type="submit"
                disabled={false}
              >
                Create Category
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AddProductScreen;
