import { EMAIL_LOGOUT_SUCCESS } from "../actions/auth";
import {
  GET_PRODUCTS_ATTEMPT,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_ERROR,
  CREATE_PRODUCTS_ATTEMPT,
  CREATE_PRODUCTS_SUCCESS,
  CREATE_PRODUCTS_ERROR,
  EDIT_PRODUCTS_ATTEMPT,
  EDIT_PRODUCTS_SUCCESS,
  EDIT_PRODUCTS_ERROR,
  RESET_EDIT_PRODUCTS,
  RESET_CREATE_PRODUCTS,
  SET_ACTIVE_PRODUCT_TAB,
  GET_NEW_PRODUCTS_ATTEMPT,
  GET_NEW_PRODUCTS_SUCCESS,
  GET_NEW_PRODUCTS_ERROR,
  GET_SPECIAL_PRODUCTS_ATTTEMPT,
  GET_SPECIAL_PRODUCTS_SUCCESS,
  GET_SPECIAL_PRODUCTS_ERROR,
  GET_TOP_SELLING_PRODUCTS_ATTEMPT,
  GET_TOP_SELLING_PRODUCTS_SUCCESS,
  GET_TOP_SELLING_PRODUCTS_ERROR,
  UPDATE_PRODUCT_FROM_LIST,
} from "../actions/products";

const defaultState = {
  products: [],

  getProductsAttempt: false,
  getProductsSuccess: false,
  getProductsError: false,
  getProductsErrorMessage: "",

  createProductsAttempt: false,
  createProductsSuccess: false,
  createProductsError: false,
  createProductsErrorMessage: "",

  editProductsAttempt: false,
  editProductsSuccess: false,
  editProductsError: false,
  editProductsErrorMessage: "",

  getSpecialProductsAttempt: false,
  getSpecialProductsSuccess: false,
  getSpecialProductsError: false,
  getSpecialProductsErrorMessage: "",

  getNewProductsAttempt: false,
  getNewProductsSuccess: false,
  getNewProductsError: false,
  getNewProductsErrorMessage: "",

  getTopSellingProductsAttempt: false,
  getTopSellingProductsSuccess: false,
  getTopSellingProductsError: false,
  getTopSellingProductsErrorMessage: "",

  specialProducts: [],
  topSellingProducts: [],
  newProducts: [],

  adminProducts: [],

  activeTab: "allProducts",
};

export const productsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case GET_PRODUCTS_ATTEMPT:
      return {
        ...state,
        getProductsAttempt: true,
        getProductsSuccess: false,
        getProductsError: false,
        getProductsErrorMessage: "",
      };
    case GET_PRODUCTS_SUCCESS:
      const renderProducts = () => {
        if (action.data.previous === null) {
          return action.data.results;
        } else {
          return [...state.products, ...action.data.results];
        }
      };

      return {
        ...state,
        products: renderProducts(),
        count: action.data.count,
        next: action.data.next,
        previous: action.data.previous,
        getProductsAttempt: false,
        getProductsSuccess: true,
        getProductsError: false,
        getProductsErrorMessage: "",
        adminProducts: action.data.results,
      };

    case GET_PRODUCTS_ERROR:
      return {
        ...state,
        products: [],
        getProductsAttempt: false,
        getProductsSuccess: false,
        getProductsError: true,
        getProductsErrorMessage: action.data,
      };

    case GET_NEW_PRODUCTS_ATTEMPT:
      return {
        ...state,
        getNewProductsAttempt: true,
        getNewProductsSuccess: false,
        getNewProductsError: false,
        getNewProductsErrorMessage: "",
      };

    case GET_NEW_PRODUCTS_SUCCESS:
      const renderNewProducts = () => {
        if (action.data.previous === null) {
          return action.data.results;
        } else {
          return [...state.newProducts, ...action.data.results];
        }
      };

      return {
        ...state,
        newProducts: renderNewProducts(),
        newProductsCount: action.data.count,
        newProductsNext: action.data.next,
        newProductsPrevious: action.data.previous,
        getNewProductsAttempt: false,
        getNewProductsSuccess: true,
        getNewProductsError: false,
        getNewProductsErrorMessage: "",
      };

    case GET_NEW_PRODUCTS_ERROR:
      return {
        ...state,
        getNewProductsAttempt: false,
        getNewProductsSuccess: false,
        getNewProductsError: true,
        getNewProductsErrorMessage: action.data,
      };

    case GET_SPECIAL_PRODUCTS_ATTTEMPT:
      return {
        ...state,
        getSpecialProductsAttempt: true,
        getSpecialProductsSuccess: false,
        getSpecialProductsError: false,
        getSpecialProductsErrorMessage: "",
      };

    case GET_SPECIAL_PRODUCTS_SUCCESS:
      const renderSpecialProducts = () => {
        if (action.data.previous === null) {
          return action.data.results;
        } else {
          return [...state.specialProducts, ...action.data.results];
        }
      };

      return {
        ...state,
        specialProducts: renderSpecialProducts(),
        specialCount: action.data.count,
        specialNext: action.data.next,
        specialPrevious: action.data.previous,
        getSpecialProductsAttempt: false,
        getSpecialProductsSuccess: false,
        getSpecialProductsError: false,
        getSpecialProductsErrorMessage: "",
      };

    case GET_SPECIAL_PRODUCTS_ERROR:
      return {
        ...state,
        getSpecialProductsAttempt: false,
        getSpecialProductsSuccess: false,
        getSpecialProductsError: true,
        getSpecialProductsErrorMessage: action.data,
      };

    case GET_TOP_SELLING_PRODUCTS_ATTEMPT:
      return {
        ...state,
        getTopSellingProductsAttempt: true,
        getTopSellingProductsSuccess: false,
        getTopSellingProductsError: false,
        getTopSellingProductsErrorMessage: "",
      };

    case GET_TOP_SELLING_PRODUCTS_SUCCESS:
      const renderTopSellingProducts = () => {
        if (action.data.previous === null) {
          return action.data.results;
        } else {
          return [...state.topSellingProducts, ...action.data.results];
        }
      };

      return {
        ...state,
        topSellingProducts: renderTopSellingProducts(),
        topSellingCount: action.data.count,
        topSellingNext: action.data.next,
        topSellingPrevious: action.data.previous,
        getTopSellingProductsAttempt: false,
        getTopSellingProductsSuccess: true,
        getTopSellingProductsError: false,
        getTopSellingProductsErrorMessage: "",
      };

    case GET_TOP_SELLING_PRODUCTS_ERROR:
      return {
        ...state,
        getTopSellingProductsAttempt: false,
        getTopSellingProductsSuccess: false,
        getTopSellingProductsError: true,
        getTopSellingProductsErrorMessage: action.data,
      };

    case CREATE_PRODUCTS_ATTEMPT:
      return {
        ...state,
        createProductsAttempt: true,
        createProductsSuccess: false,
        createProductsError: false,
        createProductsErrorMessage: "",
      };

    case CREATE_PRODUCTS_SUCCESS:
      return {
        ...state,
        createProductsAttempt: false,
        createProductsSuccess: true,
        createProductsError: false,
        createProductsErrorMessage: "",
        products: [...state.products, action.data],
      };

    case CREATE_PRODUCTS_ERROR:
      return {
        ...state,
        createProductsAttempt: false,
        createProductsSuccess: false,
        createProductsError: true,
        createProductsErrorMessage: action.data,
      };

    case RESET_CREATE_PRODUCTS:
      return {
        ...state,
        createProductsAttempt: false,
        createProductsSuccess: false,
        createProductsError: false,
        createProductsErrorMessage: "",
      };

    case EDIT_PRODUCTS_ATTEMPT:
      return {
        ...state,
        editProductsAttempt: true,
        editProductsSuccess: false,
        editProductsError: false,
        editProductsErrorMessage: "",
      };

    case EDIT_PRODUCTS_SUCCESS:
      const checkWhatToDo = () => {
        let productFromState = state.products.find(
          (product) => product.id === action.data.id
        );
        if (action.data.permanently_deleted) {
          return state.products.filter(
            (product) => product.id !== action.data.id
          );
        }
        if (action.data.is_deleted) {
          return state.products.filter(
            (product) => product.id !== action.data.id
          );
        } else if (productFromState?.permanently_deleted) {
          return state.products.filter(
            (product) => product.id !== action.data.id
          );
        } else if (productFromState?.is_deleted) {
          return state.products.filter(
            (product) => product.id !== action.data.id
          );
        } else {
          return state.products.map((product) => {
            if (product.id === action.data.id) {
              return { ...action.data };
            }
            return product;
          });
        }
      };

      const checkWhatToDoAdmin = () => {
        let productFromState = state.adminProducts.find(
          (product) => product.id === action.data.id
        );
        if (action.data.permanently_deleted) {
          return state.adminProducts.filter(
            (product) => product.id !== action.data.id
          );
        }
        if (action.data.is_deleted) {
          return state.adminProducts.filter(
            (product) => product.id !== action.data.id
          );
        } else if (productFromState?.permanently_deleted) {
          return state.adminProducts.filter(
            (product) => product.id !== action.data.id
          );
        } else if (productFromState?.is_deleted) {
          return state.adminProducts.filter(
            (product) => product.id !== action.data.id
          );
        } else {
          return state.adminProducts.map((product) => {
            if (product.id === action.data.id) {
              return { ...action.data };
            }
            return product;
          });
        }
      };

      return {
        ...state,
        editProductsAttempt: false,
        editProductsSuccess: true,
        products: checkWhatToDo(),
        adminProducts: checkWhatToDoAdmin(),
        editProductsError: false,
        editProductsErrorMessage: "",
      };

    case EDIT_PRODUCTS_ERROR:
      return {
        ...state,
        editProductsAttempt: false,
        editProductsSuccess: false,
        editProductsError: true,
        editProductsErrorMessage: action.data,
      };

    case RESET_EDIT_PRODUCTS:
      return {
        ...state,
        editProductsAttempt: false,
        editProductsSuccess: false,
        editProductsError: false,
        editProductsErrorMessage: "",
      };

    case UPDATE_PRODUCT_FROM_LIST:
      const checkWhatToDo2 = () => {
        if (action.data.is_deleted === "true") {
          return state.adminProducts?.filter(
            (item) => item?.id !== Number(action.data.product_id)
          );
        } else {
          return state.adminProducts?.map((product) => {
            if (product?.id === Number(action.data.product_id)) {
              return {
                ...product,
                name: action.data.product_name,
                box_quantity: action.data.product_box_quantity,
                quantity: action.data.product_quantity,
                price: action.data.product_price,
                image: action.data.product_image,
                is_deleted: action.data.is_deleted === "true" ? true : false,
              };
            } else return product;
          });
        }
      };

      return {
        ...state,
        adminProducts: checkWhatToDo2(),
        count: checkWhatToDo2()?.length,
      };

    case SET_ACTIVE_PRODUCT_TAB:
      return {
        ...state,
        activeTab: action.data,
      };

    case EMAIL_LOGOUT_SUCCESS:
      return defaultState;

    default:
      return state;
  }
};
