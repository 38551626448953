import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

const LoaderComponent = ({ type }) => {
  return (
    <center>
      <Loader
        type={type || "Puff"}
        height={35}
        width={100}
        className="loaderCSS"
      />
    </center>
  );
};

export default LoaderComponent;
