import React from "react";
import "./Header.css";
import logo from "../../../assets/images/logo.png";
import { IoCartSharp } from "react-icons/io5";
import { Container, Navbar, Nav, NavDropdown, Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { NavLink } from "react-router-dom";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { FaUser } from "react-icons/fa";
import { emailLogoutSuccess } from "../../redux/actions/auth";
import { useDispatch, useSelector } from "react-redux";

function Header({ routes }) {
  const dispatch = useDispatch();
  const { cartProducts } = useSelector((state) => state.cart);
  const { form, email, verified } = useSelector((state) => state.auth);
  const { width } = useWindowDimensions();

  if (form === "Admin") {
    return (
      <Navbar collapseOnSelect expand="lg" className="myNav">
        <Container>
          {width <= 991 ? (
            <>
              <NavLink to="/" className="mr-auto">
                <img className="site-logo" alt="site-logo" src={logo} />
              </NavLink>
              <NavLink
                className="nav-link"
                activeClassName="active-route"
                exact
                to="/cart-admin"
              >
                <span className="cart-icon">
                  <span className="cartName">Cart</span> / <IoCartSharp />
                  {cartProducts.length > 0 ? (
                    <span className="cartTotalNumber">
                      {cartProducts.length}
                    </span>
                  ) : null}
                </span>
              </NavLink>
            </>
          ) : null}
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav>
              {routes &&
                routes.map((item, index) => {
                  return (
                    <Nav.Link
                      exact
                      className="nav-link"
                      activeClassName="active-route"
                      key={index}
                      to={item.path}
                      as={NavLink}
                    >
                      {item.icon}
                      {item.title}
                    </Nav.Link>
                  );
                })}
            </Nav>
            {width > 991 ? (
              <NavLink to="/" className="mx-auto">
                <img className="site-logo" alt="site-logo" src={logo} />
              </NavLink>
            ) : null}
            {form === "" ? (
              <>
                <NavLink className="login-button" exact to="/login">
                  Login
                </NavLink>
                <NavLink className="login-button" exact to="/register">
                  Register
                </NavLink>
              </>
            ) : width > 991 ? (
              <>
                <NavLink
                  className="nav-link"
                  activeClassName="active-route"
                  exact
                  to="/cart-admin"
                >
                  <span className="cart-icon">
                    <span className="cartName">Cart</span> / <IoCartSharp />
                    {cartProducts.length > 0 ? (
                      <span className="cartTotalNumber">
                        {cartProducts.length}
                      </span>
                    ) : null}
                  </span>
                </NavLink>
                <NavDropdown
                  title={
                    <span className="profileIcon">
                      <FaUser /> &nbsp; {email}
                    </span>
                  }
                  id="adminmenu"
                  className="mr-0"
                >
                  <LinkContainer
                    to="/admin/products"
                    className="nav-link"
                    activeClassName="active-route"
                  >
                    <NavDropdown.Item>Manage products</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer
                    to="/admin/orders"
                    className="nav-link"
                    activeClassName="active-route"
                  >
                    <NavDropdown.Item>Manage orders</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer
                    to="/admin/categories"
                    className="nav-link"
                    activeClassName="active-route"
                  >
                    <NavDropdown.Item>Manage categories</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer
                    to="/admin/vendors"
                    className="nav-link"
                    activeClassName="active-route"
                  >
                    <NavDropdown.Item>Manage vendors</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer
                    to="/admin/customers"
                    className="nav-link"
                    activeClassName="active-route"
                  >
                    <NavDropdown.Item>Manage customers</NavDropdown.Item>
                  </LinkContainer>
                  <NavDropdown.Item
                    onClick={() => dispatch(emailLogoutSuccess())}
                    className="nav-link btn btn-secondary"
                  >
                    Log out
                  </NavDropdown.Item>
                </NavDropdown>
              </>
            ) : (
              <>
                <LinkContainer
                  to="/admin/products"
                  className="nav-link"
                  activeClassName="active-route"
                >
                  <NavDropdown.Item>Manage products</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer
                  to="/admin/orders"
                  className="nav-link"
                  activeClassName="active-route"
                >
                  <NavDropdown.Item>Manage orders</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer
                  to="/admin/categories"
                  className="nav-link"
                  activeClassName="active-route"
                >
                  <NavDropdown.Item>Manage categories</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer
                  to="/admin/customers"
                  className="nav-link"
                  activeClassName="active-route"
                >
                  <NavDropdown.Item>Manage customers</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer
                  to="/admin/vendors"
                  className="nav-link"
                  activeClassName="active-route"
                >
                  <NavDropdown.Item>Manage vendors</NavDropdown.Item>
                </LinkContainer>
                <Button
                  onClick={() => dispatch(emailLogoutSuccess())}
                  className="btn btn-secondary"
                >
                  Log out
                </Button>
              </>
            )}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  } else {
    return (
      <Navbar collapseOnSelect expand="lg" className="myNav">
        <Container>
          {width <= 991 ? (
            <>
              <NavLink to="/" className="mr-auto">
                <img className="site-logo" alt="site-logo" src={logo} />
              </NavLink>
              {verified && (
                <NavLink
                  className="nav-link"
                  activeClassName="active-route"
                  exact
                  to="/cart"
                >
                  <span className="cart-icon">
                    <span className="cartName">Cart</span> / <IoCartSharp />
                    {cartProducts.length > 0 ? (
                      <span className="cartTotalNumber">
                        {cartProducts.length}
                      </span>
                    ) : null}
                  </span>
                </NavLink>
              )}
            </>
          ) : null}
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            {width > 991 ? (
              <NavLink to="/">
                <img className="site-logo2" alt="site-logo" src={logo} />
              </NavLink>
            ) : null}
            <Nav className="mx-auto">
              {routes &&
                routes.map((item, index) => {
                  return (
                    <Nav.Link
                      exact
                      className="nav-link"
                      activeClassName="active-route"
                      key={index}
                      to={item.path}
                      as={NavLink}
                    >
                      {item.icon}
                      {item.title}
                    </Nav.Link>
                  );
                })}
            </Nav>
            {verified && (
              <NavLink
                className="nav-link"
                activeClassName="active-route"
                exact
                to="/cart"
              >
                <span className="cart-icon">
                  <span className="cartName">Cart</span> / <IoCartSharp />
                  {cartProducts.length > 0 ? (
                    <span className="cartTotalNumber">
                      {cartProducts.length}
                    </span>
                  ) : null}
                </span>
              </NavLink>
            )}
            {form === "" && !verified ? (
              <>
                <NavLink className="login-button" exact to="/login">
                  Login
                </NavLink>
                <NavLink className="login-button" exact to="/register">
                  Register
                </NavLink>
              </>
            ) : (
              <NavDropdown
                title={
                  <span className="profileIcon">
                    <FaUser /> &nbsp; {email}
                  </span>
                }
                id="adminmenu"
              >
                {verified && (
                  <>
                    <LinkContainer
                      to="/my-profile"
                      className="nav-link"
                      activeClassName="active-route"
                    >
                      <NavDropdown.Item>My profile</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer
                      to="/my-orders"
                      className="nav-link"
                      activeClassName="active-route"
                    >
                      <NavDropdown.Item>My orders</NavDropdown.Item>
                    </LinkContainer>
                  </>
                )}
                <NavDropdown.Item
                  onClick={() => dispatch(emailLogoutSuccess())}
                  className="nav-link btn btn-secondary"
                >
                  Log out
                </NavDropdown.Item>
              </NavDropdown>
            )}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  }
}

export default Header;
