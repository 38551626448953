import React, { useState } from "react";
import styles from "./HomeScreenProducts.module.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { addToCart } from "../../redux/actions/cart";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import QuantityInput from "../../components/QuantityInput/QuantityInput";
import placeholder from "../../../assets/images/placeholder.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import Card from "react-bootstrap/Card";
import { useNetwork } from "../../utils/checkInternet";

function HomeScreenProducts() {
  const isConnected = useNetwork();

  const { authToken, form, verified } = useSelector((state) => state.auth);
  const { cartProducts } = useSelector((state) => state.cart);
  const { adminProducts } = useSelector((state) => state.products);
  const [showQuantity, setShowQuantity] = useState(false);
  const [itemId, setItemId] = useState(null);
  const { addToast } = useToasts();
  const dispatch = useDispatch();

  const renderProducts = () => {
    return adminProducts?.map((product) => {
      const foundedProducts = cartProducts.find(
        (cartProduct) => cartProduct.id === product.id
      );

      const renderCardFooter = () => {
        if (form === "Admin") {
          if (
            (showQuantity &&
              itemId === product.id &&
              foundedProducts?.id === product.id) ||
            foundedProducts?.id === product.id
          ) {
            return (
              <>
                <Link
                  to={{
                    pathname: `/admin/product/${product.id}`,
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button variant="btn btn-sm btn-outline-success rounded mt-4 d-block mx-auto">
                    Edit product
                  </Button>
                </Link>
                <div className="quantity001 buttons_added001 mt-3">
                  <QuantityInput foundedProducts={foundedProducts} />
                </div>
              </>
            );
          }
          return (
            <>
              <Link
                to={{
                  pathname: `/admin/product/${product.id}`,
                }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button variant="btn btn-sm btn-outline-success rounded mt-4 d-block mx-auto">
                  Edit product
                </Button>
              </Link>
              <Button
                onClick={() => {
                  setShowQuantity(true);
                  dispatch(addToCart(product));
                  setItemId(product.id);
                  addToast(
                    `${product?.box_quantity}x ${product?.name} added to cart`,
                    {
                      appearance: "success",
                      autoDismiss: true,
                      autoDismissTimeout: 2000,
                    }
                  );
                }}
                variant="btn btn-success rounded w-100  mt-3"
              >
                Add to cart
              </Button>
            </>
          );
        } else if (form === "Customer" && verified && product?.stock) {
          if (
            (showQuantity &&
              itemId === product.id &&
              foundedProducts?.id === product.id) ||
            foundedProducts?.id === product.id
          ) {
            return (
              <div className="quantity001 buttons_added001">
                <QuantityInput foundedProducts={foundedProducts} />
              </div>
            );
          } else {
            return (
              <Button
                onClick={() => {
                  setShowQuantity(true);
                  dispatch(addToCart(product));
                  setItemId(product.id);
                  addToast(
                    `${product?.box_quantity}x ${product?.name} added to cart`,
                    {
                      appearance: "success",
                      autoDismiss: true,
                      autoDismissTimeout: 2000,
                    }
                  );
                }}
                variant="btn btn-outline-success rounded w-100 notInTableButton"
              >
                Add to cart
              </Button>
            );
          }
        } else if (form === "Customer" && verified && !product?.stock) {
          return <h5 className="text-center">Temporary out of stock</h5>;
        } else if (form === "Customer" && !verified) {
          return <h5 className="text-center">Waiting profile verification</h5>;
        }
      };

      return (
        <Col
          className={styles.productGridColumn}
          md={6}
          sm={12}
          lg={4}
          xl={3}
          key={product.id}
        >
          <Card className={styles.productCard}>
            <Card.Body className={styles.productCardBody}>
              {product?.special > 0 ? (
                <span className={styles.discoundLabel}>
                  Discount {product?.special}%
                </span>
              ) : null}
              <LazyLoadImage
                className="img-fluid rounded"
                effect="blur"
                alt={product.image}
                src={isConnected ? product.image : placeholder}
                width={214}
                height={214}
                // eslint-disable-next-line jsx-a11y/alt-text
                placeholder={<img alt="placeholder" src={placeholder} />}
                placeholderSrc={placeholder}
                visibleByDefault={product.image === placeholder}
              />
              {/* <img
                src={product.image ? product.image : placeholder}
                className="img-fluid rounded"
                alt="product"
              /> */}
              <Card.Title className={styles.productTitle}>
                {product?.name}
              </Card.Title>
              <Card.Text className={styles.productPrice}>
                {authToken && verified ? (
                  <>
                    {"CAD "}
                    {(
                      product?.price -
                      product?.price * (product.special / 100)
                    ).toFixed(2)}{" "}
                  </>
                ) : null}
              </Card.Text>
              <Card.Text className="text-right">
                Pieces per case: {product?.box_quantity}
              </Card.Text>
            </Card.Body>
            <Card.Footer className={styles.productCardFooter}>
              {renderCardFooter()}
            </Card.Footer>
          </Card>
        </Col>
      );
    });
  };

  return (
    <div className={styles.homeScreenProductsContainer}>
      <Container>
        {adminProducts.length > 0 ? (
          <h4 className={styles.latestArrivalsHeading}>Our products</h4>
        ) : null}
        <Row>{renderProducts()}</Row>
        <Row>
          <Col sm={12}>
            <div className={styles.viewAllProductsDiv}>
              <Link
                to="/products"
                className="btn btn-outline-success rounded notInTableButton"
              >
                View all products
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default HomeScreenProducts;
