import {
  EMAIL_LOGIN_ATTEMPT,
  EMAIL_LOGIN_SUCCESS,
  EMAIL_LOGIN_ERROR,
  EMAIL_LOGOUT_SUCCESS,
  EMAIL_REGISTER_ATTEMPT,
  EMAIL_REGISTER_SUCCESS,
  EMAIL_REGISTER_ERROR,
  GET_RESET_CODE_ATTEMPT,
  GET_RESET_CODE_SUCCESS,
  GET_RESET_CODE_ERROR,
  VALIDATE_RESET_CODE_ATTEMPT,
  VALIDATE_RESET_CODE_SUCCESS,
  VALIDATE_RESET_CODE_ERROR,
  REQUEST_NEW_PASSWORD_ATTEMPT,
  REQUEST_NEW_PASSWORD_SUCCESS,
  REQUEST_NEW_PASSWORD_ERROR,
} from "../actions/auth";

import { GET_PROFILE_SUCCESS } from "../actions/profile";

const defaultState = {
  authToken: "",
  form: "",
  username: "",
  email: "",
  user: "",
  verified: false,

  loginAttempt: false,
  loginSuccess: false,
  loginError: false,
  loginErrorMessage: "",

  registerAttempt: false,
  registerSuccess: false,
  registerError: false,
  registerErrorMessage: "",

  getResetCodeAttempt: false,
  getResetCodeSuccess: false,
  getResetCodeError: false,
  getResetCodeErroMessage: "",

  validateResetCodeAttempt: false,
  validateResetCodeSuccess: false,
  validateResetCodeError: false,
  validateResetCodeErrorMessage: "",

  requestNewPasswordAttempt: false,
  requestNewPasswordSuccess: false,
  requestNewPasswordError: false,
  requestNewPasswordErrorMessage: "",

  isLogin: false,
  tempAuthToken: "",
  tempForm: "",
};

export const authReducer = (state = defaultState, action) => {
  switch (action.type) {
    case EMAIL_LOGIN_ATTEMPT:
      return {
        ...state,
        loginAttempt: true,
        loginSuccess: false,
        loginError: false,
        loginErrorMessage: "",
      };
    case EMAIL_LOGIN_SUCCESS:
      return {
        ...state,
        loginAttempt: false,
        loginSuccess: true,
        loginError: false,
        loginErrorMessage: "",
        email: action.data.email,
        username: action.data.username,
        authToken: `Token ${action.data.key}`,
        user: action.data.user, //id korisnika
        form: action.data.form,
        isLogin: true,
        verified: action.data.verified,
      };
    case EMAIL_LOGIN_ERROR:
      return {
        ...state,
        loginAttempt: false,
        loginSuccess: false,
        loginError: true,
        loginErrorMessage: action.data,
      };

    case EMAIL_REGISTER_ATTEMPT:
      return {
        ...state,
        registerAttempt: true,
        registerSuccess: false,
        registerError: false,
        registerErrorMessage: "",
      };
    case EMAIL_REGISTER_SUCCESS:
      return {
        ...state,
        registerAttempt: false,
        registerSuccess: true,
        registerError: false,
        email: action.data.email,
        username: action.data.username,
        authToken: `Token ${action.data.key}`,
        user: action.data.user, //id korisnika
        form: action.data.form,
        isLogin: true,
        verified: action.data.verified,
      };
    case EMAIL_REGISTER_ERROR:
      return {
        ...state,
        registerAttempt: false,
        registerSuccess: false,
        registerError: true,
        registerErrorMessage: action.data,
      };

    case GET_RESET_CODE_ATTEMPT:
      return {
        ...state,
        getResetCodeAttempt: true,
        getResetCodeSuccess: false,
        getResetCodeError: false,
        getResetCodeErroMessage: "",
      };
    case GET_RESET_CODE_SUCCESS:
      return {
        ...state,
        getResetCodeAttempt: false,
        getResetCodeSuccess: true,
        getResetCodeError: false,
        getResetCodeErroMessage: "",
        email: action.data.email,
      };
    case GET_RESET_CODE_ERROR:
      return {
        ...state,
        getResetCodeAttempt: false,
        getResetCodeSuccess: false,
        getResetCodeError: true,
        getResetCodeErroMessage: action.data,
      };

    case VALIDATE_RESET_CODE_ATTEMPT:
      return {
        ...state,
        validateResetCodeAttempt: true,
        validateResetCodeSuccess: false,
        validateResetCodeError: false,
        validateResetCodeErrorMessage: "",
      };
    case VALIDATE_RESET_CODE_SUCCESS:
      return {
        ...state,
        validateResetCodeAttempt: false,
        validateResetCodeSuccess: true,
        validateResetCodeError: false,
        validateResetCodeErrorMessage: "",
        tempAuthToken: `Token ${action.data?.token?.key}`,
        username: action.data.token?.username,
        user: action.data.token?.user,
        email: action.data.token?.username,
        tempForm: action.data.token?.form,
      };
    case VALIDATE_RESET_CODE_ERROR:
      return {
        ...state,
        validateResetCodeAttempt: false,
        validateResetCodeSuccess: false,
        validateResetCodeError: true,
        validateResetCodeErrorMessage: action.data,
      };

    case REQUEST_NEW_PASSWORD_ATTEMPT:
      return {
        ...state,
        requestNewPasswordAttempt: true,
        requestNewPasswordSuccess: false,
        requestNewPasswordError: false,
        requestNewPasswordErrorMessage: "",
      };
    case REQUEST_NEW_PASSWORD_SUCCESS:
      return {
        ...state,
        requestNewPasswordAttempt: false,
        requestNewPasswordSuccess: true,
        requestNewPasswordError: false,
        requestNewPasswordErrorMessage: "",
        isLogin: true,
        authToken: state.tempAuthToken,
        form: state.tempForm,
        // tempAuthToken: "",
      };
    case REQUEST_NEW_PASSWORD_ERROR:
      return {
        ...state,
        requestNewPasswordAttempt: false,
        requestNewPasswordSuccess: false,
        requestNewPasswordError: true,
        requestNewPasswordErrorMessage: action.data,
      };

    case GET_PROFILE_SUCCESS:
      return {
        ...state,
        verified: action.data.verified,
      };

    case EMAIL_LOGOUT_SUCCESS:
      return defaultState;

    default:
      return state;
  }
};
