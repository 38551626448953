import { slide as Menu } from "react-burger-menu";

const BurgerMenu = ({ children, setBurgerMenuVibisle, burgerMenuVisible }) => {
  return (
    <Menu
      customBurgerIcon={false}
      styles={styles}
      isOpen={burgerMenuVisible}
      onClose={() => setBurgerMenuVibisle(false)}
    >
      {children}
    </Menu>
  );
};

export default BurgerMenu;

var styles = {
  bmBurgerButton: {
    position: "absolute",
    width: "36px",
    height: "30px",
    left: "36px",
    top: "0",
  },
  bmBurgerBars: {
    background: "#373a47",
  },
  bmBurgerBarsHover: {
    background: "#a90000",
  },
  bmCrossButton: {
    height: "24px",
    width: "24px",
  },
  bmCross: {
    background: "#bdc3c7",
  },
  bmMenuWrap: {
    position: "fixed",
    top: 0,
    bottom: 0,
    minHeight: "100vh",
  },
  bmMenu: {
    background: "white",
    padding: "2.5em 1.5em 0",
    fontSize: "1.15em",
  },
  bmMorphShape: {
    fill: "#373a47",
  },
  bmItemList: {
    color: "#55595c",
    padding: "0.8em",
    fontWeight: 500,
    fontSize: 15,
  },
  bmItem: {
    display: "inline-block",
  },
  bmOverlay: {
    background: "rgba(0, 0, 0, 0.3)",
    top: 0,
  },
};
