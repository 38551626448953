/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import styles from "./ProductList.module.css";
import { Container, Table, Button, Form, Row, Col } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { Link } from "react-router-dom";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { useSelector, useDispatch } from "react-redux";
import {
  getProductsAttempt,
  editProductsAttempt,
  resetEditProduct,
  setActiveProductTab,
  updateProductFromList,
} from "../../../redux/actions/products";
import { getVendorsAttempt } from "../../../redux/actions/vendors";
import { getCategoryAttempt } from "../../../redux/actions/categories";
import { useToasts } from "react-toast-notifications";
// import Loader from "../../../components/Loader/Loader";
import Pagination from "../../../components/Pagination/Pagination";

const OrdersScreen = () => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const {
    // products,
    adminProducts: products,
    editProductsSuccess,
    activeTab,
    // getProductsAttempt: loader,
    next,
    count,
    previous,
  } = useSelector((state) => state.products);

  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [visibilityAllPageNumbers, setVisibilityAllPageNumbers] =
    useState(false);
  // const [selectedPageToJump, setSelectedPageToJump] = useState("");

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
    if (activeTab === "allProducts") {
      dispatch(getProductsAttempt({ search: "" }));
      setCurrentPage(1);
      setSearchQuery("");
    } else if (activeTab === "reorder") {
      dispatch(getProductsAttempt({ need_to_reorder: true }));
      setCurrentPage(1);
      setSearchQuery("");
    } else if (activeTab === "trash") {
      dispatch(getProductsAttempt({ isDeleted: true }));
      setCurrentPage(1);
      setSearchQuery("");
    } else {
      dispatch(getProductsAttempt({ noImage: true }));
      setCurrentPage(1);
      setSearchQuery("");
    }
  }, [activeTab]);

  useEffect(() => {
    document.title = "Miraco - Admin Products";
    dispatch(getVendorsAttempt());
    dispatch(getCategoryAttempt());
  }, []);

  useEffect(() => {
    if (editProductsSuccess) {
      addToast(`Product edited succesfully`, {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 800,
        onDismiss: () => {
          dispatch(resetEditProduct());
        },
      });
    }
  }, [editProductsSuccess]);

  const renderTable = () => {
    return (
      <Table hover responsive className="table-sm">
        <thead>
          <tr>
            <th>Item</th>
            <th>Description</th>
            <th>Bar code</th>
            <th>Stock status</th>
            <th>Purchase tax code</th>
            <th>Sales tax code</th>
            <th>Cost CAD$</th>
            <th>Price CAD$</th>
          </tr>
        </thead>
        <tbody>
          {products.map((product) => {
            return (
              <tr key={product.id}>
                <td>{product.item}</td>
                <td>{product.name}</td>
                <td>{product.barcode}</td>
                <td>{product.stock ? "In stock" : "Out of stock"}</td>
                <td>{product.purchase_tax_code}</td>
                <td>{product.sales_tax_code}</td>
                <td>{product.cost}</td>
                <td>{product.price}</td>
                <td>
                  {product.is_deleted ? (
                    <div className="d-flex">
                      <Button
                        variant="btn btn-sm btn-outline-success rounded"
                        onClick={() => {
                          dispatch(
                            editProductsAttempt({
                              data_to_server: {
                                stock: true,
                                is_deleted: false,
                              },
                              id: product.id,
                              contentType: "application/json",
                            })
                          );
                        }}
                      >
                        Restore
                      </Button>
                      <Button
                        variant="btn btn-sm btn-outline-danger rounded"
                        className="ml-3"
                        onClick={() => {
                          dispatch(
                            editProductsAttempt({
                              data_to_server: {
                                permanently_deleted: true,
                              },
                              id: product.id,
                              contentType: "application/json",
                            })
                          );
                        }}
                      >
                        Permanently Delete
                      </Button>
                    </div>
                  ) : (
                    <Link
                      target="_blank"
                      rel="noopener noreferrer"
                      to={`/admin/product/${product.id}`}
                    >
                      <Button variant="btn btn-sm btn-outline-success rounded">
                        Edit product
                      </Button>
                    </Link>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  };

  useEffect(() => {
    function checkProduct() {
      const product_id = localStorage.getItem("product_id");
      const product_price = localStorage.getItem("product_price");
      const product_name = localStorage.getItem("product_name");
      const product_box_quantity = localStorage.getItem("product_box_quantity");
      const product_quantity = localStorage.getItem("product_quantity");
      const product_image = localStorage.getItem("product_image");
      const is_deleted = localStorage.getItem("is_deleted");

      if (product_id) {
        dispatch(
          updateProductFromList({
            product_id,
            product_name,
            product_image,
            product_box_quantity,
            product_quantity,
            product_price,
            is_deleted,
          })
        );
        localStorage.removeItem("product_id");
        localStorage.removeItem("product_price");
        localStorage.removeItem("product_name");
        localStorage.removeItem("product_box_quantity");
        localStorage.removeItem("product_quantity");
        localStorage.removeItem("product_image");
        localStorage.removeItem("is_deleted");
      }
    }

    window.addEventListener("storage", checkProduct);

    return () => {
      window.removeEventListener("storage", checkProduct);
    };
  }, []);

  // if (loader) {
  //   return (
  //     <div
  //       style={{
  //         minHeight: "100vh",
  //         display: "flex",
  //         marginTop: "100px",
  //         flexDirection: "column",
  //         alignItems: "center",
  //       }}
  //     >
  //       <Loader type="Bars" />
  //       <h5 className="mt-2">Getting products...</h5>
  //     </div>
  //   );
  // }

  const paginate = (pageNum) => {
    setCurrentPage(pageNum);
    if (searchQuery) {
      dispatch(
        getProductsAttempt({
          search: searchQuery,
          page: pageNum,
          isDeleted: activeTab === "trash",
          need_to_reorder: activeTab === "reorder",
          noImage: activeTab === "withoutimage",
        })
      );
    } else {
      dispatch(
        getProductsAttempt({
          page: pageNum,
          isDeleted: activeTab === "trash",
          need_to_reorder: activeTab === "reorder",
          noImage: activeTab === "withoutimage",
        })
      );
    }
  };

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
    dispatch(getProductsAttempt({ payloadUrl: next }));
  };

  const prevPage = () => {
    setCurrentPage(currentPage - 1);
    dispatch(getProductsAttempt({ payloadUrl: previous }));
  };

  //svi brojevi vidljivi
  const changeVisibilityAllPageNumbers = (visibility) =>
    setVisibilityAllPageNumbers(visibility);

  // const setJumpedPage = (pageObj) => setSelectedPageToJump(pageObj);

  return (
    <div className={styles.productsListScreen}>
      <Container fluid>
        <div className="d-flex align-items-center justify-content-between">
          <h3 className={styles.productListHeading}>Manage products</h3>
          <LinkContainer to="/admin/add-product">
            <Button variant="btn btn-sm btn-outline-success rounded notInTableButton">
              Add product
            </Button>
          </LinkContainer>
        </div>

        <Tabs
          id="controlled-tab-example"
          activeKey={activeTab}
          onSelect={(k) => dispatch(setActiveProductTab(k))}
        >
          <Tab eventKey="allProducts" title="All Products">
            <Row>
              <Col>
                <div className="d-flex">
                  <Form.Control
                    className="rounded"
                    type="text"
                    placeholder="Search product"
                    value={searchQuery}
                    onChange={(e) => {
                      if (e.target.value === "") {
                        dispatch(getProductsAttempt({ search: "" }));
                        setCurrentPage(1);
                      }
                      setSearchQuery(e.target.value.toLowerCase());
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        dispatch(getProductsAttempt({ search: searchQuery }));
                        setCurrentPage(1);
                      }
                    }}
                  ></Form.Control>
                  <Button
                    disabled={!searchQuery}
                    type="submit"
                    variant="btn btn-outline-success notInTableButton rounded"
                    onClick={() => {
                      dispatch(getProductsAttempt({ search: searchQuery }));
                      setCurrentPage(1);
                    }}
                  >
                    Search
                  </Button>
                </div>
              </Col>
            </Row>
            {renderTable()}
            <div className={styles.paginationWrapper}>
              <Pagination
                postsPerPage={16}
                totalPosts={count}
                paginate={paginate}
                nextPage={nextPage}
                prevPage={prevPage}
                totalPageNumber={Math.ceil(count / 16)}
                currentPage={currentPage}
                visibilityAllPageNumbers={visibilityAllPageNumbers}
                changeVisibilityAllPageNumbers={changeVisibilityAllPageNumbers}
                // setSelectedPageToJump={setJumpedPage}
                // selectedPageToJump={selectedPageToJump}
              />
            </div>
          </Tab>
          <Tab eventKey="withoutimage" title="Products without image">
            <>
              <div className="d-flex">
                <Form.Control
                  className="rounded"
                  type="text"
                  placeholder="Search product"
                  value={searchQuery}
                  onChange={(e) => {
                    if (e.target.value === "") {
                      dispatch(
                        getProductsAttempt({ search: "", noImage: true })
                      );
                      setCurrentPage(1);
                    }
                    setSearchQuery(e.target.value.toLowerCase());
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      dispatch(
                        getProductsAttempt({
                          search: searchQuery,
                          noImage: true,
                        })
                      );
                      setCurrentPage(1);
                    }
                  }}
                ></Form.Control>
                <Button
                  disabled={!searchQuery}
                  type="submit"
                  variant="btn btn-outline-success notInTableButton rounded"
                  onClick={() => {
                    dispatch(
                      getProductsAttempt({
                        search: searchQuery,
                        noImage: true,
                      })
                    );
                    setCurrentPage(1);
                  }}
                >
                  Search
                </Button>
              </div>
              {renderTable()}
              {products.length === 0 ? (
                <h5 className="text-center mt-5">No products without image</h5>
              ) : null}
            </>
            <div className={styles.paginationWrapper}>
              <Pagination
                postsPerPage={16}
                totalPosts={count}
                paginate={paginate}
                nextPage={nextPage}
                prevPage={prevPage}
                totalPageNumber={Math.ceil(count / 16)}
                currentPage={currentPage}
                visibilityAllPageNumbers={visibilityAllPageNumbers}
                changeVisibilityAllPageNumbers={changeVisibilityAllPageNumbers}
                // setSelectedPageToJump={setJumpedPage}
                // selectedPageToJump={selectedPageToJump}
              />
            </div>
          </Tab>
          <Tab eventKey="reorder" title="Need to reorder">
            {products.length === 0 ? (
              <h5 className="text-center mt-5">No products to reorder</h5>
            ) : (
              renderTable()
            )}
            <div className={styles.paginationWrapper}>
              <Pagination
                postsPerPage={16}
                totalPosts={count}
                paginate={paginate}
                nextPage={nextPage}
                prevPage={prevPage}
                totalPageNumber={Math.ceil(count / 16)}
                currentPage={currentPage}
                visibilityAllPageNumbers={visibilityAllPageNumbers}
                changeVisibilityAllPageNumbers={changeVisibilityAllPageNumbers}
                // setSelectedPageToJump={setJumpedPage}
                // selectedPageToJump={selectedPageToJump}
              />
            </div>
          </Tab>
          <Tab eventKey="trash" title="Products in trash">
            <>
              <div className="d-flex">
                <Form.Control
                  className="rounded"
                  type="text"
                  placeholder="Search product"
                  value={searchQuery}
                  onChange={(e) => {
                    if (e.target.value === "") {
                      dispatch(
                        getProductsAttempt({ search: "", isDeleted: true })
                      );
                      setCurrentPage(1);
                    }
                    setSearchQuery(e.target.value.toLowerCase());
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      dispatch(
                        getProductsAttempt({
                          search: searchQuery,
                          isDeleted: true,
                        })
                      );
                      setCurrentPage(1);
                    }
                  }}
                ></Form.Control>
                <Button
                  disabled={!searchQuery}
                  type="submit"
                  variant="btn btn-outline-success notInTableButton rounded"
                  onClick={() => {
                    dispatch(
                      getProductsAttempt({
                        search: searchQuery,
                        isDeleted: true,
                      })
                    );
                    setCurrentPage(1);
                  }}
                >
                  Search
                </Button>
              </div>
              {renderTable()}
              {products.length === 0 ? (
                <h5 className="text-center mt-5">No products in trash</h5>
              ) : null}
            </>
            <div className={styles.paginationWrapper}>
              <Pagination
                postsPerPage={16}
                totalPosts={count}
                paginate={paginate}
                nextPage={nextPage}
                prevPage={prevPage}
                totalPageNumber={Math.ceil(count / 16)}
                currentPage={currentPage}
                visibilityAllPageNumbers={visibilityAllPageNumbers}
                changeVisibilityAllPageNumbers={changeVisibilityAllPageNumbers}
                // setSelectedPageToJump={setJumpedPage}
                // selectedPageToJump={selectedPageToJump}
              />
            </div>
          </Tab>
        </Tabs>
      </Container>
    </div>
  );
};

export default OrdersScreen;
