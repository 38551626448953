import { takeEvery, put, call, select } from "redux-saga/effects";
import axios from "axios";

import {
  UPDATE_PROFILE_AFTER_REGISTRATION_ATTEMPT,
  updateProfileAfterRegistrationSuccess,
  updateProfileAfterRegistrationError,
} from "../actions/profile";

import { serverURL } from "../../../env";

// eslint-disable-next-line import/no-anonymous-default-export
export default function* () {
  yield takeEvery(UPDATE_PROFILE_AFTER_REGISTRATION_ATTEMPT, handleUpdate);
}

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else {
    var err = new Error(response.statusText);
    err.response = response;
    throw err;
  }
}

function updateProfile(requestURL, options) {
  return axios({
    method: options.method,
    headers: options.headers,
    url: requestURL,
    data: options.body,
  }).then(checkStatus);
}

function* handleUpdate(payload) {
  // const user_token = yield select(({ auth }) => auth.authToken);
  const requestURL = `${serverURL}/users/registration_profile/${payload.data.customerId}/`;
  const options = {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: payload.data.authToken,
    },
    body: payload.data.data_to_server,
  };

  try {
    const response = yield call(updateProfile, requestURL, options);

    yield put(updateProfileAfterRegistrationSuccess(response.data));
  } catch (err) {
    yield put(updateProfileAfterRegistrationError(err));
  }
}
