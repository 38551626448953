/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import styles from "./AddProductScreen.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  createProductsAttempt,
  resetCreateProduct,
} from "../../../redux/actions/products";
import { useToasts } from "react-toast-notifications";
import { Form, Row, Col, Container, Button } from "react-bootstrap";
import placeholder from "../../../../assets/images/placeholder.png";
import Select from "react-select";
import { createSlug } from "../../../utils/createSlug";
import { SiteMap } from "../../../components/BreadCrumbs/BreadCrumbs";
import history from "../../../utils/history";

const AddProductScreen = () => {
  const navLinks = [
    { href: "/admin/products", name: "Products" },
    { href: "/admin/add-product", name: "Add new product" },
  ];
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { createProductsSuccess } = useSelector((state) => state.products);
  const { vendors } = useSelector((state) => state.vendors);
  const { categories } = useSelector((state) => state.categories);
  const [productName, setProductName] = useState("");
  const [purchaseTaxCode, setPurchaseTaxCode] = useState("");
  const [salesTaxCode, setSalesTaxCode] = useState("");
  const [cost, setCost] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [productBarcode, setProductBarcode] = useState("");
  const [productPrice, setProductPrice] = useState("");
  const [image, setImage] = useState({ preview: "", raw: "" });
  const [subCategories, setSubCategories] = useState([]);
  const [category, setCategory] = useState();
  const [subCategory, setSubCategory] = useState();
  const [boxQuantity, setBoxQuantity] = useState("");
  const [orderBelow, setOrderBelow] = useState("");
  const [discoundPercentage, setDiscountPercentage] = useState("");
  const [vendor, setVendor] = useState("");
  const [actions, setActions] = useState("");
  const [ordering, setOrdering] = useState("");

  const groupProducts = () => {
    return categories
      .map((kategorija) => {
        const saParentom = categories.filter(
          (dete) => dete.parent_category?.id === kategorija.id
        );

        if (kategorija.parent_category === null) {
          return {
            value: kategorija.id,
            label: kategorija.name,
            children: saParentom.map((podkategorija) => {
              return {
                value: podkategorija.id,
                label: podkategorija.name,
              };
            }),
          };
        }
      })
      .filter(function (el) {
        return el != null;
      });
  };

  const renderSelect = () => {
    const style = {
      control: (base) => ({
        ...base,
        border: 0,
        // This line disable the blue border
        boxShadow: "none",
      }),
    };

    let categories = groupProducts();

    const handleCategoryChange = (selectedOption) => {
      setCategory(selectedOption);
      setSubCategory();
      setSubCategories(
        categories.find((category) => category.value === selectedOption.value)
          .children
      );
    };

    return (
      <>
        <Form.Label>Product category</Form.Label>
        <Select
          value={category}
          onChange={handleCategoryChange}
          options={categories}
          placeholder="Select category"
          className="abc"
          classNamePrefix="react-select"
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary25: "rgba(27, 154, 62, 0.2)",
              primary: "rgba(27, 154, 62, 0.4)",
              primary50: "#DEEBF4",
            },
          })}
          styles={style}
        />
        {subCategories?.length > 0 ? (
          <>
            <Form.Label className="mt-2">Product subcategory</Form.Label>
            <Select
              value={subCategory ?? ""}
              onChange={(selectedOption) => setSubCategory(selectedOption)}
              options={subCategories}
              placeholder="Select subcategory"
              className="abc"
              classNamePrefix="react-select"
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: "rgba(27, 154, 62, 0.2)",
                  primary: "rgba(27, 154, 62, 0.4)",
                  primary50: "#DEEBF4",
                },
              })}
              styles={style}
            />
          </>
        ) : null}
      </>
    );
  };

  const handleChange = (e) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };

  const renderVendorPicker = () => {
    const options = vendors.map((vendor) => {
      return {
        label: vendor?.name,
        value: vendor?.id,
      };
    });

    const style = {
      control: (base) => ({
        ...base,
        border: 0,
        // This line disable the blue border
        boxShadow: "none",
      }),
    };

    return (
      <>
        <Form.Label>Select vendor</Form.Label>
        <Select
          value={vendor}
          onChange={(selectedOption) => setVendor(selectedOption)}
          options={options}
          placeholder="Select vendor"
          className="abc"
          classNamePrefix="react-select"
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary25: "rgba(27, 154, 62, 0.2)",
              primary: "rgba(27, 154, 62, 0.4)",
              primary50: "#DEEBF4",
            },
          })}
          styles={style}
        />
      </>
    );
  };

  const renderActionsPicker = () => {
    const options = [
      {
        label: "None",
        value: "None",
      },
      { label: "10-1", value: "10-1" },
    ];

    const style = {
      control: (base) => ({
        ...base,
        border: 0,
        // This line disable the blue border
        boxShadow: "none",
      }),
    };

    return (
      <>
        <Form.Label>Select action (example: buy 10, get 1 gratis)</Form.Label>
        <Select
          value={actions}
          onChange={(selectedOption) => setActions(selectedOption)}
          options={options}
          placeholder="Select action"
          className="abc"
          classNamePrefix="react-select"
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary25: "rgba(27, 154, 62, 0.2)",
              primary: "rgba(27, 154, 62, 0.4)",
              primary50: "#DEEBF4",
            },
          })}
          styles={style}
        />
      </>
    );
  };

  useEffect(() => {
    if (createProductsSuccess) {
      setCategory("");
      setVendor("");
      setProductName("");
      setProductDescription("");
      setProductBarcode("");
      setProductPrice("");
      setSubCategory();
      setImage({ preview: "", raw: "" });
      setPurchaseTaxCode("");
      setSalesTaxCode("");
      setCost("");
      setBoxQuantity("");
      setActions("");
      setOrderBelow("");
      setDiscountPercentage("");
      setOrdering("");
      addToast(`Product added succesfully`, {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 2000,
        onDismiss: () => {
          dispatch(resetCreateProduct());
          history.goBack();
        },
      });
    }
  }, [createProductsSuccess]);

  const addProductHandler = (e) => {
    e.preventDefault();
    const formData = new FormData();

    formData.append("image", image.raw);
    formData.append("item", productName);
    formData.append("name", productDescription);
    formData.append("barcode", productBarcode);
    formData.append("stock", true);
    formData.append("quantity", 1);
    formData.append("price", productPrice);
    formData.append("is_deleted", false);
    formData.append("slug", createSlug(productDescription));
    formData.append("purchase_tax_code", purchaseTaxCode?.toUpperCase());
    formData.append("sales_tax_code", salesTaxCode?.toUpperCase());
    formData.append("cost", cost);
    formData.append("box_quantity", Number(boxQuantity));
    formData.append("need_to_reorder_below", Number(orderBelow));
    formData.append("actions", actions?.value);

    if (ordering !== "") {
      formData.append("ordering", Number(ordering));
    }

    if (discoundPercentage) {
      formData.append("special", Number(discoundPercentage));
    }

    if (vendor?.value) {
      formData.append("vendor", Number(vendor?.value));
    }

    if (subCategory?.value) {
      formData.append("cat", `${category?.value},${subCategory?.value}`);
      dispatch(createProductsAttempt(formData));
    } else {
      formData.append("cat", `${category?.value}`);
      dispatch(createProductsAttempt(formData));
    }
  };

  return (
    <div className={styles.addProductScreen}>
      <Container>
        <SiteMap hrefIn={"/admin/add-product"} items={navLinks} />
        {/* <h3 className={styles.addnNewProductHeading}>Add new product</h3> */}
        <Row className="justify-content-md-center">
          <Col xs={12} md={5}>
            <div>
              <label htmlFor="upload-button">
                {image.preview ? (
                  <img
                    src={image.preview}
                    alt="miraco-product"
                    className="img-fluid rounded"
                  />
                ) : (
                  <>
                    <img
                      src={placeholder}
                      alt="miraco-product"
                      className="img-fluid rounded"
                    />
                  </>
                )}
              </label>
              <input
                type="file"
                id="upload-button"
                className="d-none"
                onChange={handleChange}
              />
              <label
                className="btn btn-success d-block w-100 rounded"
                htmlFor="upload-button"
              >
                {image?.preview
                  ? "Change product image"
                  : "Upload product image"}
              </label>
              <br />
            </div>
          </Col>
          <Col xs={12} md={7}>
            <Form
              onSubmit={addProductHandler}
              className={styles.addProductForm}
            >
              <Form.Group controlId="productCategory">
                {renderSelect()}
              </Form.Group>
              <Form.Group controlId="name">
                <Form.Label>Item</Form.Label>
                <Form.Control
                  className={styles.myInput}
                  type="text"
                  placeholder="Enter item"
                  value={productName}
                  onChange={(e) => {
                    setProductName(e.target.value);
                  }}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId="desc">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  className={styles.myInput}
                  type="text"
                  placeholder="Enter description"
                  value={productDescription}
                  onChange={(e) => {
                    setProductDescription(e.target.value);
                  }}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId="barcode">
                <Form.Label>Product barcode</Form.Label>
                <Form.Control
                  className={styles.myInput}
                  type="text"
                  placeholder="Enter product barcode"
                  value={productBarcode}
                  onChange={(e) => {
                    setProductBarcode(e.target.value);
                  }}
                ></Form.Control>
              </Form.Group>

              <Form.Group controlId="price">
                <Form.Label>Product sales code</Form.Label>
                <Form.Control
                  className={styles.myInput}
                  type="text"
                  placeholder="G or E"
                  value={salesTaxCode}
                  onChange={(e) => {
                    setSalesTaxCode(e.target.value);
                  }}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId="price">
                <Form.Label>Product tax code</Form.Label>
                <Form.Control
                  className={styles.myInput}
                  type="text"
                  placeholder="G or E"
                  value={purchaseTaxCode}
                  onChange={(e) => {
                    setPurchaseTaxCode(e.target.value);
                  }}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId="price">
                <Form.Label>Cost</Form.Label>
                <Form.Control
                  className={styles.myInput}
                  type="text"
                  placeholder="Enter product cost"
                  value={cost}
                  onChange={(e) => {
                    setCost(e.target.value);
                  }}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId="price">
                <Form.Label>Product price</Form.Label>
                <Form.Control
                  className={styles.myInput}
                  type="text"
                  placeholder="Enter product price"
                  value={productPrice}
                  onChange={(e) => {
                    setProductPrice(e.target.value);
                  }}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId="price">
                <Form.Label>Box quantity (items per box)</Form.Label>
                <Form.Control
                  className={styles.myInput}
                  type="text"
                  placeholder="Enter box quantity"
                  value={boxQuantity}
                  onChange={(e) => {
                    setBoxQuantity(e.target.value);
                  }}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId="vendor">{renderVendorPicker()}</Form.Group>
              <Form.Group controlId="actions">
                {renderActionsPicker()}
              </Form.Group>
              <Form.Group controlId="reorder">
                <Form.Label>Need to order below</Form.Label>
                <Form.Control
                  className={styles.myInput}
                  type="text"
                  placeholder="Enter number"
                  value={orderBelow}
                  onChange={(e) => {
                    setOrderBelow(e.target.value);
                  }}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId="reorder">
                <Form.Label>
                  Discount in % (0 - 100%, leave blank for 0)
                </Form.Label>
                <Form.Control
                  className={styles.myInput}
                  type="text"
                  placeholder="Enter only number without %"
                  value={discoundPercentage}
                  onChange={(e) => {
                    if (e.target.value > 100) {
                      setDiscountPercentage("100");
                    } else {
                      setDiscountPercentage(
                        e.target.value
                          .replace(/[^0-9.]/g, "")
                          .replace(/(\..*?)\..*/g, "$1")
                      );
                    }
                  }}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId="ordering">
                <Form.Label>Ordering</Form.Label>
                <Form.Control
                  className={styles.myInput}
                  type="text"
                  placeholder="Enter ordering, example enter 1"
                  value={ordering}
                  onChange={(e) => {
                    setOrdering(e.target.value);
                  }}
                ></Form.Control>
              </Form.Group>
              {/* {change && error && <ErrorMessage>{error}</ErrorMessage>} */}
              <Button
                variant="btn btn-outline-success rounded notInTableButton"
                type="submit"
                disabled={!category?.value}
              >
                Create Product
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AddProductScreen;
