import { takeEvery, put, call, select } from "redux-saga/effects";
import axios from "axios";

import {
  ADD_VENDOR_ATTEMPT,
  addVendorSuccess,
  addVendorError,
} from "../actions/vendors";

import { serverURL } from "../../../env";

// eslint-disable-next-line import/no-anonymous-default-export
export default function* () {
  yield takeEvery(ADD_VENDOR_ATTEMPT, handleCreate);
}

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else {
    var err = new Error(response.statusText);
    err.response = response;
    throw err;
  }
}

function createOrder(requestURL, options) {
  return axios({
    method: options.method,
    headers: options.headers,
    url: requestURL,
    data: options.body,
  }).then(checkStatus);
}

function* handleCreate(payload) {
  const user_token = yield select(({ auth }) => auth.authToken);
  const requestURL = `${serverURL}/vendors/details/`;
  const options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: user_token,
    },
    body: payload.data,
  };

  try {
    const response = yield call(createOrder, requestURL, options);
    yield put(addVendorSuccess(response.data));
  } catch (err) {
    yield put(addVendorError(err));
  }
}
