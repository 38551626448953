//rute
import NotVerifiedScreen from "../screens/NotVerifiedScreen/NotVerifiedScreen";

export const customerRoutesNotVerified = [
  {
    title: "Not verified",
    path: "/waiting-verification",
    component: NotVerifiedScreen,
    show: false,
  },
];
