/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import styles from "./SingleOrderScreenAdmin.module.css";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { SiteMap } from "../../../components/BreadCrumbs/BreadCrumbs";
import { Container, Row, Col } from "react-bootstrap";
import { FaEnvelope, FaPhoneAlt, FaMapMarkerAlt, FaUser } from "react-icons/fa";
import placeholder from "../../../../assets/images/placeholder.png";
import { LinkContainer } from "react-router-bootstrap";
import moment from "moment-timezone";

const SingleOrderScreen = () => {
  moment.tz.setDefault("Canada/Pacific");

  const { id } = useParams();

  // console.log(id)

  const navLinks = [
    { href: "/admin/orders", name: "Customers orders" },
    { href: `/order/${id}`, name: `Order #${id}` },
  ];
  const { customerOrders } = useSelector((state) => state.orders);

  const singleOrder = customerOrders.find((order) => order?.id === Number(id));

  // console.log(singleOrder)

  useEffect(() => {
    document.title = "Miraco - Single Order";
    window.scrollTo({
      top: 0,
    });
  }, []);

  const rendeOrder = () => {
    return (
      <>
        <div className={styles.heading}>
          <div className={styles.heading_flag}></div>

          <div className={styles.heading_name}>
            <div>
              <p>Product</p>
            </div>
          </div>

          <div className={styles.heading_population}>
            <div>
              <p>Quantity</p>
            </div>
          </div>

          <div className={styles.heading_population}>
            <div>
              <p>Price</p>
            </div>
          </div>

          <div className={styles.heading_gini}>
            <div>Barcode</div>
          </div>
        </div>

        {singleOrder?.purchased_items?.map((product, index) => (
          <React.Fragment key={product.id}>
            {index === 0 ? <hr /> : null}
            <div className={styles.row}>
              <div className={styles.flag}>
                <img
                  src={product?.product?.image ?? placeholder}
                  alt={product?.product?.image ?? placeholder}
                  className="img-fluid"
                />
              </div>

              <div className={styles.name}>
                <p>{product?.product?.name}</p>
              </div>

              <div className={styles.population}>
                <p>{product?.quantity}</p>
              </div>

              <div className={styles.population}>
                <p>
                  {" "}
                  {"CAD "}
                  {(
                    product?.product?.price -
                    product?.product?.price * (product?.product?.special / 100)
                  ).toFixed(2)}
                </p>
              </div>

              <div className={styles.gini}>
                <p>{product?.product?.barcode}</p>
              </div>
            </div>
            <hr />
          </React.Fragment>
        ))}
      </>
    );
  };

  const renderCustomer = () => {
    return (
      <div className={styles.customerDiv}>
        <div className={styles.customerDataDiv}>
          <h6>Contact info</h6>
          <div className={styles.oneData}>
            <p className={styles.commonOneDataText}>
              <FaUser className="mr-2" /> {singleOrder?.customer?.company}
            </p>
            <p className={styles.commonOneDataText}>
              <FaEnvelope className="mr-2" /> {singleOrder?.customer?.email}
            </p>
            <p className={styles.commonOneDataText}>
              <FaPhoneAlt className="mr-2" />
              {singleOrder?.customer?.phone_number}
            </p>
          </div>
        </div>
        <div className={styles.commonDiv}>
          <h6>Shipping details</h6>
          <div className={styles.oneData}>
            <p className={styles.commonOneDataText}>
              <FaMapMarkerAlt className="mr-2" />
              {singleOrder?.customer?.address}, {singleOrder?.customer?.state},{" "}
              {singleOrder?.customer?.zipcode},{" "}
              {singleOrder?.customer?.townCity},{" "}
              {singleOrder?.customer?.country}
            </p>
          </div>
        </div>
        <div className={styles.commonDiv}>
          <h6>Payment summary</h6>
          <div className={styles.oneData}>
            <p className={styles.commonOneDataText}>
              Total paid by customer $CAD {singleOrder?.price?.toFixed(2)}
            </p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.singleOrderScreen}>
      <Container>
        <SiteMap hrefIn={`/order/${id}`} items={navLinks} />
        <Row>
          <Col sm={12} md={7}>
            <div className={styles.orderHeaderLeft}>
              <h3>Order #{id}</h3>
              <span className={styles.orderPaidStatus}>
                {singleOrder?.status}
              </span>
              <span className={styles.orderDeliverStatus}>
                {singleOrder?.delivered_status}
              </span>
            </div>
          </Col>
          <Col sm={12} md={5}>
            {!singleOrder?.confirmed ? (
              <LinkContainer
                to={{
                  pathname: `/admin/order/edit/${singleOrder?.id}`,
                  singleOrder: singleOrder,
                }}
                className="btn btn-outline-success rounded notInTableButtonEditOrder w-25"
              >
                <span>Edit order</span>
              </LinkContainer>
            ) : (
              <h6>
                Confirmed at{" "}
                {moment(singleOrder?.confirmed_at).format("MM/DD/YYYY h:m a")}
              </h6>
            )}
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={7}>
            {rendeOrder()}
          </Col>
          <Col sm={12} md={5}>
            {renderCustomer()}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SingleOrderScreen;
