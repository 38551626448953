export const GET_PROFILE_ATTEMPT = "GET_PROFILE_ATTEMPT";
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const GET_PROFILE_ERROR = "GET_PROFILE_ERROR";

export const getProfileAttempt = (data) => ({
  type: GET_PROFILE_ATTEMPT,
  data,
});

export const getProfileSuccess = (data) => ({
  type: GET_PROFILE_SUCCESS,
  data,
});

export const getProfileError = (data) => ({
  type: GET_PROFILE_ERROR,
  data,
});

export const UPDATE_PROFILE_ATTEMPT = "UPDATE_PROFILE_ATTEMPT";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_ERROR = "UPDATE_PROFILE_ERROR";
export const RESET_UPDATE_PROFILE = "RESET_UPDATE_PROFILE";

export const updateProfileAttempt = (data) => ({
  type: UPDATE_PROFILE_ATTEMPT,
  data,
});

export const updateProfileSuccess = (data) => ({
  type: UPDATE_PROFILE_SUCCESS,
  data,
});

export const updateProfileError = (data) => ({
  type: UPDATE_PROFILE_ERROR,
  data,
});

export const resetUpdateProfile = (data) => ({
  type: RESET_UPDATE_PROFILE,
  data,
});

export const UPDATE_PROFILE_AFTER_REGISTRATION_ATTEMPT =
  "UPDATE_PROFILE_AFTER_REGISTRATION_ATTEMPT";
export const UPDATE_PROFILE_AFTER_REGISTRATION_SUCCESS =
  "UPDATE_PROFILE_AFTER_REGISTRATION_SUCCESS";
export const UPDATE_PROFILE_AFTER_REGISTRATION_ERROR =
  "UPDATE_PROFILE_AFTER_REGISTRATION_ERROR";

export const updateProfileAfterRegistrationAttempt = (data) => ({
  type: UPDATE_PROFILE_AFTER_REGISTRATION_ATTEMPT,
  data,
});

export const updateProfileAfterRegistrationSuccess = (data) => ({
  type: UPDATE_PROFILE_AFTER_REGISTRATION_SUCCESS,
  data,
});

export const updateProfileAfterRegistrationError = (data) => ({
  type: UPDATE_PROFILE_AFTER_REGISTRATION_ERROR,
  data,
});
