/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import styles from "./VendorsListScreen.module.css";
import { Container, Table, Button, Form, Row, Col } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { getVendorsAttempt } from "../../../redux/actions/vendors";
import Loader from "../../../components/Loader/Loader";

const VendorsListScreen = () => {
  const dispatch = useDispatch();

  const { vendors, getVendorsAttempt: loader } = useSelector(
    (state) => state.vendors
  );

  useEffect(() => {
    document.title = "Miraco - Admin Vendors";
    window.scrollTo({
      top: 0,
    });
    dispatch(getVendorsAttempt());
  }, []);

  const [searchQuery, setSearchQuery] = useState("");

  const filteredVendors = vendors.filter((vendor) => {
    if (searchQuery === "") {
      return vendor;
    } else {
      return vendor.name.toLowerCase().includes(searchQuery);
    }
  });

  const renderTable = () => {
    return (
      <Table hover responsive className="table-sm">
        <thead>
          <tr>
            <th>Vendor</th>
            <th>Primary contact</th>
            <th>Main phone</th>
            <th>Fax</th>
            <th>Email</th>
          </tr>
        </thead>
        <tbody>
          {filteredVendors
            ?.sort((a, b) => a.id - b.id)
            .map((vendor) => {
              return (
                <tr key={vendor?.id}>
                  <td>{vendor?.name}</td>
                  <td>{vendor?.primary_contact}</td>
                  <td>{vendor?.main_phone}</td>
                  <td>{vendor?.fax}</td>
                  <td>{vendor?.email}</td>
                  <td>
                    <LinkContainer to={`/admin/edit-vendor/${vendor?.id}`}>
                      <Button variant="btn btn-sm btn-outline-success rounded">
                        Edit vendor
                      </Button>
                    </LinkContainer>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    );
  };

  if (loader) {
    return (
      <div
        style={{
          minHeight: "100vh",
          display: "flex",
          marginTop: "100px",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Loader type="Bars" />
        <h5 className="mt-2">Getting vendors...</h5>
      </div>
    );
  }

  return (
    <div className={styles.productsListScreen}>
      <Container>
        <div className="d-flex align-items-center justify-content-between">
          <h3 className={styles.productListHeading}>Manage vendors</h3>
          <LinkContainer to="/admin/add-vendor">
            <Button variant="btn btn-sm btn-outline-success rounded notInTableButton">
              Add vendor
            </Button>
          </LinkContainer>
        </div>
        <Row>
          <Col>
            <div className="mt-4">
              <Form.Control
                className="rounded"
                type="text"
                placeholder="Search vendor"
                value={searchQuery}
                onChange={(e) => {
                  setSearchQuery(e.target.value.toLowerCase());
                }}
              ></Form.Control>
            </div>
          </Col>
        </Row>
        {renderTable()}
      </Container>
    </div>
  );
};

export default VendorsListScreen;
