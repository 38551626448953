import { compose, createStore, applyMiddleware } from "redux";
import { persistStore, persistReducer, createTransform } from "redux-persist";
//import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import localForage from "localforage";
import createSagaMiddleware from "redux-saga";
import rootReducer from "../reducers";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";

// Korak 1 za sage import sage
import loginSaga from "../sagas/loginSaga";
import registerSaga from "../sagas/registerSaga";
import getProductsSaga from "../sagas/getProductsSaga";
import createProductsSaga from "../sagas/createProductsSaga";
import editProductSaga from "../sagas/editProductSaga";
import getCategorySaga from "../sagas/getCategorySaga";
import createCategorySaga from "../sagas/createCategorySaga";
import editCategorySaga from "../sagas/editCategorySaga";
import createOrderSaga from "../sagas/createOrderSaga";
import getMyOrdersSaga from "../sagas/getMyOrdersSaga";
import adminGetCustomerOrdersSaga from "../sagas/adminGetCustomerOrdersSaga";
import adminGetCustomersSaga from "../sagas/adminGetCustomersSaga";
import getCustomerProfileSaga from "../sagas/getCustomerProfileSaga";
import updateCustomerProfileSaga from "../sagas/updateCustomerProfileSaga";
import updateProfileAfterRegistrationSaga from "../sagas/updateProfileAfterRegistrationSaga";
import editOrderBillingDetailsSaga from "../sagas/editOrderBillingDetailsSaga";
import editOrderDeleteItemSaga from "../sagas/editOrderDeleteItemSaga";
import editOrderChangeQuantitySaga from "../sagas/editOrderChangeQuantitySaga";
import editOrderAddToOrderSaga from "../sagas/editOrderAddToOrderSaga";
import getNewArrivalsSaga from "../sagas/getNewArrivalsSaga";
import getSpecialProductsSaga from "../sagas/getSpecialProductsSaga";
import getTopSellingProductsSaga from "../sagas/getTopSellingProductsSaga";
import getVendorsSaga from "../sagas/getVendorsSaga";
import addVendorSaga from "../sagas/addVendorSaga";
import editVendorSaga from "../sagas/editVendorSaga";
import getResetCodeSaga from "../sagas/getResetCodeSaga";
import validateResetCodeSaga from "../sagas/validateResetCodeSaga";
import requestNewPasswordSaga from "../sagas/requestNewPasswordSaga";
import getProductsForOfflineSaveSaga from "../sagas/getProductsForOfflineSaveSaga";
import adminEditCustomersSaga from "../sagas/adminEditCustomersSaga";
import adminCreateOrderSaga from "../sagas/adminCreateOrderSaga";
import adminGetAllCustomersNoPaginationSaga from "../sagas/adminGetAllCustomersNoPaginationSaga";
import adminEditCustomerDataSaga from "../sagas/adminEditCustomerDataSaga";
import adminCreateCustomerSaga from "../sagas/adminCreateCustomerSaga";
import adminDeleteCustomerSaga from "../sagas/adminDeleteCustomerSaga";

const middlewares = [];

if (process.env.NODE_ENV === `development`) {
  const { logger } = require(`redux-logger`);

  middlewares.push(logger);
}

const persistConfig = {
  key: "root1",
  version: 1,
  storage: localForage,
  stateReconciler: autoMergeLevel2,
  transforms: [
    createTransform(JSON.stringify, (toRehydrate) =>
      JSON.parse(toRehydrate, (key, value) =>
        typeof value === "string" &&
        value.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/)
          ? new Date(value)
          : value
      )
    ),
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();

const store = compose(applyMiddleware(...middlewares, sagaMiddleware))(
  createStore
)(persistedReducer);

// then run the saga
// Korak 2 run sage
sagaMiddleware.run(loginSaga);
sagaMiddleware.run(registerSaga);
sagaMiddleware.run(getProductsSaga);
sagaMiddleware.run(createProductsSaga);
sagaMiddleware.run(editProductSaga);
sagaMiddleware.run(getCategorySaga);
sagaMiddleware.run(createCategorySaga);
sagaMiddleware.run(editCategorySaga);
sagaMiddleware.run(createOrderSaga);
sagaMiddleware.run(getMyOrdersSaga);
sagaMiddleware.run(adminGetCustomerOrdersSaga);
sagaMiddleware.run(adminGetCustomersSaga);
sagaMiddleware.run(getCustomerProfileSaga);
sagaMiddleware.run(updateCustomerProfileSaga);
sagaMiddleware.run(updateProfileAfterRegistrationSaga);
sagaMiddleware.run(editOrderBillingDetailsSaga);
sagaMiddleware.run(editOrderDeleteItemSaga);
sagaMiddleware.run(editOrderChangeQuantitySaga);
sagaMiddleware.run(editOrderAddToOrderSaga);
sagaMiddleware.run(getNewArrivalsSaga);
sagaMiddleware.run(getSpecialProductsSaga);
sagaMiddleware.run(getTopSellingProductsSaga);
sagaMiddleware.run(getVendorsSaga);
sagaMiddleware.run(addVendorSaga);
sagaMiddleware.run(editVendorSaga);
sagaMiddleware.run(getResetCodeSaga);
sagaMiddleware.run(validateResetCodeSaga);
sagaMiddleware.run(requestNewPasswordSaga);
sagaMiddleware.run(getProductsForOfflineSaveSaga);
sagaMiddleware.run(adminEditCustomersSaga);
sagaMiddleware.run(adminCreateOrderSaga);
sagaMiddleware.run(adminGetAllCustomersNoPaginationSaga);
sagaMiddleware.run(adminEditCustomerDataSaga);
sagaMiddleware.run(adminCreateCustomerSaga);
sagaMiddleware.run(adminDeleteCustomerSaga);

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  let persistor = persistStore(store);
  return { store, persistor };
};
