/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import styles from "./MyOrdersScreen.module.css";
import { useSelector, useDispatch } from "react-redux";
import { LinkContainer } from "react-router-bootstrap";
import moment from "moment-timezone";
import { Container, Table, Button, Col } from "react-bootstrap";
import { getMyOrdersAttempt } from "../../redux/actions/orders";
// import emptyCart from "../../../assets/images/empty-cart.svg";
// import Loader from "../../components/Loader/Loader";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import Pagination from "../../components/Pagination/Pagination";

const MyOrdersScreen = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    document.title = "Miraco - My Orders";
    dispatch(getMyOrdersAttempt({ sort: "-created_at" }));
  }, []);

  const {
    orders,
    //  getMyOrdersAttempt: loader,
    myOrdersNext: next,
    myOrdersPrevious: previous,
    myOrdersCount: count,
  } = useSelector((state) => state.orders);

  const sortOptions = [
    {
      title: "Purchased date - newest",
      name: "-created_at",
    },
    { title: "Purchased date - oldest", name: "created_at" },
    { title: "Price - Lowest to Highest", name: "price" },
    { title: "Price - Highest to Lowest", name: "-price" },
  ];

  const filterOptions = [
    { title: "All" },
    { title: "Paid" },
    { title: "Not paid" },
    { title: "Delivered" },
    { title: "Not delivered" },
  ];

  const [activeSort, setActiveSort] = useState(sortOptions[0]);
  const [activeFilter, setActiveFilter] = useState(filterOptions[0]);

  const [currentPage, setCurrentPage] = useState(1);
  const [visibilityAllPageNumbers, setVisibilityAllPageNumbers] =
    useState(false);
  const [selectedPageToJump, setSelectedPageToJump] = useState("");

  const paginate = (pageNum) => setCurrentPage(pageNum);

  const nextPage = () => setCurrentPage(currentPage + 1);

  const prevPage = () => setCurrentPage(currentPage - 1);

  const changeVisibilityAllPageNumbers = (visibility) =>
    setVisibilityAllPageNumbers(visibility);

  const setJumpedPage = (pageObj) => setSelectedPageToJump(pageObj);

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
    if (currentPage === 1) {
      dispatch(getMyOrdersAttempt({ sort: "-created_at" }));
    } else if (currentPage === Number(next?.slice(-1))) {
      let pom_next = next.substring(0, next.indexOf("page"));
      pom_next = `${pom_next}page=${currentPage}`;
      dispatch(getMyOrdersAttempt({ payloadUrl: pom_next }));
    } else if (currentPage > Number(next?.slice(-1))) {
      let pom_next = next.substring(0, next.indexOf("page"));
      pom_next = `${pom_next}page=${currentPage}`;
      dispatch(getMyOrdersAttempt({ payloadUrl: pom_next }));
    } else {
      dispatch(getMyOrdersAttempt({ payloadUrl: previous }));
    }
  }, [currentPage]);

  const renderTable = () => {
    return (
      <Table hover responsive className="table-sm">
        <thead>
          <tr>
            <th>#</th>
            <th>Order date</th>
            <th>Order status</th>
            <th>Deliver status</th>
            <th>Orderd Items</th>
            <th>Price $CAD</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {orders?.map((order) => {
            const renderWhatIsOrdered = () => {
              if (order?.purchased_items?.length > 1) {
                let firstItem = order?.purchased_items?.slice(0, 1);
                return (
                  <span>
                    {firstItem[0]?.product?.name}{" "}
                    <span className={styles.oneMoreSpan}>
                      and {order?.purchased_items?.slice(1)?.length} more
                    </span>
                  </span>
                );
              } else {
                return (
                  <span>
                    {order?.purchased_items?.map(
                      (order) => order?.product?.name
                    )}
                  </span>
                );
              }
            };

            return (
              <tr key={order?.id}>
                <td>{order?.id}</td>
                <td>{moment(order?.created_at).format("D MMM, h:mm a")}</td>
                <td>{order?.status}</td>
                <td>{order?.delivered_status}</td>
                <td>{renderWhatIsOrdered()}</td>
                <td>{order?.price?.toFixed(2)}</td>
                <td>
                  <LinkContainer to={`/order/${order?.id}`}>
                    <Button variant="btn btn-sm btn-outline-success rounded">
                      Details
                    </Button>
                  </LinkContainer>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  };

  // const renderNoOrdersMessage = () => {
  //   return (
  //     <div className={styles.noProductsMessageDiv}>
  //       <img src={emptyCart} alt="cart-empty" />
  //       <h4 className={styles.noItemsInCartText}>
  //         You don't have any order <br /> yet.
  //       </h4>
  //       <h5 className={styles.noItemsInCartText}>
  //         Add some products to <br /> create order
  //       </h5>
  //       <LinkContainer
  //         className="btn btn-outline-success mt-2 rounded notInTableButton"
  //         to="/products"
  //       >
  //         <Button variant="btn btn-sm btn-outline-success rounded">
  //           Shop now
  //         </Button>
  //       </LinkContainer>
  //     </div>
  //   );
  // };

  // if (loader) {
  //   return (
  //     <div
  //       style={{
  //         minHeight: "100vh",
  //         display: "flex",
  //         marginTop: "100px",
  //         flexDirection: "column",
  //         alignItems: "center",
  //       }}
  //     >
  //       <Loader type="Bars" />
  //       <h5 className="mt-2">Getting your orders...</h5>
  //     </div>
  //   );
  // }

  return (
    <div className={styles.myOrdersScreen}>
      <Container>
        <h4 className={styles.myOrdersHeading}>My Orders</h4>
        <Col sm={12}>
          <div className="d-flex my-3">
            <div className="d-flex align-items-center">
              <h6 className="mr-3">Sort by </h6>
              <DropdownButton
                id="dropdown-item-button-order"
                title={activeSort?.title}
                size="sm"
              >
                {sortOptions.map((option, index) => {
                  let isActiveSort =
                    option?.title === activeSort?.title ? true : false;
                  return (
                    <Dropdown.Item
                      className={
                        isActiveSort ? styles.activeSort : styles.notActiveSort
                      }
                      key={index}
                      onClick={() => {
                        setActiveSort(option);
                        dispatch(getMyOrdersAttempt({ sort: option.name }));
                      }}
                    >
                      {option.title}
                    </Dropdown.Item>
                  );
                })}
              </DropdownButton>
            </div>
            <div className="d-flex align-items-center">
              <h6 className="ml-5 mr-3">Show</h6>
              <DropdownButton
                id="dropdown-item-button-order"
                title={activeFilter?.title}
                size="sm"
              >
                {filterOptions.map((option, index) => {
                  let isActiveFilter =
                    option?.title === activeFilter?.title ? true : false;
                  return (
                    <Dropdown.Item
                      className={
                        isActiveFilter
                          ? styles.activeSort
                          : styles.notActiveSort
                      }
                      key={index}
                      onClick={() => {
                        setActiveFilter(option);
                        if (option.title === "All") {
                          dispatch(
                            getMyOrdersAttempt({
                              sort: "-created_at",
                            })
                          );
                        } else if (option.title === "Paid") {
                          dispatch(
                            getMyOrdersAttempt({
                              status: "Paid",
                            })
                          );
                        } else if (option.title === "Not paid") {
                          dispatch(
                            getMyOrdersAttempt({
                              status: "Not paid",
                            })
                          );
                        } else if (option.title === "Delivered") {
                          dispatch(
                            getMyOrdersAttempt({
                              delivered_status: "Delivered",
                            })
                          );
                        } else if (option.title === "Not delivered") {
                          dispatch(
                            getMyOrdersAttempt({
                              delivered_status: "Not delivered",
                            })
                          );
                        }
                      }}
                    >
                      {option.title}
                    </Dropdown.Item>
                  );
                })}
              </DropdownButton>
            </div>
          </div>
        </Col>
        {/* {orders.length === 0 ? renderNoOrdersMessage() : renderTable()} */}
        {renderTable()}
        <div className={styles.paginationWrapper}>
          <Pagination
            postsPerPage={16}
            totalPosts={count}
            paginate={paginate}
            nextPage={nextPage}
            prevPage={prevPage}
            totalPageNumber={Math.ceil(count / 16)}
            currentPage={currentPage}
            visibilityAllPageNumbers={visibilityAllPageNumbers}
            changeVisibilityAllPageNumbers={changeVisibilityAllPageNumbers}
            setSelectedPageToJump={setJumpedPage}
            selectedPageToJump={selectedPageToJump}
          />
        </div>
      </Container>
    </div>
  );
};

export default MyOrdersScreen;
