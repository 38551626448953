import { combineReducers } from "redux";

import { authReducer } from "./authReducer";
import { productsReducer } from "./productsReducer";
import { cartReducer } from "./cartReducer";
import { ordersReducer } from "./ordersReducer";
import { categoriesReducer } from "./categoriesReducer";
import { profileReducer } from "./profileReducer";
import { vendorsReducer } from "./vendorsReducer";
import { offlineDataReducer } from "./offlineDataReducer";

export default combineReducers({
  auth: authReducer,
  profile: profileReducer,
  products: productsReducer,
  categories: categoriesReducer,
  orders: ordersReducer,
  cart: cartReducer,
  vendors: vendorsReducer,
  offlineData: offlineDataReducer,
});
