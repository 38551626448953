export const GET_PRODUCTS_ATTEMPT = "GET_PRODUCTS_ATTEMPT";
export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS";
export const GET_PRODUCTS_ERROR = "GET_PRODUCTS_ERROR";

export const getProductsAttempt = (data) => ({
  type: GET_PRODUCTS_ATTEMPT,
  data,
});

export const getProductsSuccess = (data) => ({
  type: GET_PRODUCTS_SUCCESS,
  data,
});

export const getProductsError = (data) => ({
  type: GET_PRODUCTS_ERROR,
  data,
});

export const CREATE_PRODUCTS_ATTEMPT = "CREATE_PRODUCTS_ATTEMPT";
export const CREATE_PRODUCTS_SUCCESS = "CREATE_PRODUCTS_SUCCESS";
export const CREATE_PRODUCTS_ERROR = "CREATE_PRODUCTS_ERROR";
export const RESET_CREATE_PRODUCTS = "RESET_CREATE_PRODUCTS";

export const createProductsAttempt = (data) => ({
  type: CREATE_PRODUCTS_ATTEMPT,
  data,
});

export const createProductsSuccess = (data) => ({
  type: CREATE_PRODUCTS_SUCCESS,
  data,
});

export const createProductsError = (data) => ({
  type: CREATE_PRODUCTS_ERROR,
  data,
});

export const resetCreateProduct = () => ({
  type: RESET_CREATE_PRODUCTS,
});

export const EDIT_PRODUCTS_ATTEMPT = "EDIT_PRODUCTS_ATTEMPT";
export const EDIT_PRODUCTS_SUCCESS = "EDIT_PRODUCTS_SUCCESS";
export const EDIT_PRODUCTS_ERROR = "EDIT_PRODUCTS_ERROR";
export const RESET_EDIT_PRODUCTS = "RESET_EDIT_PRODUCTS";

export const editProductsAttempt = (data) => ({
  type: EDIT_PRODUCTS_ATTEMPT,
  data,
});

export const editProductsSuccess = (data) => ({
  type: EDIT_PRODUCTS_SUCCESS,
  data,
});

export const editProductsError = (data) => ({
  type: EDIT_PRODUCTS_ERROR,
  data,
});

export const resetEditProduct = () => ({
  type: RESET_EDIT_PRODUCTS,
});

export const SET_ACTIVE_PRODUCT_TAB = "SET_ACTIVE_PRODUCT_TAB";

export const setActiveProductTab = (data) => ({
  type: SET_ACTIVE_PRODUCT_TAB,
  data,
});

export const GET_SPECIAL_PRODUCTS_ATTTEMPT = "GET_SPECIAL_PRODUCTS_ATTTEMPT";
export const GET_SPECIAL_PRODUCTS_SUCCESS = "GET_SPECIAL_PRODUCTS_SUCCESS";
export const GET_SPECIAL_PRODUCTS_ERROR = "GET_SPECIAL_PRODUCTS_ERROR";

export const getSpecialProductsAttempt = (data) => ({
  type: GET_SPECIAL_PRODUCTS_ATTTEMPT,
  data,
});

export const getSpecialProductsSuccess = (data) => ({
  type: GET_SPECIAL_PRODUCTS_SUCCESS,
  data,
});

export const getSpecialProductsError = (data) => ({
  type: GET_SPECIAL_PRODUCTS_ERROR,
  data,
});

export const GET_NEW_PRODUCTS_ATTEMPT = "GET_NEW_PRODUCTS_ATTEMPT";
export const GET_NEW_PRODUCTS_SUCCESS = "GET_NEW_PRODUCTS_SUCCESS";
export const GET_NEW_PRODUCTS_ERROR = "GET_NEW_PRODUCTS_ERROR";

export const getNewProductsAttempt = (data) => ({
  type: GET_NEW_PRODUCTS_ATTEMPT,
  data,
});

export const getNewProductsSuccess = (data) => ({
  type: GET_NEW_PRODUCTS_SUCCESS,
  data,
});

export const getNewProductsError = (data) => ({
  type: GET_NEW_PRODUCTS_ERROR,
  data,
});

export const GET_TOP_SELLING_PRODUCTS_ATTEMPT =
  "GET_TOP_SELLING_PRODUCTS_ATTEMPT";
export const GET_TOP_SELLING_PRODUCTS_SUCCESS =
  "GET_TOP_SELLING_PRODUCTS_SUCCESS";
export const GET_TOP_SELLING_PRODUCTS_ERROR = "GET_TOP_SELLING_PRODUCTS_ERROR";

export const getTopSellingProductsAttempt = (data) => ({
  type: GET_TOP_SELLING_PRODUCTS_ATTEMPT,
  data,
});

export const getTopSellingProductsSuccess = (data) => ({
  type: GET_TOP_SELLING_PRODUCTS_SUCCESS,
  data,
});

export const getTopSellingProductsError = (data) => ({
  type: GET_TOP_SELLING_PRODUCTS_ERROR,
  data,
});

export const UPDATE_PRODUCT_FROM_LIST = "UPDATE_PRODUCT_FROM_LIST";

export const updateProductFromList = (data) => ({
  type: UPDATE_PRODUCT_FROM_LIST,
  data,
});
