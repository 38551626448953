export const GET_CATEGORY_ATTEMPT = "GET_CATEGORY_ATTEMPT";
export const GET_CATEGORY_SUCCESS = "GET_CATEGORY_SUCCESS";
export const GET_CATEGORY_ERROR = "GET_CATEGORY_ERROR";

export const getCategoryAttempt = (data) => ({
  type: GET_CATEGORY_ATTEMPT,
  data,
});

export const getCategorySuccess = (data) => ({
  type: GET_CATEGORY_SUCCESS,
  data,
});

export const getCategoryError = (data) => ({
  type: GET_CATEGORY_ERROR,
  data,
});

export const CREATE_CATEGORY_ATTEMPT = "CREATE_CATEGORY_ATTEMPT";
export const CREATE_CATEGORY_SUCCESS = "CREATE_CATEGORY_SUCCESS";
export const CREATE_CATEGORY_ERROR = "CREATE_CATEGORY_ERROR";
export const RESET_CREATE_CATEGORY = "RESET_CREATE_CATEGORY";

export const createCategoryAttempt = (data) => ({
  type: CREATE_CATEGORY_ATTEMPT,
  data,
});

export const createCategorySuccess = (data) => ({
  type: CREATE_CATEGORY_SUCCESS,
  data,
});

export const createCategoryError = (data) => ({
  type: CREATE_CATEGORY_ERROR,
  data,
});

export const resetCreateCategory = () => ({
  type: RESET_CREATE_CATEGORY,
});

export const EDIT_CATEGORY_ATTEMPT = "EDIT_CATEGORY_ATTEMPT";
export const EDIT_CATEGORY_SUCCESS = "EDIT_CATEGORY_SUCCESS";
export const EDIT_CATEGORY_ERROR = "EDIT_CATEGORY_ERROR";
export const RESET_EDIT_CATEGORY = "RESET_EDIT_CATEGORY";

export const editCategoryAttempt = (data) => ({
  type: EDIT_CATEGORY_ATTEMPT,
  data,
});

export const editCategorySuccess = (data) => ({
  type: EDIT_CATEGORY_SUCCESS,
  data,
});

export const editCategoryError = (data) => ({
  type: EDIT_CATEGORY_ERROR,
  data,
});

export const resetEditCategory = () => ({
  type: RESET_EDIT_CATEGORY,
});

export const SET_ACTIVE_CATEGORY_TAB = "SET_ACTIVE_CATEGORY_TAB";

export const setActiveCategoryTab = (data) => ({
  type: SET_ACTIVE_CATEGORY_TAB,
  data,
});
