import React from "react";
import styles from "./OfflineBar.module.css";
import { useNetwork } from "../../utils/checkInternet";

function OfflineBar() {
  const isConnected = useNetwork();

  return !isConnected ? (
    <div className={styles.offlineBar}>
      <p>No internet, please check your connection!</p>
    </div>
  ) : null;
}

export default OfflineBar;
