/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Form, Row, Col, Container, Button } from "react-bootstrap";
import styles from "./CreateCustomerScreen.module.css";
import { useDispatch, useSelector } from "react-redux";
import { adminCreateCustomerAttempt, resetAdminCreateCustomer } from "../../../redux/actions/orders";
import Loader from "../../../components/Loader/Loader";
import { useToasts } from "react-toast-notifications";

const OneCustomerDetailsScreen = () => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const {
    adminCreateCustomerAttempt: loading,
    adminCreateCustomerSuccess,
  } = useSelector((state) => state.orders);

  const [profileState, setProfileState] = useState({
    username: "",
    phone_number: "",
    first_name: "",
    last_name: "",
    country: "",
    address: "",
    townCity: "",
    state: "",
    zipcode: "",
    verified: "",
    company: "",
    email: "",
  });

  useEffect(() => {
    if (adminCreateCustomerSuccess) {
      addToast(`Customer created succesfully`, {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 2000,
        onDismiss: () => {
          dispatch(resetAdminCreateCustomer());
          setProfileState({
            username: "",
            phone_number: "",
            first_name: "",
            last_name: "",
            country: "",
            address: "",
            townCity: "",
            state: "",
            zipcode: "",
            verified: "",
            company: "",
            email: "",
          })
        },
      });
    }
  }, [adminCreateCustomerSuccess]);

  useEffect(() => {
    document.title = "Miraco - Customer profile";
    window.scrollTo({
      top: 0,
    });
  }, []);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      adminCreateCustomerAttempt({
        data_to_server: {
          email: profileState.email,
          username: profileState.email,
          phone_number: profileState.phone_number,
          first_name: profileState.first_name,
          last_name: profileState.last_name,
          country: profileState.country,
          address: profileState.address,
          townCity: profileState.townCity,
          state: profileState.state,
          zipcode: profileState.zipcode,
          company: profileState.company,
          verified: true,
        },
      })
    );
  };

  const handleChange = (e) => {
    setProfileState({
      ...profileState,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className={styles.updateProfilePage}>
      <Container>
        <Row className="justify-content-md-center">
          <Col xs={12} md={6}>
            <Form onSubmit={submitHandler} className={styles.loginForm}>
              <h3 className={styles.signInText}>Create customer</h3>
              <Form.Group controlId="company">
                <Form.Label>Company</Form.Label>
                <Form.Control
                  className={styles.myInput}
                  type="text"
                  name="company"
                  placeholder="Enter customer company name"
                  value={profileState.company}
                  onChange={handleChange}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId="email">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  className={styles.myInput}
                  type="email"
                  name="email"
                  placeholder="Enter email"
                  value={profileState.email}
                  onChange={handleChange}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId="password">
                <Form.Label>Phone number</Form.Label>
                <Form.Control
                  className={styles.myInput}
                  type="text"
                  name="phone_number"
                  placeholder="Enter phone number"
                  value={profileState.phone_number}
                  onChange={handleChange}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId="password">
                <Form.Label>First name</Form.Label>
                <Form.Control
                  className={styles.myInput}
                  type="text"
                  name="first_name"
                  placeholder="Enter first name"
                  value={profileState.first_name}
                  onChange={handleChange}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId="password">
                <Form.Label>Last name</Form.Label>
                <Form.Control
                  className={styles.myInput}
                  type="text"
                  name="last_name"
                  placeholder="Enter last name"
                  value={profileState.last_name}
                  onChange={handleChange}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId="password">
                <Form.Label>Customer country</Form.Label>
                <Form.Control
                  className={styles.myInput}
                  type="text"
                  name="country"
                  placeholder="Enter country"
                  value={profileState.country}
                  onChange={handleChange}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId="password">
                <Form.Label>Address</Form.Label>
                <Form.Control
                  className={styles.myInput}
                  type="text"
                  name="address"
                  placeholder="Enter customer address"
                  value={profileState.address}
                  onChange={handleChange}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId="password">
                <Form.Label>Town/City</Form.Label>
                <Form.Control
                  className={styles.myInput}
                  type="text"
                  name="townCity"
                  placeholder="Enter customer town/city"
                  value={profileState.townCity}
                  onChange={handleChange}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId="password">
                <Form.Label>State</Form.Label>
                <Form.Control
                  className={styles.myInput}
                  type="text"
                  name="state"
                  placeholder="Enter customer state"
                  value={profileState.state}
                  onChange={handleChange}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId="password">
                <Form.Label>Zip</Form.Label>
                <Form.Control
                  className={styles.myInput}
                  type="text"
                  name="zipcode"
                  placeholder="Enter your zip"
                  value={profileState.zipcode}
                  onChange={handleChange}
                ></Form.Control>
              </Form.Group>
              <Button
                type="submit"
                variant="btn btn-sm btn-outline-success rounded notInTableButton"
              >
                {loading ? <Loader /> : "Create customer"}
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default OneCustomerDetailsScreen;
