import { takeEvery, put, call } from "redux-saga/effects";
import axios from "axios";
import history from "../../utils/history";
import {
  VALIDATE_RESET_CODE_ATTEMPT,
  validateResetCodeSuccess,
  validateResetCodeError,
} from "../actions/auth";

import { serverURL } from "../../../env";

// eslint-disable-next-line import/no-anonymous-default-export
export default function* () {
  yield takeEvery(VALIDATE_RESET_CODE_ATTEMPT, handleValidate);
}

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else {
    var err = new Error(response.statusText);
    err.response = response;
    throw err;
  }
}

function validateCode(requestURL, options) {
  return axios({
    method: options.method,
    url: requestURL,
    data: options.body,
  }).then(checkStatus);
}

function* handleValidate(payload) {
  const requestURL = `${serverURL}/users/reset_password_code_check/`;
  const options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: payload.data,
  };

  try {
    const response = yield call(validateCode, requestURL, options);

    yield put(validateResetCodeSuccess(response.data));
    history.push("/enterpassword");
  } catch (err) {
    yield put(validateResetCodeError(err?.response?.data?.error));
  }
}
