import React, { useState, useEffect } from "react";
import { Form, Row, Col, Container, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import styles from "./LoginScreen.module.css";
import { useDispatch, useSelector } from "react-redux";
import { emailLoginAttempt } from "../../redux/actions/auth";
import {
  canBeSubmitted,
  showEmailError,
  showPasswordError,
} from "../../utils/validate";
import Loader from "../../components/Loader/Loader";
import ErrorMessage from "../../components/ErrorMessage/ErrorMessage";
import { useNetwork } from "../../utils/checkInternet";

const LoginScreen = () => {
  const isConnected = useNetwork();

  useEffect(() => {
    document.title = "Miraco - Sign in";
    window.scrollTo({
      top: 0,
    });
  }, []);

  const dispatch = useDispatch();
  const { loginAttempt: loading, loginErrorMessage: error } = useSelector(
    (state) => state.auth
  );
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailChanged, setEmailChanged] = useState(false);
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [change, setChange] = useState(false);

  const isDisabled =
    !canBeSubmitted(email, password) || loading || !isConnected;

  const submitHandler = (e) => {
    e.preventDefault();
    if (!isDisabled) {
      setChange(true);
      dispatch(
        emailLoginAttempt({
          username: email,
          password: password,
        })
      );
    }
  };

  return (
    <div className={styles.loginPage}>
      <Container>
        <Row className="justify-content-md-center">
          <Col xs={12} md={6}>
            <Form onSubmit={submitHandler} className={styles.loginForm}>
              <h3 className={styles.signInText}>Sign in to Miraco Euro Food</h3>
              <Form.Group controlId="email">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  className={styles.myInput}
                  type="email"
                  placeholder="Enter email"
                  value={email}
                  onBlur={() => setEmailChanged(true)}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setChange(false);
                  }}
                ></Form.Control>
                {showEmailError(email, emailChanged)}
              </Form.Group>
              <Form.Group controlId="password">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  className={styles.myInput}
                  type="password"
                  placeholder="Enter password"
                  value={password}
                  onBlur={() => setPasswordChanged(true)}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setChange(false);
                  }}
                ></Form.Control>
                {showPasswordError(password, passwordChanged)}
              </Form.Group>
              <div className={styles.commonGoToDiv}>
                <Link className={styles.commonGoTLink} to={"/register"}>
                  Not a customer? Go to Registration
                </Link>
                <Link className={styles.commonGoTLink} to={"/youremail"}>
                  Password forgoten? Click here to recover
                </Link>
              </div>
              {change && error && <ErrorMessage>{error}</ErrorMessage>}
              <Button
                type="submit"
                disabled={isDisabled}
                variant="btn btn-sm btn-outline-success rounded notInTableButton"
              >
                {loading ? <Loader /> : "Sign in"}
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default LoginScreen;
