//rute
import HomeScreen from "../screens/HomeScreen/HomeScreen";
import ProductsScreen from "../screens/ProductsScreen/ProductsScreen";
import CartScreen from "../screens/CartScreen/CartScreen";
import CheckoutScreen from "../screens/CheckoutScreen/CheckoutScreen";
import LoginScreen from "../screens/LoginScreen/LoginScreen";
import RegisterScreen from "../screens/RegisterScreen/RegisterScreen";
import GetResetCodeScreen from "../screens/ResetPasswordAllScreens/GetResetCodeScreen/GetResetCodeScreen";
import ValidateResetCodeScreen from "../screens/ResetPasswordAllScreens/ValidateResetCodeScreen/ValidateResetCodeScreen";
import RequestNewPasswordScreen from "../screens/ResetPasswordAllScreens/RequestNewPasswordScreen/RequestNewPasswordScreen";

export const publicRoutes = [
  {
    title: "Home",
    path: "/",
    component: HomeScreen,
    show: true,
  },
  {
    title: "Products",
    path: "/products",
    component: ProductsScreen,
    show: true,
  },
  {
    title: "CartScreen",
    path: "/cart",
    component: CartScreen,
    show: false,
  },
  {
    title: "CheckoutScreen",
    path: "/checkout",
    component: CheckoutScreen,
    show: false,
  },
  {
    title: "Login",
    path: "/login",
    component: LoginScreen,
    show: false,
  },
  {
    title: "Register",
    path: "/register",
    component: RegisterScreen,
    show: false,
  },
  {
    title: "Your email",
    path: "/youremail",
    component: GetResetCodeScreen,
    show: false,
  },
  {
    title: "Validate code",
    path: "/validateCode",
    component: ValidateResetCodeScreen,
    show: false,
  },
  {
    title: "Enter password",
    path: "/enterpassword",
    component: RequestNewPasswordScreen,
    show: false,
  },
];
