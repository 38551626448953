/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  subtractQuantity,
  addQuantity,
  addCustomQuantity,
} from "../../redux/actions/cart";
import { useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { IoCheckmarkSharp, IoCloseSharp } from "react-icons/io5";
import "./QuantityInput.css";

const QuantityInput = ({ foundedProducts }) => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const [item, setItem] = useState({});
  const [quantity, setQuantity] = useState(foundedProducts?.quantity ?? 1);
  const [customQuantity, setCustomQuantity] = useState(false);

  useEffect(() => {
    setItem(foundedProducts);
    setQuantity(foundedProducts?.quantity);
  }, [foundedProducts?.quantity]);

  useEffect(() => {
    setQuantity(foundedProducts?.quantity);
  }, [!customQuantity]);

  return (
    <>
      {!customQuantity ? (
        <input
          type="button"
          value="-"
          className="minus001"
          onClick={() => {
            dispatch(subtractQuantity(item));
            addToast(`${item?.box_quantity}x ${item?.name} removed from cart`, {
              appearance: "success",
              autoDismiss: true,
              autoDismissTimeout: 2000,
            });
          }}
        />
      ) : (
        <button
          className="minus001 denyQButton"
          onClick={() => {
            setCustomQuantity(false);
          }}
        >
          <IoCloseSharp />
        </button>
      )}
      <input
        type="text"
        name="quantity001"
        value={quantity}
        className="input-text001 qty001 text001"
        disabled
        onChange={(e) => {
          var quantity = e.target.value.replace(/\D/g, "");
          setQuantity(quantity);
        }}
        // onFocus={() => setCustomQuantity(true)}
        // onBlur={(e) => setCustomQuantity(false)}
      />
      {!customQuantity ? (
        <input
          type="button"
          value="+"
          className="plus001"
          onClick={() => {
            dispatch(addQuantity(item));
            addToast(`${item?.box_quantity}x ${item?.name} added to cart`, {
              appearance: "success",
              autoDismiss: true,
              autoDismissTimeout: 2000,
            });
          }}
        />
      ) : (
        <button
          type="submit"
          className="plus001 acceptQButton"
          onClick={() => {
            if (quantity === "0") {
              item.quantity = Number(1);
              dispatch(subtractQuantity(item));
              addToast(
                `${item?.box_quantity}x ${item?.name} removed from cart`,
                {
                  appearance: "success",
                  autoDismiss: true,
                  autoDismissTimeout: 2000,
                }
              );
            } else {
              item.quantity = Number(quantity);
              dispatch(addCustomQuantity(item));
              setCustomQuantity(false);
              addToast(`${item?.box_quantity}x ${item?.name} added to cart`, {
                appearance: "success",
                autoDismiss: true,
                autoDismissTimeout: 2000,
              });
            }
          }}
        >
          <IoCheckmarkSharp />
        </button>
      )}
    </>
  );
};

export default QuantityInput;
