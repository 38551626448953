import React, { useState, useEffect } from "react";
import { Form, Row, Col, Container, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import styles from "./ValidateResetCodeScreen.module.css";
import { useDispatch, useSelector } from "react-redux";
import { validateResetCodeAttempt } from "../../../redux/actions/auth";
import Loader from "../../../components/Loader/Loader";
import ErrorMessage from "../../../components/ErrorMessage/ErrorMessage";

const ValidateResetCodeScreen = () => {
  useEffect(() => {
    document.title = "Miraco - Validate code";
    window.scrollTo({
      top: 0,
    });
  }, []);

  const dispatch = useDispatch();
  const {
    validateResetCodeAttempt: loading,
    validateResetCodeErrorMessage: error,
    email,
  } = useSelector((state) => state.auth);
  const [code, setCode] = useState("");
  const [change, setChange] = useState(false);

  const isDisabled = !code;

  const submitHandler = (e) => {
    e.preventDefault();
    if (!isDisabled) {
      setChange(true);
      dispatch(
        validateResetCodeAttempt({
          email: email,
          code: code,
        })
      );
    }
  };

  return (
    <div className={styles.loginPage}>
      <Container>
        <Row className="justify-content-md-center">
          <Col xs={12} md={6}>
            <Form onSubmit={submitHandler} className={styles.loginForm}>
              <h3 className={styles.signInText}>VALIDATE CODE FROM EMAIL</h3>
              <Form.Group controlId="email">
                <Form.Label>Enter verification code</Form.Label>
                <Form.Control
                  className={styles.myInput}
                  type="text"
                  placeholder="Enter verification code"
                  value={code}
                  onChange={(e) => {
                    setCode(e.target.value);
                    setChange(false);
                  }}
                ></Form.Control>
              </Form.Group>
              <div className={styles.commonGoToDiv}>
                <Link className={styles.commonGoTLink} to={"/login"}>
                  Back to login
                </Link>
              </div>
              {change && error && <ErrorMessage>{error}</ErrorMessage>}
              <Button
                type="submit"
                disabled={isDisabled}
                variant="btn btn-sm btn-outline-success rounded notInTableButton"
              >
                {loading ? <Loader /> : "Validate"}
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ValidateResetCodeScreen;
