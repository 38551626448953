/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import styles from "./ProductsScreen.module.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import { addToCart } from "../../redux/actions/cart";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import QuantityInput from "../../components/QuantityInput/QuantityInput";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import ListGroup from "react-bootstrap/ListGroup";
import {
  getProductsAttempt,
  updateProductFromList,
} from "../../redux/actions/products";
import { filterOfflineProducts } from "../../redux/actions/offlineData";
import { getCategoryAttempt } from "../../redux/actions/categories";
import placeholder from "../../../assets/images/placeholder.png";
import StickyBox from "react-sticky-box";
import { useLocation } from "react-router-dom";
import BurgerMenu from "../../components/BurgerMenu/BurgerMenu";
import Pagination from "../../components/Pagination/Pagination";
import { IoMenu } from "react-icons/io5";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { VscFilePdf } from "react-icons/vsc";
import { serverURL } from "../../../env";
import { useNetwork } from "../../utils/checkInternet";

function ProductsScreen() {
  const isConnected = useNetwork();

  let location = useLocation();
  const [searchQuery, setSearchQuery] = useState("");
  const dispatch = useDispatch();
  const { authToken, form, verified } = useSelector((state) => state.auth);
  const { filteredOfflineProductsList } = useSelector(
    (state) => state.offlineData
  );
  const {
    adminProducts: products,
    next,
    count,
    previous,
  } = useSelector((state) => state.products);

  const offlineProductsData = [...filteredOfflineProductsList].filter(
    (product) => {
      if (searchQuery === "") {
        return product;
      } else {
        return product.name.toLowerCase().includes(searchQuery);
      }
    }
  );

  const dataToRender = isConnected ? products : offlineProductsData;

  // console.log(dataToRender);

  useEffect(() => {
    if (isConnected) {
      if (!authToken) {
        dispatch(getProductsAttempt({ name: "ordering" }));
        dispatch(getCategoryAttempt());
      }
    }
  }, [!authToken, isConnected]);

  useEffect(() => {
    if (isConnected) {
      dispatch(getProductsAttempt({ name: "ordering" }));
      dispatch(getCategoryAttempt());
    }
  }, [isConnected]);

  useEffect(() => {
    document.title = "Miraco - Products";
    window.scrollTo({
      top: 0,
    });
  }, []);

  const { cartProducts } = useSelector((state) => state.cart);
  const { categories } = useSelector((state) => state.categories);
  const [showQuantity, setShowQuantity] = useState(false);
  const [itemId, setItemId] = useState(null);
  const { addToast } = useToasts();
  const sortOptions = authToken
    ? [
        {
          title: "Default",
          name: "ordering",
        },
        { title: "Product name (A-Z)", name: "name" },
        { title: "Product name (Z-A)", name: "-name" },
        { title: "Price - Lowest to Highest", name: "price" },
        { title: "Price - Highest to Lowest", name: "-price" },
      ]
    : [
        {
          title: "Default",
          search: "",
        },
        { title: "Product name (A-Z)", name: "+name" },
        { title: "Product name (Z-A)", name: "-name" },
      ];

  const [activeSort, setActiveSort] = useState(sortOptions[0]);
  const [state, setState] = useState({ All: true });
  const [activePodcategory, setActivePodcategory] = useState("");
  const [activePodcategoryId, setActivePodcategoryId] = useState("");
  const [primaryCategory, setPrimaryCategory] = useState("");
  const [burgerMenuVisible, setBurgerMenuVibisle] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [visibilityAllPageNumbers, setVisibilityAllPageNumbers] =
    useState(false);
  // const [selectedPageToJump, setSelectedPageToJump] = useState("");

  const [catalogueUrl, setCatalogueUrl] = useState(
    `${serverURL}/products/generate/pdf/`
  );

  const handleClick = (item) => {
    setState((prevState) => ({ [item]: !prevState[item] }));
    setBurgerMenuVibisle(false);
    setSearchQuery("");
    setCurrentPage(1);
    setActivePodcategoryId("");
    setPrimaryCategory("");
  };

  useEffect(() => {
    if (isConnected) {
      if (state["All"] === true) {
        setCatalogueUrl(`${serverURL}/products/generate/pdf/`);
      }
    }
  }, [state, isConnected]);

  useEffect(() => {
    if (isConnected) {
      if (location?.categoryFeatured?.name) {
        if (location?.categoryFeatured?.parent_category) {
          handleClick(location?.categoryFeatured?.parent_category?.name);
          setActivePodcategory(location?.categoryFeatured?.name);
          dispatch(
            getProductsAttempt({ categoryId: location?.categoryFeatured?.id })
          );
        } else {
          handleClick(location?.categoryFeatured?.name);
          dispatch(
            getProductsAttempt({ categoryId: location?.categoryFeatured?.id })
          );
        }
      } else {
        dispatch(getProductsAttempt({ name: "ordering" }));
      }
    }
  }, [location?.categoryFeatured?.name, isConnected]);

  const categoriesLayout = () => {
    return categories.map((kategorija, indexKategorije) => {
      const saParentom = categories.filter(
        (dete) => dete.parent_category?.id === kategorija.id
      );

      if (kategorija.parent_category === null) {
        return (
          <React.Fragment key={indexKategorije}>
            {indexKategorije === 0 && (
              <ListGroup.Item
                style={{
                  fontWeight: state["All"] === true ? "bold" : "normal",
                }}
                onClick={() => {
                  if (isConnected) {
                    handleClick("All");
                    setActivePodcategory("");
                    dispatch(getProductsAttempt({ name: "ordering" }));
                    window.scrollTo({
                      top: 0,
                    });
                  } else {
                    dispatch(filterOfflineProducts({ categoryId: "All" }));
                  }
                }}
              >
                All
              </ListGroup.Item>
            )}

            <ListGroup.Item
              style={{
                fontWeight: state[kategorija.name] ? "bold" : "normal",
              }}
              onClick={() => {
                if (isConnected) {
                  handleClick(kategorija.name);
                  setPrimaryCategory(kategorija.id);
                  setCatalogueUrl(
                    `${serverURL}/products/generate/pdf/?category=${kategorija.id}`
                  );
                  setActivePodcategory("");
                  dispatch(getProductsAttempt({ categoryId: kategorija.id }));
                  window.scrollTo({
                    top: 0,
                  });
                } else {
                  dispatch(
                    filterOfflineProducts({ categoryId: kategorija.id })
                  );
                }
              }}
            >
              {kategorija.name}{" "}
              {saParentom.length && !state[kategorija.name]
                ? "↓"
                : saParentom.length && state[kategorija.name]
                ? "↑"
                : null}
            </ListGroup.Item>
            <ListGroup
              variant="flush"
              className="ml-3"
              style={{
                display: state[kategorija.name] ? "block" : "none",
              }}
            >
              {saParentom.map((podkategorija, indexPodkategorije) => {
                return (
                  <ListGroup.Item
                    onClick={() => {
                      if (isConnected) {
                        setActivePodcategory(podkategorija.name);
                        setActivePodcategoryId(podkategorija.id);
                        setBurgerMenuVibisle(false);
                        setCurrentPage(1);
                        setCatalogueUrl(
                          `${serverURL}/products/generate/pdf/?category=${podkategorija.id}`
                        );
                        dispatch(
                          getProductsAttempt({ categoryId: podkategorija.id })
                        );
                        window.scrollTo({
                          top: 0,
                        });
                      }
                    }}
                    key={indexPodkategorije}
                    style={{
                      fontWeight:
                        podkategorija.name === activePodcategory
                          ? "bold"
                          : "normal",
                    }}
                  >
                    {podkategorija.name}
                  </ListGroup.Item>
                );
              })}
            </ListGroup>
          </React.Fragment>
        );
      }
    });
  };

  const renderProducts = () => {
    return dataToRender.map((product) => {
      const foundedProducts = cartProducts.find(
        (cartProduct) => cartProduct.id === product.id
      );

      const renderCardFooter = () => {
        if (form === "Admin") {
          if (
            (showQuantity &&
              itemId === product.id &&
              foundedProducts?.id === product.id) ||
            foundedProducts?.id === product.id
          ) {
            return (
              <>
                <Link
                  to={{
                    pathname: `/admin/product/${product.id}`,
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button variant="btn btn-sm btn-outline-success rounded mt-4 d-block mx-auto">
                    Edit product
                  </Button>
                </Link>
                <div className="quantity001 buttons_added001 mt-3">
                  <QuantityInput foundedProducts={foundedProducts} />
                </div>
              </>
            );
          }
          return (
            <>
              <Link
                to={{
                  pathname: `/admin/product/${product.id}`,
                }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button variant="btn btn-sm btn-outline-success rounded mt-4 d-block mx-auto">
                  Edit product
                </Button>
              </Link>
              <Button
                onClick={() => {
                  setShowQuantity(true);
                  dispatch(addToCart(product));
                  setItemId(product.id);
                  addToast(
                    `${product?.box_quantity}x ${product?.name} added to cart`,
                    {
                      appearance: "success",
                      autoDismiss: true,
                      autoDismissTimeout: 2000,
                    }
                  );
                }}
                variant="btn btn-success rounded w-100 mt-3"
              >
                Add to cart
              </Button>
            </>
          );
        } else if (form === "Customer" && verified && product?.stock) {
          if (
            (showQuantity &&
              itemId === product.id &&
              foundedProducts?.id === product.id) ||
            foundedProducts?.id === product.id
          ) {
            return (
              <div className="quantity001 buttons_added001">
                <QuantityInput foundedProducts={foundedProducts} />
              </div>
            );
          } else {
            return (
              <Button
                onClick={() => {
                  setShowQuantity(true);
                  dispatch(addToCart(product));
                  setItemId(product.id);
                  addToast(
                    `${product?.box_quantity}x ${product?.name} added to cart`,
                    {
                      appearance: "success",
                      autoDismiss: true,
                      autoDismissTimeout: 2000,
                    }
                  );
                }}
                variant="btn btn-outline-success rounded w-100 notInTableButton"
              >
                Add to cart
              </Button>
            );
          }
        } else if (form === "Customer" && verified && !product?.stock) {
          return <h5 className="text-center">Temporary out of stock</h5>;
        } else if (form === "Customer" && !verified) {
          return <h5 className="text-center">Waiting profile verification</h5>;
        }
      };

      return (
        <Col
          className={styles.productGridColumn}
          md={6}
          sm={12}
          lg={4}
          xl={3}
          key={product.id}
        >
          <Card className={styles.productCard}>
            <Card.Body className={styles.productCardBody}>
              {product?.special > 0 ? (
                <span className={styles.discoundLabel}>
                  Discount {product?.special}%
                </span>
              ) : null}
              <LazyLoadImage
                className="img-fluid rounded"
                effect="blur"
                alt={product.image}
                src={isConnected ? product.image : placeholder}
                width={214}
                height={214}
                // eslint-disable-next-line jsx-a11y/alt-text
                placeholder={<img alt="placeholder" src={placeholder} />}
                placeholderSrc={placeholder}
                visibleByDefault={product.image === placeholder}
              />
              {/* <img
                src={product.image ? product.image : placeholder}
                className="img-fluid rounded"
                alt="product"
              /> */}
              <Card.Title className={styles.productTitle}>
                {product?.name}
              </Card.Title>
              <Card.Text className={styles.productPrice}>
                {authToken && verified ? (
                  <>
                    {"CAD "}
                    {(
                      product?.price -
                      product?.price * (product.special / 100)
                    ).toFixed(2)}{" "}
                  </>
                ) : null}
              </Card.Text>
              <Card.Text className="text-right">
                Pieces per case: {product?.box_quantity}
              </Card.Text>
            </Card.Body>
            <Card.Footer className={styles.productCardFooter}>
              {renderCardFooter()}
            </Card.Footer>
          </Card>
        </Col>
      );
    });
  };

  const paginate = (pageNum) => {
    window.scrollTo({
      top: 0,
    });
    setCurrentPage(pageNum);
    if (searchQuery) {
      dispatch(
        getProductsAttempt({
          search: searchQuery,
          page: pageNum,
          categoryId: location?.categoryFeatured?.id
            ? location?.categoryFeatured?.id
            : activePodcategoryId
            ? activePodcategoryId
            : primaryCategory,
        })
      );
    } else {
      dispatch(
        getProductsAttempt({
          page: pageNum,
          categoryId: location?.categoryFeatured?.id
            ? location?.categoryFeatured?.id
            : activePodcategoryId
            ? activePodcategoryId
            : primaryCategory,
        })
      );
    }
  };

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
    dispatch(getProductsAttempt({ payloadUrl: next }));
    window.scrollTo({
      top: 0,
    });
  };

  const prevPage = () => {
    setCurrentPage(currentPage - 1);
    dispatch(getProductsAttempt({ payloadUrl: previous }));
    window.scrollTo({
      top: 0,
    });
  };

  //svi brojevi vidljivi
  const changeVisibilityAllPageNumbers = (visibility) => {
    setVisibilityAllPageNumbers(visibility);
  };

  // const setJumpedPage = (pageObj) => setSelectedPageToJump(pageObj);

  useEffect(() => {
    function checkProduct() {
      const product_id = localStorage.getItem("product_id");
      const product_price = localStorage.getItem("product_price");
      const product_name = localStorage.getItem("product_name");
      const product_box_quantity = localStorage.getItem("product_box_quantity");
      const product_quantity = localStorage.getItem("product_quantity");
      const product_image = localStorage.getItem("product_image");
      const is_deleted = localStorage.getItem("is_deleted");

      if (product_id && isConnected) {
        dispatch(
          updateProductFromList({
            product_id,
            product_name,
            product_image,
            product_box_quantity,
            product_quantity,
            product_price,
            is_deleted,
          })
        );
        localStorage.removeItem("product_id");
        localStorage.removeItem("product_price");
        localStorage.removeItem("product_name");
        localStorage.removeItem("product_box_quantity");
        localStorage.removeItem("product_quantity");
        localStorage.removeItem("product_image");
        localStorage.removeItem("is_deleted");
      }
    }

    window.addEventListener("storage", checkProduct);

    return () => {
      window.removeEventListener("storage", checkProduct);
    };
  }, [isConnected]);

  return (
    <div className={styles.productScreen}>
      <BurgerMenu
        burgerMenuVisible={burgerMenuVisible}
        setBurgerMenuVibisle={setBurgerMenuVibisle}
      >
        <StickyBox>
          {isConnected && form === "Admin" ? (
            <a
              className={styles.pdfButton}
              href={catalogueUrl}
              download
              target="_blank"
              rel="noreferrer"
            >
              <VscFilePdf className={styles.pdfIcon} />
              Download PDF
            </a>
          ) : null}
          <h6 className={styles.commonProductsFilterHeading}>
            Search products
          </h6>
          <div className="d-flex">
            <Form.Control
              className="rounded mySearchInput"
              type="text"
              placeholder="Search by name"
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value.toLowerCase());
                if (isConnected) {
                  if (e.target.value.length === 0) {
                    dispatch(getProductsAttempt({ name: "ordering" }));
                  }
                }
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  if (isConnected) {
                    dispatch(getProductsAttempt({ search: searchQuery }));
                    setCurrentPage(1);
                    setCatalogueUrl(
                      `${serverURL}/products/generate/pdf/?search=${searchQuery}`
                    );
                  }
                }
              }}
            ></Form.Control>
            <button
              className="confirmSearch"
              type="submit"
              disabled={!searchQuery}
              onClick={() => {
                dispatch(getProductsAttempt({ search: searchQuery }));
                setCatalogueUrl(
                  `${serverURL}/products/generate/pdf/?search=${searchQuery}`
                );
                setCurrentPage(1);
                setBurgerMenuVibisle(false);
              }}
            >
              Search
            </button>
          </div>

          <div className={styles.categoriesDiv}>
            <h6 className={styles.commonProductsFilterHeading}>
              Product categories
            </h6>
            <ListGroup variant="flush">{categoriesLayout()}</ListGroup>
          </div>
        </StickyBox>
      </BurgerMenu>
      <Container fluid className="d-none d-lg-block">
        <Row>
          <Col md={2} sm={12}>
            <StickyBox>
              {isConnected && form === "Admin" ? (
                <a
                  className={styles.pdfButton}
                  href={catalogueUrl}
                  download
                  target="_blank"
                  rel="noreferrer"
                >
                  <VscFilePdf className={styles.pdfIcon} />
                  Download PDF
                </a>
              ) : null}
              <h6 className={styles.commonProductsFilterHeading}>
                Search products
              </h6>
              <div className="d-flex">
                <Form.Control
                  className="rounded mySearchInput"
                  type="text"
                  placeholder="Search by name"
                  value={searchQuery}
                  onChange={(e) => {
                    setSearchQuery(e.target.value.toLowerCase());
                    if (isConnected) {
                      if (e.target.value.length === 0) {
                        dispatch(getProductsAttempt({ name: "ordering" }));
                      }
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      if (isConnected) {
                        dispatch(getProductsAttempt({ search: searchQuery }));
                        setCurrentPage(1);
                        setCatalogueUrl(
                          `${serverURL}/products/generate/pdf/?search=${searchQuery}`
                        );
                      }
                    }
                  }}
                ></Form.Control>
                <button
                  className="confirmSearch"
                  type="submit"
                  disabled={!searchQuery}
                  onClick={() => {
                    dispatch(getProductsAttempt({ search: searchQuery }));
                    setCatalogueUrl(
                      `${serverURL}/products/generate/pdf/?search=${searchQuery}`
                    );
                    setBurgerMenuVibisle(false);
                    setCurrentPage(1);
                  }}
                >
                  Search
                </button>
              </div>

              <div className={styles.categoriesDiv}>
                <h6 className={styles.commonProductsFilterHeading}>
                  Product categories
                </h6>
                <ListGroup variant="flush">{categoriesLayout()}</ListGroup>
              </div>
            </StickyBox>
          </Col>
          <Col md={10} sm={12}>
            <Container>
              {isConnected ? (
                <div className={styles.sortByDiv}>
                  <h6 className={styles.sortByHeading}>Sort by: </h6>
                  <DropdownButton
                    id="dropdown-item-button-order-products-screen"
                    title={activeSort?.title}
                    size={"sm"}
                  >
                    {sortOptions.map((option, index) => {
                      let isActiveSort =
                        option?.title === activeSort?.title ? true : false;
                      return (
                        <Dropdown.Item
                          className={
                            isActiveSort
                              ? styles.activeSort
                              : styles.notActiveSort
                          }
                          key={index}
                          onClick={() => {
                            setActiveSort(option);
                            if (option.title === "Default") {
                              dispatch(
                                getProductsAttempt({
                                  name: "ordering",
                                  categoryId: activePodcategoryId
                                    ? activePodcategoryId
                                    : primaryCategory
                                    ? primaryCategory
                                    : false,
                                })
                              );
                            } else {
                              dispatch(
                                getProductsAttempt({
                                  sort: option.name,
                                  categoryId: activePodcategoryId
                                    ? activePodcategoryId
                                    : primaryCategory
                                    ? primaryCategory
                                    : false,
                                })
                              );
                            }
                          }}
                        >
                          {option.title}
                        </Dropdown.Item>
                      );
                    })}
                  </DropdownButton>
                </div>
              ) : null}

              <h6 className={styles.foundedProductsHeading}>
                {isConnected ? (
                  <span className={styles.foundedProductsHeadingSpan}>
                    Showing {products?.length} / {count}
                    {products?.length > 1 ? " products" : " product"}
                  </span>
                ) : (
                  <span className={styles.foundedProductsHeadingSpan}>
                    Showing all {offlineProductsData?.length} products
                  </span>
                )}
              </h6>
              <Row className={styles.productGridRow}>
                {renderProducts()}
                <Col sm={12}>
                  <div className={styles.paginationWrapper}>
                    <Pagination
                      postsPerPage={16}
                      totalPosts={count}
                      paginate={paginate}
                      nextPage={nextPage}
                      prevPage={prevPage}
                      totalPageNumber={Math.ceil(count / 16)}
                      currentPage={currentPage}
                      visibilityAllPageNumbers={visibilityAllPageNumbers}
                      changeVisibilityAllPageNumbers={
                        changeVisibilityAllPageNumbers
                      }
                      // setSelectedPageToJump={setJumpedPage}
                      // selectedPageToJump={selectedPageToJump}
                    />
                  </div>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
      <Container fluid className="d-sm-block d-lg-none">
        <Row>
          <Col>
            <Container>
              <h5
                style={{
                  display: "flex",
                  alignSelf: "center",
                  justifyContent: "center",
                  marginBottom: "30px",
                  fontSize: "15px",
                  cursor: "pointer",
                }}
                onClick={() => setBurgerMenuVibisle(!burgerMenuVisible)}
              >
                <IoMenu
                  style={{
                    marginRight: "10px",
                    fontSize: "18px",
                  }}
                />
                Show filters
              </h5>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "10px",
                  marginBottom: "30px",
                }}
              >
                <h6 className={styles.sortByHeading}>Sort by: </h6>
                <DropdownButton
                  id="dropdown-item-button-order-products-screen"
                  title={activeSort?.title}
                  size={"sm"}
                >
                  {sortOptions.map((option, index) => {
                    let isActiveSort =
                      option?.title === activeSort?.title ? true : false;
                    return (
                      <Dropdown.Item
                        className={
                          isActiveSort
                            ? styles.activeSort
                            : styles.notActiveSort
                        }
                        key={index}
                        onClick={() => {
                          setActiveSort(option);
                          if (option.title === "Default") {
                            dispatch(getProductsAttempt({ name: "ordering" }));
                          } else {
                            dispatch(getProductsAttempt({ sort: option.name }));
                          }
                        }}
                      >
                        {option.title}
                      </Dropdown.Item>
                    );
                  })}
                </DropdownButton>
              </div>

              <h6 className={styles.foundedProductsHeading}>
                <span className={styles.foundedProductsHeadingSpan}>
                  Showing {products?.length} / {count}
                  {products?.length > 1 ? " products" : " product"}
                </span>
              </h6>
              <Row className={styles.productGridRow}>
                {renderProducts()}
                <div className={styles.paginationWrapper}>
                  <Pagination
                    postsPerPage={16}
                    totalPosts={count}
                    paginate={paginate}
                    nextPage={nextPage}
                    prevPage={prevPage}
                    totalPageNumber={Math.ceil(count / 16)}
                    currentPage={currentPage}
                    visibilityAllPageNumbers={visibilityAllPageNumbers}
                    changeVisibilityAllPageNumbers={
                      changeVisibilityAllPageNumbers
                    }
                    // setSelectedPageToJump={setJumpedPage}
                    // selectedPageToJump={selectedPageToJump}
                  />
                </div>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ProductsScreen;
