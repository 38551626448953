import React, { useState, useEffect } from "react";
import { Form, Row, Col, Container, Button } from "react-bootstrap";
import styles from "./RegisterScreen.module.css";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { emailRegisterAttempt } from "../../redux/actions/auth";
import {
  canBeSubmitted,
  showEmailError,
  showPasswordError,
} from "../../utils/validate";
import Loader from "../../components/Loader/Loader";
import ErrorMessage from "../../components/ErrorMessage/ErrorMessage";
import { useNetwork } from "../../utils/checkInternet";

const LoginScreen = () => {
  const isConnected = useNetwork();

  useEffect(() => {
    document.title = "Miraco - Sign up";
  }, []);

  const dispatch = useDispatch();
  const { registerAttempt: loading, registerErrorMessage: error } = useSelector(
    (state) => state.auth
  );
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailChanged, setEmailChanged] = useState(false);
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [change, setChange] = useState(false);

  const [profileState, setProfileState] = useState({
    phone_number: "",
    first_name: "",
    last_name: "",
    country: "",
    address: "",
    townCity: "",
    state: "",
    zipcode: "",
    company: "",
  });

  const isDisabled =
    !canBeSubmitted(email, password) ||
    loading ||
    !isConnected ||
    !profileState.phone_number ||
    !profileState.first_name ||
    !profileState.last_name ||
    !profileState.country ||
    !profileState.address ||
    !profileState.townCity ||
    !profileState.state ||
    !profileState.zipcode;

  const handleChange = (e) => {
    setProfileState({
      ...profileState,
      [e.target.name]: e.target.value,
    });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (!isDisabled) {
      setChange(true);
      dispatch(
        emailRegisterAttempt({
          data_to_update: profileState,
          data_to_server: {
            email: email,
            username: email,
            password1: password,
            password2: password,
            form: "Customer",
          },
        })
      );
    }
  };

  return (
    <div className={styles.loginPage}>
      <Container>
        <Row className="justify-content-md-center">
          <Col xs={12} md={6}>
            <Form onSubmit={submitHandler} className={styles.loginForm}>
              <h3 className={styles.signInText}>Sign up to Miraco Euro Food</h3>
              <Form.Group controlId="company">
                <Form.Label>Company name</Form.Label>
                <Form.Control
                  className={styles.myInput}
                  type="text"
                  name="company"
                  placeholder="Enter company name (optional)"
                  value={profileState.company}
                  onChange={handleChange}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId="email">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  className={styles.myInput}
                  type="email"
                  placeholder="Enter email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setChange(false);
                  }}
                  onBlur={() => setEmailChanged(true)}
                ></Form.Control>
                {showEmailError(email, emailChanged)}
              </Form.Group>
              <Form.Group controlId="password">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  className={styles.myInput}
                  placeholder="Enter password"
                  type={"password"}
                  value={password}
                  onBlur={() => setPasswordChanged(true)}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setChange(false);
                  }}
                />
                {showPasswordError(password, passwordChanged)}
              </Form.Group>
              <Form.Group controlId="phone">
                <Form.Label>Phone number</Form.Label>
                <Form.Control
                  className={styles.myInput}
                  type="text"
                  name="phone_number"
                  placeholder="Enter phone number"
                  value={profileState.phone_number}
                  onChange={handleChange}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId="fname">
                <Form.Label>First name</Form.Label>
                <Form.Control
                  className={styles.myInput}
                  type="text"
                  name="first_name"
                  placeholder="Enter first name"
                  value={profileState.first_name}
                  onChange={handleChange}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId="lname">
                <Form.Label>Last name</Form.Label>
                <Form.Control
                  className={styles.myInput}
                  type="text"
                  name="last_name"
                  placeholder="Enter last name"
                  value={profileState.last_name}
                  onChange={handleChange}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId="country">
                <Form.Label>Your country</Form.Label>
                <Form.Control
                  className={styles.myInput}
                  type="text"
                  name="country"
                  placeholder="Enter country"
                  value={profileState.country}
                  onChange={handleChange}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId="address">
                <Form.Label>Address</Form.Label>
                <Form.Control
                  className={styles.myInput}
                  type="text"
                  name="address"
                  placeholder="Enter your address"
                  value={profileState.address}
                  onChange={handleChange}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId="town">
                <Form.Label>Town/City</Form.Label>
                <Form.Control
                  className={styles.myInput}
                  type="text"
                  name="townCity"
                  placeholder="Enter your town/city"
                  value={profileState.townCity}
                  onChange={handleChange}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId="state">
                <Form.Label>State</Form.Label>
                <Form.Control
                  className={styles.myInput}
                  type="text"
                  name="state"
                  placeholder="Enter your state"
                  value={profileState.state}
                  onChange={handleChange}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId="zip">
                <Form.Label>Zip</Form.Label>
                <Form.Control
                  className={styles.myInput}
                  type="text"
                  name="zipcode"
                  placeholder="Enter your zip"
                  value={profileState.zipcode}
                  onChange={handleChange}
                ></Form.Control>
              </Form.Group>
              <div className={styles.commonGoToDiv}>
                <Link className={styles.commonGoTLink} to={"/login"}>
                  Already a customer? Go to Login
                </Link>
              </div>
              {change && error && <ErrorMessage>{error}</ErrorMessage>}
              <Button
                type="submit"
                disabled={isDisabled}
                variant="btn btn-sm btn-outline-success rounded notInTableButton"
              >
                {loading ? <Loader /> : "Sign up"}
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default LoginScreen;
