import React from "react";
import styles from "./NotVerifiedScreen.module.css";
import { Link } from "react-router-dom";

const NotVerifiedScreen = () => {
  return (
    <div className={styles.notVerifiedScreen}>
      <div className={styles.noProductsMessageDiv}>
        <h4 className={styles.noItemsInCartText}>Verification in progress.</h4>
        <h5 className={styles.noItemsInCartText}>
          You'll be notified on your email address once
          <br />
          we confirm your account
        </h5>
        <Link
          className="btn btn-outline-success mt-2 rounded notInTableButton"
          to="/"
        >
          OK
        </Link>
      </div>
    </div>
  );
};

export default NotVerifiedScreen;
