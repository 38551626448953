/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import styles from "./EditCategoryScreen.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  editCategoryAttempt,
  resetEditCategory,
  setActiveCategoryTab,
} from "../../../redux/actions/categories";
import { useToasts } from "react-toast-notifications";
import { useParams } from "react-router-dom";
import history from "../../../utils/history";
import { Form, Row, Col, Container, Button } from "react-bootstrap";
import { SiteMap } from "../../../components/BreadCrumbs/BreadCrumbs";
import { createSlug } from "../../../utils/createSlug";
import Select from "react-select";

const EditCategoryScreen = () => {
  const { id } = useParams();
  const navLinks = [
    { href: "/admin/categories", name: "Categories" },
    { href: `/admin/category/${id}`, name: "Edit category" },
  ];
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { editCategorySuccess, categories } = useSelector(
    (state) => state.categories
  );
  const [parentCategory, setParentCategory] = useState("");
  const [categoryName, setCategoryName] = useState("");

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
    const foundedCategory = categories.find(
      (category) => category?.id === Number(id)
    );

    setCategoryName(foundedCategory?.name);
    if (foundedCategory?.parent_category !== null) {
      setParentCategory({
        label: foundedCategory?.parent_category?.name,
        value: foundedCategory?.parent_category?.id,
      });
    }
  }, []);

  useEffect(() => {
    if (editCategorySuccess) {
      addToast(`Category edited succesfully`, {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 800,
        onDismiss: () => {
          dispatch(resetEditCategory());
          history.goBack();
        },
      });
    }
  }, [editCategorySuccess]);

  const editCategoryHandler = (e) => {
    e.preventDefault();
    if (parentCategory === "") {
      dispatch(
        editCategoryAttempt({
          data_to_server: {
            name: categoryName,
            slug: createSlug(categoryName),
          },
          id: id,
        })
      );
    } else {
      dispatch(
        editCategoryAttempt({
          data_to_server: {
            name: categoryName,
            slug: createSlug(categoryName),
            parent_category: parentCategory?.value,
          },
          id: id,
        })
      );
    }
  };

  const groupProducts = () => {
    return categories
      .filter((category) => category.id !== Number(id))
      .map((kategorija) => {
        const saParentom = categories.filter(
          (dete) => dete.parent_category?.id === kategorija.id
        );

        if (kategorija.parent_category === null) {
          return {
            value: kategorija.id,
            label: kategorija.name,
            children: saParentom.map((podkategorija) => {
              return {
                value: podkategorija.id,
                label: podkategorija.name,
              };
            }),
          };
        }
      })
      .filter(function (el) {
        return el != null;
      });
  };

  const renderSelect = () => {
    const style = {
      control: (base) => ({
        ...base,
        border: 0,
        // This line disable the blue border
        boxShadow: "none",
      }),
    };

    let categories = groupProducts();

    const handleCategoryChange = (selectedOption) => {
      setParentCategory(selectedOption);
    };

    return (
      <>
        <Form.Label>Product main category</Form.Label>
        <Select
          value={parentCategory}
          onChange={handleCategoryChange}
          options={categories}
          placeholder="None - click here if your category have main category"
          className="abc"
          classNamePrefix="react-select"
          isClearable
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary25: "rgba(27, 154, 62, 0.2)",
              primary: "rgba(27, 154, 62, 0.4)",
              primary50: "#DEEBF4",
            },
          })}
          styles={style}
        />
      </>
    );
  };

  return (
    <div className={styles.editCategoryScreen}>
      <Container>
        {/* <h3 className={styles.addCategoryHeading}>Add new category</h3> */}
        <SiteMap hrefIn={`/admin/category/${id}`} items={navLinks} />
        <Row className="justify-content-md-center">
          <Col xs={12} md={6}>
            <Form onSubmit={editCategoryHandler}>
              <Form.Group controlId="desc">
                <Form.Label>Category name</Form.Label>
                <Form.Control
                  className={styles.myInput}
                  type="text"
                  placeholder="Enter category name"
                  value={categoryName}
                  onChange={(e) => {
                    setCategoryName(e.target.value);
                  }}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId="productCategory">
                {renderSelect()}
              </Form.Group>
              <div className="d-flex">
                <Button
                  variant="btn btn-outline-success rounded notInTableButton mr-2"
                  type="submit"
                  disabled={false}
                >
                  Confirm changes
                </Button>
                <Button
                  variant="btn btn-outline-danger notInTableButton rounded"
                  onClick={() => {
                    dispatch(setActiveCategoryTab("trash"));
                    dispatch(
                      editCategoryAttempt({
                        data_to_server: {
                          is_deleted: true,
                        },
                        id: id,
                      })
                    );
                  }}
                >
                  Move to trash
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default EditCategoryScreen;
