/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import styles from "./EditVendorScreen.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  editVendorAttempt,
  resetEditVendor,
} from "../../../redux/actions/vendors";
import { useToasts } from "react-toast-notifications";
import { Form, Row, Col, Container, Button } from "react-bootstrap";
import { SiteMap } from "../../../components/BreadCrumbs/BreadCrumbs";
import { useParams } from "react-router-dom";
import history from "../../../utils/history";

const AddProductScreen = () => {
  const navLinks = [
    { href: "/admin/vendors", name: "Vendors" },
    { href: `/admin/edit-vendor`, name: "Edit vendor" },
  ];
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { editVendorSuccess, vendors } = useSelector((state) => state.vendors);

  const { id } = useParams();

  const [vendorName, setVendorName] = useState("");
  const [primaryContact, setPrimaryContact] = useState("");
  const [mainPhone, setMainPhone] = useState("");
  const [vendorEmail, setVendorEmail] = useState("");
  const [vendorFax, setVendorFax] = useState("");

  useEffect(() => {
    document.title = "Miraco - Edit Vendor";
    window.scrollTo({
      top: 0,
    });
    const foundedVendor = vendors.find((vendor) => vendor?.id === Number(id));
    setVendorName(foundedVendor?.name);
    setPrimaryContact(foundedVendor?.primary_contact);
    setMainPhone(foundedVendor?.main_phone);
    setVendorEmail(foundedVendor?.email);
    setVendorFax(foundedVendor?.fax);
  }, [id]);

  useEffect(() => {
    if (editVendorSuccess) {
      addToast(`Vendor edited succesfully`, {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 2000,
        onDismiss: () => {
          dispatch(resetEditVendor());
          history.goBack();
        },
      });
    }
  }, [editVendorSuccess]);

  const addCategoryHandler = (e) => {
    e.preventDefault();
    dispatch(
      editVendorAttempt({
        id: id,
        data_to_server: {
          name: vendorName,
          primary_contact: primaryContact,
          main_phone: mainPhone,
          email: vendorEmail,
          fax: vendorFax,
        },
      })
    );
  };

  return (
    <div className={styles.addCategoryScreen}>
      <Container>
        <SiteMap hrefIn={`/admin/edit-vendor`} items={navLinks} />
        <Row className="justify-content-md-center">
          <Col xs={12} md={6}>
            <Form onSubmit={addCategoryHandler}>
              <Form.Group controlId="desc">
                <Form.Label>Vendor name</Form.Label>
                <Form.Control
                  className={styles.myInput}
                  type="text"
                  placeholder="Enter vendor name"
                  value={vendorName}
                  onChange={(e) => {
                    setVendorName(e.target.value);
                  }}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId="desc">
                <Form.Label>Vendor email</Form.Label>
                <Form.Control
                  className={styles.myInput}
                  type="text"
                  placeholder="Enter vendor email"
                  value={vendorEmail}
                  onChange={(e) => {
                    setVendorEmail(e.target.value);
                  }}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId="desc">
                <Form.Label>Main phone</Form.Label>
                <Form.Control
                  className={styles.myInput}
                  type="text"
                  placeholder="Enter main phone"
                  value={mainPhone}
                  onChange={(e) => {
                    setMainPhone(e.target.value);
                  }}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId="desc">
                <Form.Label>Primary contact</Form.Label>
                <Form.Control
                  className={styles.myInput}
                  type="text"
                  placeholder="Enter primary contact"
                  value={primaryContact}
                  onChange={(e) => {
                    setPrimaryContact(e.target.value);
                  }}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId="desc">
                <Form.Label>Fax</Form.Label>
                <Form.Control
                  className={styles.myInput}
                  type="text"
                  placeholder="Enter fax number"
                  value={vendorFax}
                  onChange={(e) => {
                    setVendorFax(e.target.value);
                  }}
                ></Form.Control>
              </Form.Group>
              <Button
                variant="btn btn-outline-success rounded notInTableButton"
                type="submit"
                disabled={!vendorName}
              >
                Confirm changes
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AddProductScreen;
