import { EMAIL_LOGOUT_SUCCESS } from "../actions/auth";
import {
  GET_PROFILE_ATTEMPT,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_ERROR,
  UPDATE_PROFILE_ATTEMPT,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_ERROR,
  RESET_UPDATE_PROFILE,
  UPDATE_PROFILE_AFTER_REGISTRATION_ATTEMPT,
  UPDATE_PROFILE_AFTER_REGISTRATION_SUCCESS,
  UPDATE_PROFILE_AFTER_REGISTRATION_ERROR,
} from "../actions/profile";

const defaultState = {
  getProfileAttempt: false,
  getProfileSuccess: false,
  getProfileError: false,
  getProfileErrorMessage: "",

  updateProfileAttempt: false,
  updateProfileSuccess: false,
  updateProfileError: false,
  updateProfileErrorMessage: "",

  updateProfileAfterRegistraitonAttempt: false,
  updateProfileAfterRegistraitonSuccess: false,
  updateProfileAfterRegistraitonError: false,
  updateProfileAfterRegistraitonErrorMessage: "",

  email: "",
  username: "",
  phone_number: "",
  first_name: "",
  last_name: "",
  country: "",
  address: "",
  townCity: "",
  state: "",
  zipcode: "",
  verified: false,
};

export const profileReducer = (state = defaultState, action) => {
  switch (action.type) {
    case GET_PROFILE_ATTEMPT:
      return {
        ...state,
        getProfileAttempt: true,
        getProfileSuccess: false,
        getProfileError: false,
        getProfileErrorMessage: "",
      };
    case GET_PROFILE_SUCCESS:
      return {
        ...state,
        getProfileAttempt: false,
        getProfileSuccess: true,
        getProfileError: false,
        getProfileErrorMessage: "",
        email: action.data.email,
        username: action.data.username,
        phone_number: action.data.phone_number,
        first_name: action.data.first_name,
        last_name: action.data.last_name,
        country: action.data.country,
        address: action.data.address,
        townCity: action.data.townCity,
        state: action.data.state,
        zipcode: action.data.zipcode,
        company: action.data.company,
        verified: action.data.verified,
      };
    case GET_PROFILE_ERROR:
      return {
        ...state,
        getProfileAttempt: false,
        getProfileSuccess: true,
        getProfileError: true,
        getProfileErrorMessage: action.data,
      };

    case UPDATE_PROFILE_ATTEMPT:
      return {
        ...state,
        updateProfileAttempt: true,
        updateProfileSuccess: false,
        updateProfileError: false,
        updateProfileErrorMessage: "",
      };
    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        updateProfileAttempt: false,
        updateProfileSuccess: true,
        updateProfileError: false,
        updateProfileErrorMessage: "",
        email: action.data.email,
        username: action.data.username,
        phone_number: action.data.phone_number,
        first_name: action.data.first_name,
        last_name: action.data.last_name,
        country: action.data.country,
        address: action.data.address,
        townCity: action.data.townCity,
        state: action.data.state,
        zipcode: action.data.zipcode,
        company: action.data.company,
      };
    case UPDATE_PROFILE_ERROR:
      return {
        ...state,
        updateProfileAttempt: false,
        updateProfileSuccess: false,
        updateProfileError: true,
        updateProfileErrorMessage: action.data,
      };

    case RESET_UPDATE_PROFILE:
      return {
        ...state,
        updateProfileAttempt: false,
        updateProfileSuccess: false,
        updateProfileError: false,
        updateProfileErrorMessage: "",
      };

    case UPDATE_PROFILE_AFTER_REGISTRATION_ATTEMPT:
      return {
        ...state,
        updateProfileAfterRegistraitonAttempt: true,
        updateProfileAfterRegistraitonSuccess: false,
        updateProfileAfterRegistraitonError: false,
        updateProfileAfterRegistraitonErrorMessage: "",
      };
    case UPDATE_PROFILE_AFTER_REGISTRATION_SUCCESS:
      return {
        ...state,
        updateProfileAfterRegistraitonAttempt: false,
        updateProfileAfterRegistraitonSuccess: true,
        updateProfileAfterRegistraitonError: false,
        updateProfileAfterRegistraitonErrorMessage: "",
        email: action.data.email,
        username: action.data.username,
        phone_number: action.data.phone_number,
        first_name: action.data.first_name,
        last_name: action.data.last_name,
        country: action.data.country,
        address: action.data.address,
        townCity: action.data.townCity,
        state: action.data.state,
        zipcode: action.data.zipcode,
        company: action.data.company,
      };
    case UPDATE_PROFILE_AFTER_REGISTRATION_ERROR:
      return {
        ...state,
        updateProfileAfterRegistraitonAttempt: false,
        updateProfileAfterRegistraitonSuccess: false,
        updateProfileAfterRegistraitonError: true,
        updateProfileAfterRegistraitonErrorMessage: action.data,
      };

    case EMAIL_LOGOUT_SUCCESS:
      return defaultState;

    default:
      return state;
  }
};
