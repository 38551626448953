import { takeEvery, put, call, select } from "redux-saga/effects";
import axios from "axios";
import history from "../../utils/history";
import {
  EMAIL_LOGIN_ATTEMPT,
  emailLoginSuccess,
  emailLoginError,
} from "../actions/auth";
import { getProfileAttempt } from "../actions/profile";

import { serverURL } from "../../../env";

// eslint-disable-next-line import/no-anonymous-default-export
export default function* () {
  yield takeEvery(EMAIL_LOGIN_ATTEMPT, handleLogin);
}

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else {
    var err = new Error(response.statusText);
    err.response = response;
    throw err;
  }
}

function Login(requestURL, options) {
  return axios({
    method: options.method,
    url: requestURL,
    data: options.body,
  }).then(checkStatus);
}

function* handleLogin(payload) {
  const redirectToRegistration = yield select(
    ({ orders }) => orders.redirectToRegistration
  );
  const requestURL = `${serverURL}/rest-auth/login/`;
  const options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: payload.data,
  };

  try {
    const response = yield call(Login, requestURL, options);

    yield put(emailLoginSuccess(response.data));
    yield put(getProfileAttempt({ customerId: response.data.user }));
    if (redirectToRegistration) {
      history.push("/checkout");
    } else if (response.data.form === "Admin") {
      history.push("/admin/products");
    } else if (response.data.form === "Customer") {
      if (response.data.verified) {
        history.push("/products");
      } else {
        history.push("/waiting-verification");
      }
    }
  } catch (err) {
    yield put(emailLoginError(err?.response?.data?.non_field_errors[0]));
  }
}
