/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  subtractQuantity,
  addQuantity,
  addCustomQuantity,
} from "../../redux/actions/cart";
import { useDispatch } from "react-redux";
import { IoCheckmarkSharp, IoCloseSharp } from "react-icons/io5";

const Input = ({ foundedProducts }) => {
  const dispatch = useDispatch();
  const [item, setItem] = useState({});
  const [quantity, setQuantity] = useState(foundedProducts?.quantity ?? 1);
  const [customQuantity, setCustomQuantity] = useState(false);

  useEffect(() => {
    setItem(foundedProducts);
    setQuantity(foundedProducts?.quantity);
  }, [foundedProducts?.quantity]);

  useEffect(() => {
    setQuantity(foundedProducts?.quantity);
  }, [!customQuantity]);

  return (
    <>
      {!customQuantity ? (
        <input
          type="button"
          value="-"
          className="minus"
          onClick={() => {
            dispatch(subtractQuantity(item));
          }}
        />
      ) : (
        <button
          className="minus denyQButton"
          onClick={() => {
            setCustomQuantity(false);
          }}
        >
          <IoCloseSharp />
        </button>
      )}
      <input
        type="text"
        name="quantity"
        value={quantity}
        className="input-text qty text"
        onChange={(e) => {
          var quantity = e.target.value.replace(/\D/g, "");
          setQuantity(quantity);
        }}
        onFocus={() => setCustomQuantity(true)}
        // onBlur={(e) => setCustomQuantity(false)}
      />
      {!customQuantity ? (
        <input
          type="button"
          value="+"
          className="plus"
          onClick={() => {
            dispatch(addQuantity(item));
          }}
        />
      ) : (
        <button
          className="plus acceptQButton"
          onClick={() => {
            if (quantity === "0") {
              item.quantity = Number(1);
              dispatch(subtractQuantity(item));
            } else {
              item.quantity = Number(quantity);
              dispatch(addCustomQuantity(item));
              setCustomQuantity(false);
            }
          }}
        >
          <IoCheckmarkSharp />
        </button>
      )}
    </>
  );
};

export default Input;
