import moment from "moment";
import { EMAIL_LOGOUT_SUCCESS } from "../actions/auth";
import {
  GET_PRODUCTS_FOR_OFFLINE_SAVE_ATTEMPT,
  GET_PRODUCTS_FOR_OFFLINE_SAVE_SUCCESS,
  GET_PRODUCTS_FOR_OFFLINE_SAVE_ERROR,
  FILTER_OFFLINE_PRODUCTS
} from "../actions/offlineData";

const defaultState = {
  getProductsForOfflineSaveAttempt: false,
  getProductsForOfflineSaveSuccess: false,
  getProductsForOfflineSaveError: false,
  getProductsForOfflineSaveErrorMessage: "",

  offlineProductsList: [],
  filteredOfflineProductsList: [],
  offlineProductsAttemptDate: "",
};

export const offlineDataReducer = (state = defaultState, action) => {
  switch (action.type) {
    case GET_PRODUCTS_FOR_OFFLINE_SAVE_ATTEMPT:
      return {
        ...state,
        getProductsForOfflineSaveAttempt: true,
        getProductsForOfflineSaveSuccess: false,
        getProductsForOfflineSaveError: false,
        getProductsForOfflineSaveErrorMessage: "",
      };

    case GET_PRODUCTS_FOR_OFFLINE_SAVE_SUCCESS:
      return {
        ...state,
        getProductsForOfflineSaveAttempt: false,
        getProductsForOfflineSaveSuccess: true,
        getProductsForOfflineSaveError: false,
        getProductsForOfflineSaveErrorMessage: "",
        offlineProductsList: action.data,
        offlineProductsAttemptDate: moment().tz("Canada/Pacific"),
      };

    case GET_PRODUCTS_FOR_OFFLINE_SAVE_ERROR:
      return {
        ...state,
        getProductsForOfflineSaveAttempt: false,
        getProductsForOfflineSaveSuccess: true,
        getProductsForOfflineSaveError: true,
        getProductsForOfflineSaveErrorMessage: action.data,
      };
    
    case FILTER_OFFLINE_PRODUCTS:
      return {
        ...state,
        filteredOfflineProductsList: 
          action.data.categoryId == "All" ? 
          state.offlineProductsList : 
          state.offlineProductsList.filter(
            (item) => {
              return item.categories.find(obj => {
                return obj.id === action.data.categoryId
              })
            }
          ),
      };

    case EMAIL_LOGOUT_SUCCESS:
      return defaultState;

    default:
      return state;
  }
};
