/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { IoCheckmarkSharp, IoCloseSharp } from "react-icons/io5";

const Input = ({ foundedProduct, itemsInOrder, setItemsInOrder }) => {
  const [item, setItem] = useState({});
  const [quantity, setQuantity] = useState(foundedProduct?.quantity ?? 1);
  const [customQuantity, setCustomQuantity] = useState(false);

  useEffect(() => {
    setItem(foundedProduct);
    setQuantity(foundedProduct?.quantity);
  }, [foundedProduct?.quantity]);

  useEffect(() => {
    setQuantity(foundedProduct?.quantity);
  }, [!customQuantity]);

  useEffect(() => {
    if (quantity === 0) {
      setItemsInOrder(
        itemsInOrder?.filter((proizvod) => proizvod?.id !== item?.id)
      );
    }
  }, [quantity]);

  return (
    <>
      {!customQuantity ? (
        <input
          type="button"
          value="-"
          className="minus"
          onClick={() => {
            setItemsInOrder(
              itemsInOrder?.map((proizvod) => {
                if (proizvod?.id === item?.id) {
                  return {
                    ...proizvod,
                    quantity: proizvod.quantity - proizvod.box_quantity,
                  };
                } else {
                  return proizvod;
                }
              })
            );
          }}
        />
      ) : (
        <button
          className="minus denyQButton"
          onClick={() => {
            setCustomQuantity(false);
          }}
        >
          <IoCloseSharp />
        </button>
      )}
      <input
        type="text"
        name="quantity"
        value={quantity}
        disabled
        className="input-text qty text"
        onChange={(e) => {
          var quantity = e.target.value.replace(/\D/g, "");
          setQuantity(quantity);
        }}
        onFocus={() => setCustomQuantity(true)}
      />
      {!customQuantity ? (
        <input
          type="button"
          value="+"
          className="plus"
          onClick={() => {
            setItemsInOrder(
              itemsInOrder?.map((proizvod) => {
                if (proizvod?.id === item?.id) {
                  return {
                    ...proizvod,
                    quantity: proizvod.quantity + proizvod.box_quantity,
                  };
                } else {
                  return proizvod;
                }
              })
            );
          }}
        />
      ) : (
        <button
          className="plus acceptQButton"
          onClick={() => {
            if (quantity === "0") {
              setItemsInOrder(
                itemsInOrder?.filter((proizvod) => proizvod?.id !== item?.id)
              );
            } else {
              setItemsInOrder(
                itemsInOrder?.map((proizvod) => {
                  if (proizvod?.id === item?.id) {
                    return {
                      ...proizvod,
                      quantity: quantity,
                    };
                  } else {
                    return proizvod;
                  }
                })
              );
            }
            setCustomQuantity(false);
          }}
        >
          <IoCheckmarkSharp />
        </button>
      )}
    </>
  );
};

export default Input;
