//rute
import ProfileScreen from "../screens/ProfileScreen/ProfileScreen";
import MyOrdersScreen from "../screens/MyOrdersScreen/MyOrdersScreen";
import SingleOrderScreen from "../screens/SingleOrderScreen/SingleOrderScreen";
import EditOrderScreen from "../screens/EditOrderScreen/EditOrderScreen";

export const customerRoutes = [
  {
    title: "My Profile",
    path: "/my-profile",
    component: ProfileScreen,
    show: false,
  },
  {
    title: "My Orders",
    path: "/my-orders",
    component: MyOrdersScreen,
    show: false,
  },
  {
    title: "Single order",
    path: "/order/:id",
    component: SingleOrderScreen,
    show: false,
  },
  {
    title: "Edit order",
    path: "/edit-order/:id",
    component: EditOrderScreen,
    show: false,
  },
];
