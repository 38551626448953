/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import styles from "./OrdersScreen.module.css";
import { Container, Table, Button, Form, Row, Col } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import moment from "moment-timezone";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import { useSelector, useDispatch } from "react-redux";
import { adminGetCustomerOrdersAttempt } from "../../../redux/actions/orders";
import Pagination from "../../../components/Pagination/Pagination";

const OrdersScreen = () => {
  useEffect(() => {
    document.title = "Miraco - User Orders";
    dispatch(adminGetCustomerOrdersAttempt({ sort: "-created_at" }));
  }, []);

  const [searchQuery, setSearchQuery] = useState("");

  const { customerOrders, next, previous, count } = useSelector(
    (state) => state.orders
  );
  const dispatch = useDispatch();

  const sortOptions = [
    {
      title: "Purchased date - newest",
      name: "-created_at",
    },
    { title: "Purchased date - oldest", name: "created_at" },
    { title: "Price - Lowest to Highest", name: "price" },
    { title: "Price - Highest to Lowest", name: "-price" },
  ];

  const filterOptions = [
    { title: "All" },
    { title: "Paid" },
    { title: "Not paid" },
    { title: "Delivered" },
    { title: "Not delivered" },
  ];

  const [activeSort, setActiveSort] = useState(sortOptions[0]);
  const [activeFilter, setActiveFilter] = useState(filterOptions[0]);

  const [currentPage, setCurrentPage] = useState(1);
  const [visibilityAllPageNumbers, setVisibilityAllPageNumbers] =
    useState(false);
  const [selectedPageToJump, setSelectedPageToJump] = useState("");

  const paginate = (pageNum) => setCurrentPage(pageNum);

  const nextPage = () => setCurrentPage(currentPage + 1);

  const prevPage = () => setCurrentPage(currentPage - 1);

  const changeVisibilityAllPageNumbers = (visibility) =>
    setVisibilityAllPageNumbers(visibility);

  const setJumpedPage = (pageObj) => setSelectedPageToJump(pageObj);

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
    if (currentPage === 1) {
      dispatch(adminGetCustomerOrdersAttempt({ sort: "-created_at" }));
    } else if (currentPage === Number(next?.slice(-1))) {
      let pom_next = next.substring(0, next.indexOf("page"));
      pom_next = `${pom_next}page=${currentPage}`;
      dispatch(adminGetCustomerOrdersAttempt({ payloadUrl: pom_next }));
    } else if (currentPage > Number(next?.slice(-1))) {
      let pom_next = next.substring(0, next.indexOf("page"));
      pom_next = `${pom_next}page=${currentPage}`;
      dispatch(adminGetCustomerOrdersAttempt({ payloadUrl: pom_next }));
    } else {
      dispatch(adminGetCustomerOrdersAttempt({ payloadUrl: previous }));
    }
  }, [currentPage]);

  const renderTable = () => {
    return (
      <Table hover responsive className="table-sm">
        <thead>
          <tr>
            <th>#</th>
            <th>Purchased date</th>
            <th>Customer</th>
            <th>Order status</th>
            <th>Deliver status</th>
            <th>Purchased Items</th>
            <th>Price $CAD</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {customerOrders?.map((order) => {
            const renderWhatIsOrdered = () => {
              if (order?.purchased_items?.length > 1) {
                let firstItem = order?.purchased_items.slice(0, 1);
                return (
                  <span>
                    {firstItem[0]?.product?.name}{" "}
                    <span className={styles.oneMoreSpan}>
                      and {order?.purchased_items?.slice(1)?.length} more
                    </span>
                  </span>
                );
              } else {
                return (
                  <span>
                    {order?.purchased_items.map(
                      (order) => order?.product?.name
                    )}
                  </span>
                );
              }
            };
            return (
              <tr key={order.id}>
                <td>{order?.id}</td>
                <td>{moment(order?.created_at).format("D MMM, h:mm a")}</td>
                <td>{order?.customer?.email}</td>
                <td>{order?.status}</td>
                <td>{order?.delivered_status}</td>
                <td>{renderWhatIsOrdered()}</td>
                <td>{order?.price?.toFixed(2)}</td>
                <td>
                  <LinkContainer to={`/admin/order/${order.id}`}>
                    <Button variant="btn btn-sm btn-outline-success rounded">
                      Details
                    </Button>
                  </LinkContainer>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  };

  return (
    <div className={styles.ordersScreen}>
      <Container>
        <h3 className={styles.ordersHeading}>Manage Orders</h3>
        <Row>
          <Col sm={12}>
            <div className="d-flex">
              <Form.Control
                className="rounded"
                type="text"
                placeholder="Search by customer"
                value={searchQuery}
                onChange={(e) => {
                  if (e.target.value === "") {
                    dispatch(adminGetCustomerOrdersAttempt({ search: "" }));
                  }
                  setSearchQuery(e.target.value.toLowerCase());
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    dispatch(
                      adminGetCustomerOrdersAttempt({
                        search: searchQuery,
                      })
                    );
                  }
                }}
              ></Form.Control>
              <Button
                variant="btn btn-outline-success rounded notInTableButton"
                type="submit"
                disabled={!searchQuery}
                onClick={() => {
                  dispatch(
                    adminGetCustomerOrdersAttempt({ search: searchQuery })
                  );
                }}
              >
                Search
              </Button>
            </div>
          </Col>
          <Col sm={12}>
            <div className="d-flex my-3">
              <div className="d-flex align-items-center">
                <h6 className="mr-3">Sort by </h6>
                <DropdownButton
                  id="dropdown-item-button-order"
                  title={activeSort?.title}
                  size="sm"
                >
                  {sortOptions.map((option, index) => {
                    let isActiveSort =
                      option?.title === activeSort?.title ? true : false;
                    return (
                      <Dropdown.Item
                        className={
                          isActiveSort
                            ? styles.activeSort
                            : styles.notActiveSort
                        }
                        key={index}
                        onClick={() => {
                          setActiveSort(option);
                          dispatch(
                            adminGetCustomerOrdersAttempt({ sort: option.name })
                          );
                        }}
                      >
                        {option.title}
                      </Dropdown.Item>
                    );
                  })}
                </DropdownButton>
              </div>
              <div className="d-flex align-items-center">
                <h6 className="ml-5 mr-3">Show</h6>
                <DropdownButton
                  id="dropdown-item-button-order"
                  title={activeFilter?.title}
                  size="sm"
                >
                  {filterOptions.map((option, index) => {
                    let isActiveFilter =
                      option?.title === activeFilter?.title ? true : false;
                    return (
                      <Dropdown.Item
                        className={
                          isActiveFilter
                            ? styles.activeSort
                            : styles.notActiveSort
                        }
                        key={index}
                        onClick={() => {
                          setActiveFilter(option);
                          if (option.title === "All") {
                            dispatch(
                              adminGetCustomerOrdersAttempt({
                                sort: "-created_at",
                              })
                            );
                          } else if (option.title === "Paid") {
                            dispatch(
                              adminGetCustomerOrdersAttempt({
                                status: "Paid",
                              })
                            );
                          } else if (option.title === "Not paid") {
                            dispatch(
                              adminGetCustomerOrdersAttempt({
                                status: "Not paid",
                              })
                            );
                          } else if (option.title === "Delivered") {
                            dispatch(
                              adminGetCustomerOrdersAttempt({
                                delivered_status: "Delivered",
                              })
                            );
                          } else if (option.title === "Not delivered") {
                            dispatch(
                              adminGetCustomerOrdersAttempt({
                                delivered_status: "Not delivered",
                              })
                            );
                          }
                        }}
                      >
                        {option.title}
                      </Dropdown.Item>
                    );
                  })}
                </DropdownButton>
              </div>
            </div>
          </Col>
        </Row>
        {renderTable()}
        <div className={styles.paginationWrapper}>
          <Pagination
            postsPerPage={16}
            totalPosts={count}
            paginate={paginate}
            nextPage={nextPage}
            prevPage={prevPage}
            totalPageNumber={Math.ceil(count / 16)}
            currentPage={currentPage}
            visibilityAllPageNumbers={visibilityAllPageNumbers}
            changeVisibilityAllPageNumbers={changeVisibilityAllPageNumbers}
            setSelectedPageToJump={setJumpedPage}
            selectedPageToJump={selectedPageToJump}
          />
        </div>
      </Container>
    </div>
  );
};

export default OrdersScreen;
