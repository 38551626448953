import { takeEvery, put, call, select } from "redux-saga/effects";
import axios from "axios";
import history from "../../utils/history";

import {
  EMAIL_REGISTER_ATTEMPT,
  emailRegisterSuccess,
  emailRegisterError,
} from "../actions/auth";
import {
  getProfileAttempt,
  updateProfileAfterRegistrationAttempt,
} from "../actions/profile";

import { serverURL } from "../../../env";

// eslint-disable-next-line import/no-anonymous-default-export
export default function* () {
  yield takeEvery(EMAIL_REGISTER_ATTEMPT, handleRegister);
}

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else {
    var err = new Error(response.statusText);
    err.response = response;
    throw err;
  }
}

function Register(requestURL, options) {
  return axios({
    method: options.method,
    url: requestURL,
    data: {
      username: options.body.username,
      email: options.body.username,
      password1: options.body.password1,
      password2: options.body.password2,
      form: options.body.form,
    },
  }).then(checkStatus);
}

function* handleRegister(payload) {
  const redirectToRegistration = yield select(
    ({ orders }) => orders.redirectToRegistration
  );
  const requestURL = `${serverURL}/rest-auth/registration/`;
  const options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: {
      username: payload.data.data_to_server.email,
      email: payload.data.data_to_server.email,
      password1: payload.data.data_to_server.password1,
      password2: payload.data.data_to_server.password2,
      form: "Customer",
    },
  };

  try {
    const response = yield call(Register, requestURL, options);

    yield put(emailRegisterSuccess(response.data));
    yield put(getProfileAttempt({ customerId: response.data.user }));

    yield put(
      updateProfileAfterRegistrationAttempt({
        data_to_server: payload.data.data_to_update,
        customerId: response.data.user,
        authToken: `Token ${response.data.key}`,
      })
    );

    if (response.data.form === "Customer") {
      if (redirectToRegistration && response.data.verified) {
        history.push("/checkout");
      } else if (response.data.verified) {
        history.push("/products");
      } else {
        history.push("/waiting-verification");
      }
    }
  } catch (err) {
    yield put(emailRegisterError(err?.response?.data?.email[0]));
  }
}
