/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import styles from "./SingleOrderScreen.module.css";
import { useParams } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { useSelector, useDispatch } from "react-redux";
import { clearRedirectFromCreateOrder } from "../../redux/actions/orders";
import { SiteMap } from "../../components/BreadCrumbs/BreadCrumbs";
import { Container, Row, Col } from "react-bootstrap";
import { FaEnvelope, FaPhoneAlt, FaMapMarkerAlt } from "react-icons/fa";
import Loader from "../../components/Loader/Loader";
import { LinkContainer } from "react-router-bootstrap";
import placeholder from "../../../assets/images/placeholder.png";
import moment from "moment-timezone";

const SingleOrderScreen = () => {
  moment.tz.setDefault("Canada/Pacific");

  const { id } = useParams();
  const navLinks = [
    { href: "/my-orders", name: "My orders" },
    { href: `/order/${id}`, name: `Order #${id}` },
  ];
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { redirectFromCreateOrder, orders, getMyOrdersAttempt } = useSelector(
    (state) => state.orders
  );

  const singleOrder = orders?.find((order) => order?.id === Number(id));

  useEffect(() => {
    document.title = "Miraco - Single Order";
    if (redirectFromCreateOrder) {
      addToast(`Order created succesfully`, {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 800,
        onDismiss: () => dispatch(clearRedirectFromCreateOrder()),
      });
    }
    window.scrollTo({
      top: 0,
    });
  }, [redirectFromCreateOrder]);

  const rendeOrder = () => {
    return (
      <>
        <div className={styles.heading}>
          <div className={styles.heading_flag}></div>

          <div className={styles.heading_name}>
            <div>
              <p>Product</p>
            </div>
          </div>

          <div className={styles.heading_population}>
            <div>
              <p>Quantity</p>
            </div>
          </div>

          <div className={styles.heading_population}>
            <div>
              <p>Price</p>
            </div>
          </div>

          <div className={styles.heading_gini}>
            <div>Barcode</div>
          </div>
        </div>

        {singleOrder?.purchased_items?.map((product, index) => (
          <React.Fragment key={product.id}>
            {index === 0 ? <hr /> : null}
            <div className={styles.row}>
              <div className={styles.flag}>
                <img
                  src={product?.product?.image ?? placeholder}
                  alt={product?.product?.image ?? placeholder}
                  className="img-fluid"
                />
              </div>

              <div className={styles.name}>
                <p>{product?.product?.name}</p>
              </div>

              <div className={styles.population}>
                <p>{product?.quantity}</p>
              </div>

              <div className={styles.population}>
                <p>
                  {"CAD "}
                  {(
                    product?.product?.price -
                    product?.product?.price * (product?.product?.special / 100)
                  ).toFixed(2)}{" "}
                </p>
              </div>

              <div className={styles.gini}>
                <p>{product?.product?.barcode}</p>
              </div>
            </div>
            <hr />
          </React.Fragment>
        ))}
      </>
    );
  };

  const renderCustomer = () => {
    return (
      <div className={styles.customerDiv}>
        <div className={styles.customerDataDiv}>
          <h6>Contact info</h6>
          <div className={styles.oneData}>
            <p className={styles.commonOneDataText}>
              <FaEnvelope className="mr-2" /> {singleOrder?.email}
            </p>
            <p className={styles.commonOneDataText}>
              <FaPhoneAlt className="mr-2" />
              {singleOrder?.phone}
            </p>
          </div>
        </div>
        <div className={styles.commonDiv}>
          <h6>Shipping details</h6>
          <div className={styles.oneData}>
            <p className={styles.commonOneDataText}>
              <FaMapMarkerAlt className="mr-2" />
              {singleOrder?.address}, {singleOrder?.state},{" "}
              {singleOrder?.zipcode}, {singleOrder?.townCity},{" "}
              {singleOrder?.country}
            </p>
          </div>
        </div>
        <div className={styles.commonDiv}>
          <h6>Payment summary</h6>
          <div className={styles.oneData}>
            <p className={styles.commonOneDataText}>
              Total paid by customer $CAD {singleOrder?.price?.toFixed(2)}
            </p>
          </div>
        </div>
      </div>
    );
  };

  if (getMyOrdersAttempt) {
    return (
      <div
        style={{
          minHeight: "100vh",
          display: "flex",
          marginTop: "100px",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Loader type="Bars" />
        <h5 className="mt-2">Setting your order...</h5>
      </div>
    );
  }

  return (
    <div className={styles.singleOrderScreen}>
      <Container>
        <SiteMap hrefIn={`/order/${id}`} items={navLinks} />
        <Row>
          <Col sm={12} md={7}>
            <div className={styles.orderHeaderLeft}>
              <h3>Order #{id}</h3>
              <span className={styles.orderPaidStatus}>
                {singleOrder?.status}
              </span>
              <span className={styles.orderDeliverStatus}>
                {singleOrder?.delivered_status}
              </span>
            </div>
          </Col>
          {!singleOrder?.confirmed ? (
            <Col sm={12} md={5}>
              <LinkContainer
                to={{
                  pathname: `/edit-order/${singleOrder?.id}`,
                  singleOrder: singleOrder,
                }}
                className="btn btn-outline-success rounded notInTableButtonEditOrder w-25"
              >
                <span>Edit order</span>
              </LinkContainer>
            </Col>
          ) : (
            <h6>
              Confirmed at{" "}
              {moment(singleOrder?.confirmed_at).format("MM/DD/YYYY h:m a")}
            </h6>
          )}
        </Row>
        <Row>
          <Col sm={12} md={7}>
            {rendeOrder()}
          </Col>
          <Col sm={12} md={5}>
            {renderCustomer()}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SingleOrderScreen;
