/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Form, Row, Col, Container, Button } from "react-bootstrap";
import styles from "./ProfileScreen.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  updateProfileAttempt,
  resetUpdateProfile,
} from "../../redux/actions/profile";
import Loader from "../../components/Loader/Loader";
// import ErrorMessage from "../../components/ErrorMessage/ErrorMessage";
import { useToasts } from "react-toast-notifications";

const ProfileScreen = () => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const { user, email } = useSelector((state) => state.auth);

  const {
    // email,
    phone_number,
    first_name,
    last_name,
    country,
    address,
    townCity,
    state,
    zipcode,
    company,
    getProfileAttempt: loading,
    updateProfileSuccess,
  } = useSelector((state) => state.profile);

  const [profileState, setProfileState] = useState({
    email,
    username: email,
    phone_number,
    first_name,
    last_name,
    country,
    address,
    townCity,
    state,
    zipcode,
    company,
  });

  useEffect(() => {
    if (first_name) {
      setProfileState({
        email,
        username: email,
        phone_number,
        first_name,
        last_name,
        country,
        address,
        townCity,
        state,
        zipcode,
        company,
      });
    }
  }, [first_name]);

  // const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (updateProfileSuccess) {
      addToast(`Profile updated succesfully`, {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 2000,
        onDismiss: () => {
          dispatch(resetUpdateProfile());
        },
      });
    }
  }, [updateProfileSuccess]);

  useEffect(() => {
    document.title = "Miraco - My profile";
    window.scrollTo({
      top: 0,
    });
  }, []);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      updateProfileAttempt({
        data_to_server: {
          email: profileState.email,
          username: profileState.email,
          phone_number: profileState.phone_number,
          first_name: profileState.first_name,
          last_name: profileState.last_name,
          country: profileState.country,
          address: profileState.address,
          townCity: profileState.townCity,
          state: profileState.state,
          zipcode: profileState.zipcode,
          company: profileState.company,
        },
        customerId: user,
      })
    );
  };

  const handleChange = (e) => {
    setProfileState({
      ...profileState,
      [e.target.name]: e.target.value,
    });
  };

  if (loading) {
    return (
      <div
        style={{
          minHeight: "100vh",
          display: "flex",
          marginTop: "100px",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Loader type="Bars" />
        <h5 className="mt-2">Getting your profile...</h5>
      </div>
    );
  }

  return (
    <div className={styles.updateProfilePage}>
      <Container>
        <Row className="justify-content-md-center">
          <Col xs={12} md={6}>
            <Form onSubmit={submitHandler} className={styles.loginForm}>
              <h3 className={styles.signInText}>Edit profile</h3>
              <Form.Group controlId="email">
                <Form.Label>Company</Form.Label>
                <Form.Control
                  className={styles.myInput}
                  type="text"
                  name="company"
                  placeholder="Enter company (optional)"
                  value={profileState.company}
                  onChange={handleChange}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId="email">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  className={styles.myInput}
                  type="email"
                  name="email"
                  placeholder="Enter email"
                  value={profileState.email}
                  onChange={handleChange}
                  disabled
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId="password">
                <Form.Label>Phone number</Form.Label>
                <Form.Control
                  className={styles.myInput}
                  type="text"
                  name="phone_number"
                  placeholder="Enter phone number"
                  value={profileState.phone_number}
                  onChange={handleChange}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId="password">
                <Form.Label>First name</Form.Label>
                <Form.Control
                  className={styles.myInput}
                  type="text"
                  name="first_name"
                  placeholder="Enter first name"
                  value={profileState.first_name}
                  onChange={handleChange}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId="password">
                <Form.Label>Last name</Form.Label>
                <Form.Control
                  className={styles.myInput}
                  type="text"
                  name="last_name"
                  placeholder="Enter last name"
                  value={profileState.last_name}
                  onChange={handleChange}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId="password">
                <Form.Label>Your country</Form.Label>
                <Form.Control
                  className={styles.myInput}
                  type="text"
                  name="country"
                  placeholder="Enter country"
                  value={profileState.country}
                  onChange={handleChange}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId="password">
                <Form.Label>Address</Form.Label>
                <Form.Control
                  className={styles.myInput}
                  type="text"
                  name="address"
                  placeholder="Enter your address"
                  value={profileState.address}
                  onChange={handleChange}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId="password">
                <Form.Label>Town/City</Form.Label>
                <Form.Control
                  className={styles.myInput}
                  type="text"
                  name="townCity"
                  placeholder="Enter your town/city"
                  value={profileState.townCity}
                  onChange={handleChange}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId="password">
                <Form.Label>State</Form.Label>
                <Form.Control
                  className={styles.myInput}
                  type="text"
                  name="state"
                  placeholder="Enter your state"
                  value={profileState.state}
                  onChange={handleChange}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId="password">
                <Form.Label>Zip</Form.Label>
                <Form.Control
                  className={styles.myInput}
                  type="text"
                  name="zipcode"
                  placeholder="Enter your zip"
                  value={profileState.zipcode}
                  onChange={handleChange}
                ></Form.Control>
              </Form.Group>
              {/* {showError && <ErrorMessage>{error}</ErrorMessage>} */}
              <Button
                type="submit"
                // disabled={isDisabled}
                variant="btn btn-sm btn-outline-success rounded notInTableButton"
              >
                {loading ? <Loader /> : "Confirm changes"}
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ProfileScreen;
