import { takeEvery, put, call, select } from "redux-saga/effects";
import axios from "axios";

import {
  GET_MY_ORDERS_ATTEMPT,
  getMyOrdersSuccess,
  getMyOrdersError,
} from "../actions/orders";

import { serverURL } from "../../../env";

// eslint-disable-next-line import/no-anonymous-default-export
export default function* () {
  yield takeEvery(GET_MY_ORDERS_ATTEMPT, handleGet);
}

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else {
    var err = new Error(response.statusText);
    err.response = response;
    throw err;
  }
}

function getAll(requestURL, options) {
  return axios({
    method: options.method,
    headers: options.headers,
    url: requestURL,
  }).then(checkStatus);
}

function* handleGet(payload) {
  const user_token = yield select(({ auth }) => auth.authToken);

  let fetchNext = payload?.data?.payloadUrl
    ? payload?.data?.payloadUrl.replace("http", "https")
    : `${serverURL}/orders/my_orders/`;

  let haveSort = payload?.data?.sort
    ? `${serverURL}/orders/my_orders/?sort=${payload.data.sort}`
    : fetchNext;

  let filter = payload.data.status
    ? `${serverURL}/orders/my_orders/?status=${payload.data.status}`
    : payload.data.delivered_status
    ? `${serverURL}/orders/my_orders/?delivered_status=${payload.data.delivered_status}`
    : haveSort;

  const requestURL = filter;

  const options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: user_token,
    },
  };

  try {
    const response = yield call(getAll, requestURL, options);

    yield put(getMyOrdersSuccess(response.data));
  } catch (err) {
    yield put(getMyOrdersError(err));
  }
}
