import React, { useState, useEffect } from "react";
import { Form, Row, Col, Container, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import styles from "./RequestNewPasswordScreen.module.css";
import { useDispatch, useSelector } from "react-redux";
import { requestNewPasswordAttempt } from "../../../redux/actions/auth";
import { showPasswordError } from "../../../utils/validate";
import Loader from "../../../components/Loader/Loader";
import ErrorMessage from "../../../components/ErrorMessage/ErrorMessage";

const RequestNewPasswordScreen = () => {
  useEffect(() => {
    document.title = "Miraco - New password";
    window.scrollTo({
      top: 0,
    });
  }, []);

  const dispatch = useDispatch();
  const {
    requestNewPasswordAttempt: loading,
    requestNewPasswordErrorMessage: error,
  } = useSelector((state) => state.auth);

  const [password, setPassword] = useState("");
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [change, setChange] = useState(false);

  const isDisabled = !password || loading;

  const submitHandler = (e) => {
    e.preventDefault();
    if (!isDisabled) {
      setChange(true);
      dispatch(
        requestNewPasswordAttempt({
          new_password: password,
        })
      );
    }
  };

  return (
    <div className={styles.loginPage}>
      <Container>
        <Row className="justify-content-md-center">
          <Col xs={12} md={6}>
            <Form onSubmit={submitHandler} className={styles.loginForm}>
              <h3 className={styles.signInText}>Enter new password</h3>
              <Form.Group controlId="password">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  className={styles.myInput}
                  type="password"
                  placeholder="Enter password"
                  value={password}
                  onBlur={() => setPasswordChanged(true)}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setChange(false);
                  }}
                ></Form.Control>
                {showPasswordError(password, passwordChanged)}
              </Form.Group>
              <div className={styles.commonGoToDiv}>
                <Link className={styles.commonGoTLink} to={"/login"}>
                  Back to login
                </Link>
              </div>
              {change && error && <ErrorMessage>{error}</ErrorMessage>}
              <Button
                type="submit"
                disabled={isDisabled}
                variant="btn btn-sm btn-outline-success rounded notInTableButton"
              >
                {loading ? <Loader /> : "Change password"}
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default RequestNewPasswordScreen;
