export const EMAIL_LOGIN_ATTEMPT = "EMAIL_LOGIN_ATTEMPT";
export const EMAIL_LOGIN_SUCCESS = "EMAIL_LOGIN_SUCCESS";
export const EMAIL_LOGIN_ERROR = "EMAIL_LOGIN_ERROR";

export const emailLoginAttempt = (data) => ({
  type: EMAIL_LOGIN_ATTEMPT,
  data,
});

export const emailLoginSuccess = (data) => ({
  type: EMAIL_LOGIN_SUCCESS,
  data,
});

export const emailLoginError = (data) => ({
  type: EMAIL_LOGIN_ERROR,
  data,
});

export const EMAIL_LOGOUT_SUCCESS = "EMAIL_LOGOUT_SUCCESS";

export const emailLogoutSuccess = () => ({
  type: EMAIL_LOGOUT_SUCCESS,
});

export const EMAIL_REGISTER_ATTEMPT = "EMAIL_REGISTER_ATTEMPT";
export const EMAIL_REGISTER_SUCCESS = "EMAIL_REGISTER_SUCCESS";
export const EMAIL_REGISTER_ERROR = "EMAIL_REGISTER_ERROR";

export const emailRegisterAttempt = (data) => ({
  type: EMAIL_REGISTER_ATTEMPT,
  data,
});

export const emailRegisterSuccess = (data) => ({
  type: EMAIL_REGISTER_SUCCESS,
  data,
});

export const emailRegisterError = (data) => ({
  type: EMAIL_REGISTER_ERROR,
  data,
});

export const GET_RESET_CODE_ATTEMPT = "GET_RESET_CODE_ATTEMPT";
export const GET_RESET_CODE_SUCCESS = "GET_RESET_CODE_SUCCESS";
export const GET_RESET_CODE_ERROR = "GET_RESET_CODE_ERROR";

export const getResetCodeAttempt = (data) => ({
  type: GET_RESET_CODE_ATTEMPT,
  data,
});

export const getResetCodeSuccess = (data) => ({
  type: GET_RESET_CODE_SUCCESS,
  data,
});

export const getResetCodeError = (data) => ({
  type: GET_RESET_CODE_ERROR,
  data,
});

export const VALIDATE_RESET_CODE_ATTEMPT = "VALIDATE_RESET_CODE_ATTEMPT";
export const VALIDATE_RESET_CODE_SUCCESS = "VALIDATE_RESET_CODE_SUCCESS";
export const VALIDATE_RESET_CODE_ERROR = "VALIDATE_RESET_CODE_ERROR";

export const validateResetCodeAttempt = (data) => ({
  type: VALIDATE_RESET_CODE_ATTEMPT,
  data,
});

export const validateResetCodeSuccess = (data) => ({
  type: VALIDATE_RESET_CODE_SUCCESS,
  data,
});

export const validateResetCodeError = (data) => ({
  type: VALIDATE_RESET_CODE_ERROR,
  data,
});

export const REQUEST_NEW_PASSWORD_ATTEMPT = "REQUEST_NEW_PASSWORD_ATTEMPT";
export const REQUEST_NEW_PASSWORD_SUCCESS = "REQUEST_NEW_PASSWORD_SUCCESS";
export const REQUEST_NEW_PASSWORD_ERROR = "REQUEST_NEW_PASSWORD_ERROR";

export const requestNewPasswordAttempt = (data) => ({
  type: REQUEST_NEW_PASSWORD_ATTEMPT,
  data,
});

export const requestNewPasswordSuccess = (data) => ({
  type: REQUEST_NEW_PASSWORD_SUCCESS,
  data,
});

export const requestNewPasswordError = (data) => ({
  type: REQUEST_NEW_PASSWORD_ERROR,
  data,
});
