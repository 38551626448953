/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import styles from "./ManageCustomers.module.css";
import { Container, Table, Row, Form, Col, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { adminGetCustomersAttempt } from "../../../redux/actions/orders";
import { LinkContainer } from "react-router-bootstrap";
import { Link } from "react-router-dom";
import Pagination from "../../../components/Pagination/Pagination";

const CustomersScreen = () => {
  useEffect(() => {
    document.title = "Miraco - Customers";
    dispatch(adminGetCustomersAttempt({ search: "" }));
  }, []);

  const {
    customers,
    customersNext: next,
    customersPrevious: previous,
    customersCount: count,
  } = useSelector((state) => state.orders);
  const dispatch = useDispatch();

  const [searchQuery, setSearchQuery] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [visibilityAllPageNumbers, setVisibilityAllPageNumbers] =
    useState(false);
  // const [selectedPageToJump, setSelectedPageToJump] = useState("");

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
    dispatch(adminGetCustomersAttempt({ payloadUrl: next }));
  };

  const prevPage = () => {
    setCurrentPage(currentPage - 1);
    dispatch(adminGetCustomersAttempt({ payloadUrl: previous }));
  };

  const changeVisibilityAllPageNumbers = (visibility) =>
    setVisibilityAllPageNumbers(visibility);

  const paginate = (pageNum) => {
    setCurrentPage(pageNum);
    if (searchQuery) {
      dispatch(
        adminGetCustomersAttempt({
          search: searchQuery,
          page: pageNum,
        })
      );
    } else {
      dispatch(
        adminGetCustomersAttempt({
          page: pageNum,
        })
      );
    }
  };

  // const setJumpedPage = (pageObj) => setSelectedPageToJump(pageObj);

  const renderTable = () => {
    return (
      <Table hover responsive className="table-sm">
        <thead>
          <tr>
            <th>Company</th>
            <th>First name</th>
            <th>Last name</th>
            <th>Phone number</th>
            <th>Email</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {customers?.map((customer) => {
            return (
              <tr key={customer?.id}>
                <td>{customer?.company}</td>
                <td>{customer?.first_name}</td>
                <td>{customer?.last_name}</td>
                <td>{customer?.phone_number}</td>
                <td>{customer?.email}</td>
                <td>
                  <div className="d-flex">
                    <LinkContainer
                      to={`/admin/customer-orders/customer/${customer.id}`}
                    >
                      <Button
                        variant="btn btn-sm btn-outline-success rounded"
                        className="mr-3"
                      >
                        View all orders
                      </Button>
                    </LinkContainer>
                    <Link
                      // target="_blank"
                      // rel="noopener noreferrer"
                      to={`/admin/edit-customer/${customer.id}`}
                    >
                      <Button variant="btn btn-sm btn-outline-success rounded">
                        Edit customer
                      </Button>
                    </Link>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  };

  return (
    <div className={styles.customersScreen}>
      <Container>
        <div className="d-flex align-items-center my-5 justify-content-between">
          <h3 className={styles.productListHeading}>Manage customers</h3>
          <LinkContainer to="/admin/create-customer">
            <Button variant="btn btn-sm btn-outline-success rounded notInTableButton">
              Add customer
            </Button>
          </LinkContainer>
        </div>
        <Row>
          <Col>
            <div className="d-flex">
              <Form.Control
                className="rounded"
                type="text"
                placeholder="Search by customer first name, last name, email or phone number"
                value={searchQuery}
                onChange={(e) => {
                  if (e.target.value === "") {
                    dispatch(adminGetCustomersAttempt({ search: "" }));
                  }
                  setSearchQuery(e.target.value.toLowerCase());
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    dispatch(adminGetCustomersAttempt({ search: searchQuery }));
                  }
                }}
              ></Form.Control>
              <Button
                disabled={!searchQuery}
                type="submit"
                variant="btn btn-outline-success notInTableButton rounded"
                onClick={() => {
                  dispatch(adminGetCustomersAttempt({ search: searchQuery }));
                }}
              >
                Search
              </Button>
            </div>
          </Col>
        </Row>
        {renderTable()}
        <div className={styles.paginationWrapper}>
          <Pagination
            postsPerPage={16}
            totalPosts={count}
            paginate={paginate}
            nextPage={nextPage}
            prevPage={prevPage}
            totalPageNumber={Math.ceil(count / 16)}
            currentPage={currentPage}
            visibilityAllPageNumbers={visibilityAllPageNumbers}
            changeVisibilityAllPageNumbers={changeVisibilityAllPageNumbers}
          // setSelectedPageToJump={setJumpedPage}
          // selectedPageToJump={selectedPageToJump}
          />
        </div>
      </Container>
    </div>
  );
};

export default CustomersScreen;
