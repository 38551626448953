import { takeEvery, put, call } from "redux-saga/effects";
import axios from "axios";

import {
  GET_CATEGORY_ATTEMPT,
  getCategorySuccess,
  getCategoryError,
} from "../actions/categories";

import { serverURL } from "../../../env";

// eslint-disable-next-line import/no-anonymous-default-export
export default function* () {
  yield takeEvery(GET_CATEGORY_ATTEMPT, handleGet);
}

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else {
    var err = new Error(response.statusText);
    err.response = response;
    throw err;
  }
}

function getAllCategories(requestURL, options) {
  return axios({
    method: options.method,
    headers: options.headers,
    url: requestURL,
  }).then(checkStatus);
}

function* handleGet(payload) {
  let url = payload?.data?.isDeleted ? `?is_deleted=true` : `?is_deleted=false`;
  const requestURL = `${serverURL}/categories/list/${url}`;
  const options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  try {
    const response = yield call(getAllCategories, requestURL, options);

    yield put(getCategorySuccess(response.data));
  } catch (err) {
    yield put(getCategoryError(err));
  }
}
