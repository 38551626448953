import { EMAIL_LOGOUT_SUCCESS } from "../actions/auth";
import {
  GET_CATEGORY_ATTEMPT,
  GET_CATEGORY_SUCCESS,
  GET_CATEGORY_ERROR,
  CREATE_CATEGORY_ATTEMPT,
  CREATE_CATEGORY_SUCCESS,
  CREATE_CATEGORY_ERROR,
  EDIT_CATEGORY_ATTEMPT,
  EDIT_CATEGORY_SUCCESS,
  EDIT_CATEGORY_ERROR,
  RESET_CREATE_CATEGORY,
  RESET_EDIT_CATEGORY,
  SET_ACTIVE_CATEGORY_TAB,
} from "../actions/categories";

const defaultState = {
  categories: [],

  getCategoryAttempt: false,
  getCategorySuccess: false,
  getCategoryError: false,
  getCategoryErrorMessage: "",

  createCategoryAttempt: false,
  createCategorySuccess: false,
  createCategoryError: false,
  createCategoryErrorMessage: "",

  editCategoryAttempt: false,
  editCategorySuccess: false,
  editCategoryError: false,
  editCategoryErrorMessage: "",

  activeTab: "allCategories",
};

export const categoriesReducer = (state = defaultState, action) => {
  switch (action.type) {
    case GET_CATEGORY_ATTEMPT:
      return {
        ...state,
        getCategoryAttempt: true,
        getCategorySuccess: false,
        getCategoryError: false,
        getCategoryErrorMessage: "",
      };

    case GET_CATEGORY_SUCCESS:
      return {
        ...state,
        categories: action.data,
        getCategoryAttempt: false,
        getCategorySuccess: true,
        getCategoryError: false,
        getCategoryErrorMessage: "",
      };

    case GET_CATEGORY_ERROR:
      return {
        ...state,
        getCategoryAttempt: false,
        getCategorySuccess: false,
        getCategoryError: true,
        getCategoryErrorMessage: action.data,
      };

    case CREATE_CATEGORY_ATTEMPT:
      return {
        ...state,
        createCategoryAttempt: true,
        createCategorySuccess: false,
        createCategoryError: false,
        createCategoryErrorMessage: "",
      };

    case CREATE_CATEGORY_SUCCESS:
      return {
        ...state,
        categories: [...state.categories, action.data],
        createCategoryAttempt: false,
        createCategorySuccess: true,
        createCategoryError: false,
        createCategoryErrorMessage: "",
      };

    case CREATE_CATEGORY_ERROR:
      return {
        ...state,
        createCategoryAttempt: false,
        createCategorySuccess: false,
        createCategoryError: true,
        createCategoryErrorMessage: action.data,
      };

    case RESET_CREATE_CATEGORY:
      return {
        ...state,
        createCategoryAttempt: false,
        createCategorySuccess: false,
        createCategoryError: false,
        createCategoryErrorMessage: "",
      };

    case EDIT_CATEGORY_ATTEMPT:
      return {
        ...state,
        editCategoryAttempt: true,
        editCategorySuccess: false,
        editCategoryError: false,
        editCategoryErrorMessage: "",
      };

    case EDIT_CATEGORY_SUCCESS:
      const checkWhatToDo = () => {
        let categoryFromState = state.categories.find(
          (category) => category.id === action.data.id
        );

        if (action.data.is_deleted) {
          return state.categories.filter(
            (category) => category.id !== action.data.id
          );
        } else if (categoryFromState?.is_deleted) {
          return state.categories.filter(
            (category) => category.id !== action.data.id
          );
        } else {
          return state.categories.map((category) => {
            if (category.id === action.data.id) {
              return { ...action.data };
            }
            return category;
          });
        }
      };

      return {
        ...state,
        editCategoryAttempt: false,
        editCategorySuccess: true,
        editCategoryError: false,
        editCategoryErrorMessage: "",
        categories: checkWhatToDo(),
      };

    case EDIT_CATEGORY_ERROR:
      return {
        ...state,
        editCategoryAttempt: false,
        editCategorySuccess: false,
        editCategoryError: true,
        editCategoryErrorMessage: action.data,
      };

    case RESET_EDIT_CATEGORY:
      return {
        ...state,
        editCategoryAttempt: false,
        editCategorySuccess: false,
        editCategoryError: false,
        editCategoryErrorMessage: "",
      };

    case SET_ACTIVE_CATEGORY_TAB:
      return {
        ...state,
        activeTab: action.data,
      };

    case EMAIL_LOGOUT_SUCCESS:
      return defaultState;

    default:
      return state;
  }
};
