/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import styles from "./OneCustomerOrdersScreen.module.css";
import { Container, Table, Row, Col, Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { adminGetCustomerOrdersAttempt } from "../../../redux/actions/orders";
import { LinkContainer } from "react-router-bootstrap";
import moment from "moment-timezone";
import Pagination from "../../../components/Pagination/Pagination";

const OneCustomerOrdersScreen = () => {
  const { id } = useParams();
  const { customers, next, customerOrders, previous, count } = useSelector(
    (state) => state.orders
  );
  const dispatch = useDispatch();

  const customer = customers?.find((item) => item?.id === Number(id));

  useEffect(() => {
    dispatch(adminGetCustomerOrdersAttempt({ customer: Number(id) }));
  }, [id]);

  const [currentPage, setCurrentPage] = useState(1);
  const [visibilityAllPageNumbers, setVisibilityAllPageNumbers] =
    useState(false);
  const [selectedPageToJump, setSelectedPageToJump] = useState("");

  const paginate = (pageNum) => setCurrentPage(pageNum);

  const nextPage = () => setCurrentPage(currentPage + 1);

  const prevPage = () => setCurrentPage(currentPage - 1);

  const changeVisibilityAllPageNumbers = (visibility) =>
    setVisibilityAllPageNumbers(visibility);

  const setJumpedPage = (pageObj) => setSelectedPageToJump(pageObj);

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
    if (currentPage === 1) {
      dispatch(adminGetCustomerOrdersAttempt({ customer: Number(id) }));
    } else if (currentPage === Number(next?.slice(-1))) {
      let pom_next = next.substring(0, next.indexOf("page"));
      pom_next = `${pom_next}page=${currentPage}`;
      dispatch(adminGetCustomerOrdersAttempt({ payloadUrl: pom_next }));
    } else if (currentPage > Number(next?.slice(-1))) {
      let pom_next = next.substring(0, next.indexOf("page"));
      pom_next = `${pom_next}page=${currentPage}`;
      dispatch(adminGetCustomerOrdersAttempt({ payloadUrl: pom_next }));
    } else {
      dispatch(adminGetCustomerOrdersAttempt({ payloadUrl: previous }));
    }
  }, [currentPage]);

  const renderTable = () => {
    return (
      <Table hover responsive className="table-sm">
        <thead>
          <tr>
            <th>#</th>
            <th>Purchased date</th>
            <th>Customer</th>
            <th>Order status</th>
            <th>Deliver status</th>
            <th>Purchased Items</th>
            <th>Price $CAD</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {customerOrders?.map((order) => {
            const renderWhatIsOrdered = () => {
              if (order?.purchased_items?.length > 1) {
                let firstItem = order?.purchased_items.slice(0, 1);
                return (
                  <span>
                    {firstItem[0]?.product?.name}{" "}
                    <span className={styles.oneMoreSpan}>
                      and {order?.purchased_items?.slice(1)?.length} more
                    </span>
                  </span>
                );
              } else {
                return (
                  <span>
                    {order?.purchased_items.map(
                      (order) => order?.product?.name
                    )}
                  </span>
                );
              }
            };
            return (
              <tr key={order.id}>
                <td>{order?.id}</td>
                <td>{moment(order?.created_at).format("D MMM, h:mm a")}</td>
                <td>{order?.customer?.email}</td>
                <td>{order?.status}</td>
                <td>{order?.delivered_status}</td>
                <td>{renderWhatIsOrdered()}</td>
                <td>{order?.price?.toFixed(2)}</td>
                <td>
                  <LinkContainer to={`/admin/order/${order.id}`}>
                    <Button variant="btn btn-sm btn-outline-success rounded">
                      Details
                    </Button>
                  </LinkContainer>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  };

  return (
    <div className={styles.oneCustomerOrdersScreen}>
      <Container>
        <h3 className={styles.customersHeading}>
          All orders from {customer?.email}
        </h3>
        <Row>
          <Col>
            {renderTable()}
            <div className={styles.paginationWrapper}>
              <Pagination
                postsPerPage={16}
                totalPosts={count}
                paginate={paginate}
                nextPage={nextPage}
                prevPage={prevPage}
                totalPageNumber={Math.ceil(count / 16)}
                currentPage={currentPage}
                visibilityAllPageNumbers={visibilityAllPageNumbers}
                changeVisibilityAllPageNumbers={changeVisibilityAllPageNumbers}
                setSelectedPageToJump={setJumpedPage}
                selectedPageToJump={selectedPageToJump}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default OneCustomerOrdersScreen;
