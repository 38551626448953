import { EMAIL_LOGOUT_SUCCESS } from "../actions/auth";
import {
  SAVE_CREATED_ORDER,
  DELETE_CREATED_ORDER,
  REDIRECT_TO_REGISTRATION,
  CREATE_ORDER_ATTEMPT,
  CREATE_ORDER_SUCCESS,
  CREATE_ORDER_ERROR,
  RESET_CREATE_ORDER,
  GET_MY_ORDERS_ATTEMPT,
  GET_MY_ORDERS_SUCCESS,
  GET_MY_ORDERS_ERROR,
  REDIRECT_FROM_CREATE_ORDER,
  CLEAR_REDIRECT_FROM_CREATE_ORDER,
  ADMIN_GET_CUSTOMER_ORDERS_ATTEMPT,
  ADMIN_GET_CUSTOMER_ORDERS_SUCCESS,
  ADMIN_GET_CUSTOMER_ORDERS_ERROR,
  ADMIN_GET_CUSTOMERS_ATTEMPT,
  ADMIN_GET_CUSTOMERS_SUCCESS,
  ADMIN_GET_CUSTOMERS_ERROR,
  EDIT_ORDER_BILLING_DETAILS_ATTEMPT,
  EDIT_ORDER_BILLING_DETAILS_SUCCESS,
  EDIT_ORDER_BILLING_DETAILS_ERROR,
  EDIT_ORDER_DELETE_ITEM_ATTEMPT,
  EDIT_ORDER_DELETE_ITEM_SUCCESS,
  EDIT_ORDER_DELETE_ITEM_ERROR,
  EDIT_ORDER_CHANGE_QUANTITY_ATTEMPT,
  EDIT_ORDER_CHANGE_QUANTITY_SUCCESS,
  EDIT_ORDER_CHANGE_QUANTITY_ERROR,
  RESET_ORDER_EDITING,
  EDIT_ORDER_ADD_TO_ORDER_ATTEMPT,
  EDIT_ORDER_ADD_TO_ORDER_SUCCESS,
  EDIT_ORDER_ADD_TO_ORDER_ERROR,
  ADMIN_EDIT_CUSTOMERS_ATTEMPT,
  ADMIN_EDIT_CUSTOMERS_SUCCESS,
  ADMIN_EDIT_CUSTOMERS_ERROR,
  RESET_ADMIN_EDIT_CUSTOMERS,
  ADMIN_CREATE_ORDER_ATTEMPT,
  ADMIN_CREATE_ORDER_SUCCESS,
  ADMIN_CREATE_ORDER_ERROR,
  ADMIN_GET_CUSTOMERS_NO_PAGINATION_ATTEMPT,
  ADMIN_GET_CUSTOMERS_NO_PAGINATION_SUCCESS,
  ADMIN_GET_CUSTOMERS_NO_PAGINATION_ERROR,
  ADMIN_EDIT_CUSTOMER_DATA_ATTEMPT,
  ADMIN_EDIT_CUSTOMER_DATA_SUCCESS,
  ADMIN_EDIT_CUSTOMER_DATA_ERROR,
  RESET_ADMIN_EDIT_CUSTOMER_DATA,
  ADMIN_CREATE_CUSTOMER_ATTEMPT,
  ADMIN_CREATE_CUSTOMER_SUCCESS,
  ADMIN_CREATE_CUSTOMER_ERROR,
  RESET_ADMIN_CREATE_CUSTOMER,
  ADMIN_DELETE_CUSTOMER_ATTEMPT,
  ADMIN_DELETE_CUSTOMER_SUCCESS,
  ADMIN_DELETE_CUSTOMER_ERROR,
  RESET_ADMIN_DELETE_CUSTOMER,
} from "../actions/orders";

const defaultState = {
  savedOrder: {},
  redirectToRegistration: false,
  orders: [],
  customerOrders: [],
  customers: [],
  customersNoPagination: [],

  createOrderAttempt: false,
  createOrderSuccess: false,
  createOrderError: false,
  createOrderErrorMessage: "",

  getMyOrdersAttempt: false,
  getMyOrdersSuccess: false,
  getMyOrdersError: false,
  getMyOrdersErrorMessage: "",

  adminCreateOrderAttempt: false,
  adminCreateOrderSuccess: false,
  adminCreateOrderError: false,
  adminCreateOrderErrorMessage: "",

  adminGetCustomerOrdersAttempt: false,
  adminGetCustomerOrdersSuccess: false,
  adminGetCustomerOrdersError: false,
  adminGetCustomerOrdersErrorMessage: "",

  adminGetCustomersAttempt: false,
  adminGetCustomersSuccess: false,
  adminGetCustomersError: false,
  adminGetCustomersErrorMessage: "",

  redirectFromCreateOrder: false,

  editOrderBillingDetailsAttempt: false,
  editOrderBillingDetailsSuccess: false,
  editOrderBillingDetailsError: false,
  editOrderBillingDetailsErrorMessage: "",

  editOrderDeleteItemAttempt: false,
  editOrderDeleteItemSuccess: false,
  editOrderDeleteItemError: false,
  editOrderDeleteItemErrorMessage: "",

  editOrderChangeQuantityAttempt: false,
  editOrderChangeQuantitySuccess: false,
  editOrderChangeQuantityError: false,
  editOrderChangeQuantityErrorMessage: "",

  editOrderAddToOrderAttempt: false,
  editOrderAddToOrderSuccess: false,
  editOrderAddToOrderError: false,
  editOrderAddToOrderErrorMessage: "",

  adminEditCustomersAttempt: false,
  adminEditCustomersSuccess: false,
  adminEditCustomersError: false,
  adminEditCustomersErrorMessage: "",

  adminGetCustomersNoPaginationAttempt: false,
  adminGetCustomersNoPaginationSuccess: false,
  adminGetCustomersNoPaginationError: false,
  adminGetCustomersNoPaginationErrorMessage: "",

  adminEditCustomerDataAttempt: false,
  adminEditCustomerDataSuccess: false,
  adminEditCustomerDataError: false,
  adminEditCustomerDataErrorMessage: "",

  adminCreateCustomerAttempt: false,
  adminCreateCustomerSuccess: false,
  adminCreateCustomerError: false,
  adminCreateCustomerErrorMessage: "",

  adminDeleteCustomerAttempt: false,
  adminDeleteCustomerSuccess: false,
  adminDeleteCustomerError: false,
  adminDeleteCustomerErrorMessage: "",
};

export const ordersReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ADMIN_CREATE_CUSTOMER_ATTEMPT:
      return {
        ...state,
        adminCreateCustomerAttempt: true,
        adminCreateCustomerSuccess: false,
        adminCreateCustomerError: false,
        adminCreateCustomerErrorMessage: "",
      };
    case ADMIN_CREATE_CUSTOMER_SUCCESS:
      return {
        ...state,
        adminCreateCustomerAttempt: false,
        adminCreateCustomerSuccess: true,
        adminCreateCustomerError: false,
        adminCreateCustomerErrorMessage: "",
        customers: [...state.customers, action.data],
      };
    case ADMIN_CREATE_CUSTOMER_ERROR:
      return {
        ...state,
        adminCreateCustomerAttempt: false,
        adminCreateCustomerSuccess: false,
        adminCreateCustomerError: true,
        adminCreateCustomerErrorMessage: action.data,
      };
    case RESET_ADMIN_CREATE_CUSTOMER:
      return {
        ...state,
        adminCreateCustomerAttempt: false,
        adminCreateCustomerSuccess: false,
        adminCreateCustomerError: false,
        adminCreateCustomerErrorMessage: "",
      };
    case ADMIN_EDIT_CUSTOMER_DATA_ATTEMPT:
      return {
        ...state,
        adminEditCustomerDataAttempt: true,
        adminEditCustomerDataSuccess: false,
        adminEditCustomerDataError: false,
        adminEditCustomerDataErrorMessage: "",
      };
    case ADMIN_EDIT_CUSTOMER_DATA_SUCCESS:
      return {
        ...state,
        adminEditCustomerDataAttempt: false,
        adminEditCustomerDataSuccess: true,
        adminEditCustomerDataError: false,
        adminEditCustomerDataErrorMessage: "",
        customers: state.customers.map((customer) => {
          if (customer.id === action.data.id) {
            return {
              ...action.data,
            };
          } else return customer;
        }),
      };
    case ADMIN_EDIT_CUSTOMER_DATA_ERROR:
      return {
        ...state,
        adminEditCustomerDataAttempt: false,
        adminEditCustomerDataSuccess: false,
        adminEditCustomerDataError: true,
        adminEditCustomerDataErrorMessage: action.data,
      };
    case RESET_ADMIN_EDIT_CUSTOMER_DATA:
      return {
        ...state,
        adminEditCustomerDataAttempt: false,
        adminEditCustomerDataSuccess: false,
        adminEditCustomerDataError: false,
        adminEditCustomerDataErrorMessage: "",
      };

    case ADMIN_DELETE_CUSTOMER_ATTEMPT:
      return {
        ...state,
        adminDeleteCustomerAttempt: true,
        adminDeleteCustomerSuccess: false,
        adminDeleteCustomerError: false,
        adminDeleteCustomerErrorMessage: "",
      };
    case ADMIN_DELETE_CUSTOMER_SUCCESS:
      return {
        ...state,
        adminDeleteCustomerAttempt: false,
        adminDeleteCustomerSuccess: true,
        adminDeleteCustomerError: false,
        adminDeleteCustomerErrorMessage: "",
        customers: state.customers.filter((item) => item.id !== action.data.id),
      };
    case ADMIN_DELETE_CUSTOMER_ERROR:
      return {
        ...state,
        adminDeleteCustomerAttempt: false,
        adminDeleteCustomerSuccess: false,
        adminDeleteCustomerError: true,
        adminDeleteCustomerErrorMessage: action.data,
      };
    case RESET_ADMIN_DELETE_CUSTOMER:
      return {
        ...state,
        adminDeleteCustomerAttempt: false,
        adminDeleteCustomerSuccess: false,
        adminDeleteCustomerError: false,
        adminDeleteCustomerErrorMessage: "",
      };

    case ADMIN_EDIT_CUSTOMERS_ATTEMPT:
      return {
        ...state,
        adminEditCustomersAttempt: true,
        adminEditCustomersSuccess: false,
        adminEditCustomersError: false,
        adminEditCustomersErrorMessage: "",
      };
    case ADMIN_EDIT_CUSTOMERS_SUCCESS:
      return {
        ...state,
        adminEditCustomersAttempt: false,
        adminEditCustomersSuccess: true,
        adminEditCustomersError: false,
        adminEditCustomersErrorMessage: "",
        customers: state.customers.map((customer) => {
          if (customer.id === action.data.id) {
            return {
              ...action.data,
            };
          } else return customer;
        }),
      };
    case ADMIN_EDIT_CUSTOMERS_ERROR:
      return {
        ...state,
        adminEditCustomersAttempt: false,
        adminEditCustomersSuccess: false,
        adminEditCustomersError: true,
        adminEditCustomersErrorMessage: action.data,
      };
    case RESET_ADMIN_EDIT_CUSTOMERS:
      return {
        ...state,
        adminEditCustomersAttempt: false,
        adminEditCustomersSuccess: false,
        adminEditCustomersError: false,
        adminEditCustomersErrorMessage: "",
      };
    case SAVE_CREATED_ORDER:
      return {
        ...state,
        savedOrder: action.data,
      };

    case DELETE_CREATED_ORDER:
      return {
        ...state,
        savedOrder: {},
      };

    case REDIRECT_TO_REGISTRATION:
      return {
        ...state,
        redirectToRegistration: true,
      };

    case CREATE_ORDER_ATTEMPT:
      return {
        ...state,
        createOrderAttempt: true,
        createOrderSuccess: false,
        createOrderError: false,
        createOrderErrorMessage: "",
      };

    case CREATE_ORDER_SUCCESS:
      return {
        ...state,
        createOrderAttempt: false,
        createOrderSuccess: true,
        createOrderError: false,
        createOrderErrorMessage: "",
      };

    case CREATE_ORDER_ERROR:
      return {
        ...state,
        createOrderAttempt: false,
        createOrderSuccess: false,
        createOrderError: true,
        createOrderErrorMessage: action.data,
      };

    case ADMIN_CREATE_ORDER_ATTEMPT:
      return {
        ...state,
        adminCreateOrderAttempt: true,
        adminCreateOrderSuccess: false,
        adminCreateOrderError: false,
        adminCreateOrderErrorMessage: "",
      };

    case ADMIN_CREATE_ORDER_SUCCESS:
      return {
        ...state,
        adminCreateOrderAttempt: false,
        adminCreateOrderSuccess: true,
        adminCreateOrderError: false,
        adminCreateOrderErrorMessage: "",
      };

    case ADMIN_CREATE_ORDER_ERROR:
      return {
        ...state,
        adminCreateOrderAttempt: false,
        adminCreateOrderSuccess: false,
        adminCreateOrderError: true,
        adminCreateOrderErrorMessage: action.data,
      };

    case RESET_CREATE_ORDER:
      return {
        ...state,
        createOrderAttempt: false,
        createOrderSuccess: false,
        createOrderError: false,
        createOrderErrorMessage: "",
      };

    case GET_MY_ORDERS_ATTEMPT:
      return {
        ...state,
        getMyOrdersAttempt: true,
        getMyOrdersSuccess: false,
        getMyOrdersError: false,
        getMyOrdersErrorMessage: "",
      };

    case GET_MY_ORDERS_SUCCESS:
      const renderMyOrders = () => {
        if (action.data.previous === null) {
          return action.data.results;
        } else {
          return [...state.orders, ...action.data.results];
        }
      };
      return {
        ...state,
        getMyOrdersAttempt: false,
        getMyOrdersSuccess: true,
        getMyOrdersError: false,
        getMyOrdersErrorMessage: "",
        orders: action.data.results,
        myOrdersCount: action.data.count,
        myOrdersNext: action.data.next,
        myOrderPrevious: action.data.previous,
      };

    case GET_MY_ORDERS_ERROR:
      return {
        ...state,
        getMyOrdersAttempt: false,
        getMyOrdersSuccess: false,
        getMyOrdersError: true,
        getMyOrdersErrorMessage: action.data,
      };

    case ADMIN_GET_CUSTOMER_ORDERS_ATTEMPT:
      return {
        ...state,
        adminGetCustomerOrdersAttempt: true,
        adminGetCustomerOrdersSuccess: false,
        adminGetCustomerOrdersError: false,
        adminGetCustomerOrdersErrorMessage: "",
      };

    case ADMIN_GET_CUSTOMER_ORDERS_SUCCESS:
      const renderProducts = () => {
        if (action.data.previous === null) {
          return action.data.results;
        } else {
          return [...state.customerOrders, ...action.data.results];
        }
      };
      return {
        ...state,
        adminGetCustomerOrdersAttempt: false,
        adminGetCustomerOrdersSuccess: true,
        adminGetCustomerOrdersError: false,
        adminGetCustomerOrdersErrorMessage: "",
        customerOrders: action.data.results,
        count: action.data.count,
        next: action.data.next,
        previous: action.data.previous,
      };

    case ADMIN_GET_CUSTOMER_ORDERS_ERROR:
      return {
        ...state,
        adminGetCustomerOrdersAttempt: false,
        adminGetCustomerOrdersSuccess: false,
        adminGetCustomerOrdersError: true,
        adminGetCustomerOrdersErrorMessage: action.data,
      };

    case ADMIN_GET_CUSTOMERS_ATTEMPT:
      return {
        ...state,
        adminGetCustomersAttempt: true,
        adminGetCustomersSuccess: false,
        adminGetCustomersError: false,
        adminGetCustomersErrorMessage: "",
      };

    case ADMIN_GET_CUSTOMERS_SUCCESS:
      return {
        ...state,
        adminGetCustomersAttempt: false,
        adminGetCustomersSuccess: true,
        adminGetCustomersError: false,
        adminGetCustomersErrorMessage: "",
        customers: action.data.results,
        customersCount: action.data.count,
        customersNext: action.data.next,
        customersPrevious: action.data.previous,
      };

    case ADMIN_GET_CUSTOMERS_ERROR:
      return {
        ...state,
        adminGetCustomersAttempt: false,
        adminGetCustomersSuccess: false,
        adminGetCustomersError: true,
        adminGetCustomersErrorMessage: action.data,
      };

    case ADMIN_GET_CUSTOMERS_NO_PAGINATION_ATTEMPT:
      return {
        ...state,
        adminGetCustomersNoPaginationAttempt: true,
        adminGetCustomersNoPaginationSuccess: false,
        adminGetCustomersNoPaginationError: false,
        adminGetCustomersNoPaginationErrorMessage: "",
      };

    case ADMIN_GET_CUSTOMERS_NO_PAGINATION_SUCCESS:
      return {
        ...state,
        adminGetCustomersNoPaginationAttempt: false,
        adminGetCustomersNoPaginationSuccess: true,
        adminGetCustomersNoPaginationError: false,
        adminGetCustomersNoPaginationErrorMessage: "",
        customersNoPagination: action.data,
      };

    case ADMIN_GET_CUSTOMERS_NO_PAGINATION_ERROR:
      return {
        ...state,
        adminGetCustomersNoPaginationAttempt: false,
        adminGetCustomersNoPaginationSuccess: false,
        adminGetCustomersNoPaginationError: true,
        adminGetCustomersNoPaginationErrorMessage: action.data,
      };

    case EDIT_ORDER_BILLING_DETAILS_ATTEMPT:
      return {
        ...state,
        editOrderBillingDetailsAttempt: true,
        editOrderBillingDetailsSuccess: false,
        editOrderBillingDetailsError: false,
        editOrderBillingDetailsErrorMessage: "",
      };

    case EDIT_ORDER_BILLING_DETAILS_SUCCESS:
      let editedOrder = state.orders?.map((order) => {
        if (order?.id === action.data?.id) {
          return {
            ...action.data,
          };
        } else {
          return order;
        }
      });

      let editedCustomerOrders = state.customerOrders?.map((order) => {
        if (order?.id === action.data?.id) {
          return {
            ...action.data,
          };
        } else {
          return order;
        }
      });

      return {
        ...state,
        orders: editedOrder,
        customerOrders: editedCustomerOrders,
        editOrderBillingDetailsAttempt: false,
        editOrderBillingDetailsSuccess: true,
        editOrderBillingDetailsError: false,
        editOrderBillingDetailsErrorMessage: "",
      };

    case EDIT_ORDER_BILLING_DETAILS_ERROR:
      return {
        ...state,
        editOrderBillingDetailsAttempt: false,
        editOrderBillingDetailsSuccess: false,
        editOrderBillingDetailsError: true,
        editOrderBillingDetailsErrorMessage: action.data,
      };

    case EDIT_ORDER_DELETE_ITEM_ATTEMPT:
      return {
        ...state,
        editOrderDeleteItemAttempt: true,
        editOrderDeleteItemSuccess: false,
        editOrderDeleteItemError: false,
        editOrderDeleteItemErrorMessage: "",
      };

    case EDIT_ORDER_DELETE_ITEM_SUCCESS:
      return {
        ...state,
        editOrderDeleteItemAttempt: false,
        editOrderDeleteItemSuccess: true,
        editOrderDeleteItemError: false,
        editOrderDeleteItemErrorMessage: "",
      };

    case EDIT_ORDER_DELETE_ITEM_ERROR:
      return {
        ...state,
        editOrderDeleteItemAttempt: false,
        editOrderDeleteItemSuccess: false,
        editOrderDeleteItemError: true,
        editOrderDeleteItemErrorMessage: action.data,
      };

    case EDIT_ORDER_CHANGE_QUANTITY_ATTEMPT:
      return {
        ...state,
        editOrderChangeQuantityAttempt: true,
        editOrderChangeQuantitySuccess: false,
        editOrderChangeQuantityError: false,
        editOrderChangeQuantityErrorMessage: "",
      };

    case EDIT_ORDER_CHANGE_QUANTITY_SUCCESS:
      let orderEdited = state.orders?.map((order) => {
        if (order?.id === action.data?.id) {
          return {
            ...action.data,
          };
        } else {
          return order;
        }
      });

      let edCOrder = state.customerOrders?.map((order) => {
        if (order?.id === action.data?.id) {
          return {
            ...action.data,
          };
        } else {
          return order;
        }
      });

      return {
        ...state,
        orders: orderEdited,
        customerOrders: edCOrder,
        editOrderChangeQuantityAttempt: false,
        editOrderChangeQuantitySuccess: true,
        editOrderChangeQuantityError: false,
        editOrderChangeQuantityErrorMessage: "",
      };

    case EDIT_ORDER_CHANGE_QUANTITY_ERROR:
      return {
        ...state,
        editOrderChangeQuantityAttempt: false,
        editOrderChangeQuantitySuccess: false,
        editOrderChangeQuantityError: true,
        editOrderChangeQuantityErrorMessage: action.data,
      };

    case EDIT_ORDER_ADD_TO_ORDER_ATTEMPT:
      return {
        ...state,
        editOrderAddToOrderAttempt: true,
        editOrderAddToOrderSuccess: false,
        editOrderAddToOrderError: false,
        editOrderAddToOrderErrorMessage: "",
      };

    case EDIT_ORDER_ADD_TO_ORDER_SUCCESS:
      return {
        ...state,
        editOrderAddToOrderAttempt: false,
        editOrderAddToOrderSuccess: true,
        editOrderAddToOrderError: false,
        editOrderAddToOrderErrorMessage: "",
      };

    case EDIT_ORDER_ADD_TO_ORDER_ERROR:
      return {
        ...state,
        editOrderAddToOrderAttempt: false,
        editOrderAddToOrderSuccess: false,
        editOrderAddToOrderError: true,
        editOrderAddToOrderErrorMessage: action.data,
      };

    case REDIRECT_FROM_CREATE_ORDER:
      return {
        ...state,
        redirectFromCreateOrder: true,
      };

    case CLEAR_REDIRECT_FROM_CREATE_ORDER:
      return {
        ...state,
        redirectFromCreateOrder: false,
      };

    case RESET_ORDER_EDITING:
      return {
        ...state,
        editOrderBillingDetailsAttempt: false,
        editOrderBillingDetailsSuccess: false,
        editOrderBillingDetailsError: false,
        editOrderBillingDetailsErrorMessage: "",
        editOrderChangeQuantityAttempt: false,
        editOrderChangeQuantitySuccess: false,
        editOrderChangeQuantityError: false,
        editOrderChangeQuantityErrorMessage: "",
        editOrderDeleteItemAttempt: false,
        editOrderDeleteItemSuccess: false,
        editOrderDeleteItemError: false,
        editOrderDeleteItemErrorMessage: "",
        editOrderAddToOrderAttempt: false,
        editOrderAddToOrderSuccess: false,
        editOrderAddToOrderError: false,
        editOrderAddToOrderErrorMessage: "",
      };

    case EMAIL_LOGOUT_SUCCESS:
      return defaultState;

    default:
      return state;
  }
};
