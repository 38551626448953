import { takeEvery, put, call } from "redux-saga/effects";
import axios from "axios";

import {
  GET_SPECIAL_PRODUCTS_ATTTEMPT,
  getSpecialProductsSuccess,
  getSpecialProductsError,
} from "../actions/products";

import { serverURL } from "../../../env";

// eslint-disable-next-line import/no-anonymous-default-export
export default function* () {
  yield takeEvery(GET_SPECIAL_PRODUCTS_ATTTEMPT, handleGet);
}

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else {
    var err = new Error(response.statusText);
    err.response = response;
    throw err;
  }
}

function getAllProducts(requestURL, options) {
  return axios({
    method: options.method,
    headers: options.headers,
    url: requestURL,
  }).then(checkStatus);
}

function* handleGet(payload) {
  const requestURL = `${serverURL}/products/product_list/?is_deleted=false&special=special`;
  const options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  try {
    const response = yield call(getAllProducts, requestURL, options);

    yield put(getSpecialProductsSuccess(response.data));
  } catch (err) {
    yield put(getSpecialProductsError(err));
  }
}
