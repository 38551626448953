import { takeEvery, put, call, select } from "redux-saga/effects";
import axios from "axios";

import {
  ADMIN_EDIT_CUSTOMER_DATA_ATTEMPT,
  adminEditCustomerDataSuccess,
  adminEditCustomerDataError,
} from "../actions/orders";

import { serverURL } from "../../../env";

// eslint-disable-next-line import/no-anonymous-default-export
export default function* () {
  yield takeEvery(ADMIN_EDIT_CUSTOMER_DATA_ATTEMPT, handleEdit);
}

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else {
    var err = new Error(response.statusText);
    err.response = response;
    throw err;
  }
}

function edit(requestURL, options) {
  return axios({
    method: options.method,
    headers: options.headers,
    url: requestURL,
    data: options.body,
  }).then(checkStatus);
}

function* handleEdit(payload) {
  const user_token = yield select(({ auth }) => auth.authToken);
  const requestURL = `${serverURL}/users/profile/${payload.data.customerId}/`;
  const options = {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: user_token,
    },
    body: payload.data.data_to_server,
  };

  try {
    const response = yield call(edit, requestURL, options);

    yield put(adminEditCustomerDataSuccess(response.data));
  } catch (err) {
    yield put(adminEditCustomerDataError(err));
  }
}
