/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import styles from "./CategoriesScreen.module.css";
import { Container, Table, Button, Form, Row, Col } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { useSelector, useDispatch } from "react-redux";
import {
  editCategoryAttempt,
  getCategoryAttempt,
  resetEditCategory,
  setActiveCategoryTab,
} from "../../../redux/actions/categories";
import { useToasts } from "react-toast-notifications";

const CategoriesScreen = () => {
  const dispatch = useDispatch();
  const { categories, editCategorySuccess, activeTab } = useSelector(
    (state) => state.categories
  );
  const [searchQuery, setSearchQuery] = useState("");
  const { addToast } = useToasts();

  useEffect(() => {
    document.title = "Miraco - Admin Categories";
    window.scrollTo({
      top: 0,
    });
    if (activeTab === "allCategories") {
      dispatch(getCategoryAttempt());
    } else {
      dispatch(getCategoryAttempt({ isDeleted: true }));
    }
  }, [activeTab]);

  useEffect(() => {
    if (editCategorySuccess) {
      addToast(`Category restored succesfully`, {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 800,
        onDismiss: () => {
          dispatch(resetEditCategory());
        },
      });
    }
  }, [editCategorySuccess]);

  const filteredCategories = categories.filter((category) => {
    if (searchQuery === "") {
      return category;
    } else {
      return category.name.toLowerCase().includes(searchQuery);
    }
  });

  const renderTable = () => {
    return (
      <Table hover responsive className="table-sm">
        <thead>
          <tr>
            <th>Category name</th>
            <th>Main category</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {filteredCategories
            ?.sort((a, b) => a.id - b.id)
            ?.map((category) => {
              return (
                <tr key={category.id}>
                  <td>{category.name}</td>
                  <td>
                    {category.parent_category === null
                      ? null
                      : category.parent_category
                      ? category.parent_category.name
                      : null}
                  </td>
                  <td>
                    {category.is_deleted ? (
                      <Button
                        variant="btn btn-sm btn-outline-success rounded"
                        onClick={() => {
                          dispatch(
                            editCategoryAttempt({
                              data_to_server: {
                                is_deleted: false,
                              },
                              id: category.id,
                            })
                          );
                        }}
                      >
                        Restore
                      </Button>
                    ) : (
                      <LinkContainer to={`/admin/category/${category.id}`}>
                        <Button variant="btn btn-sm btn-outline-success rounded">
                          Edit category
                        </Button>
                      </LinkContainer>
                    )}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    );
  };

  return (
    <div className={styles.categoriesScreen}>
      <Container>
        <h3 className={styles.categoriesListHeading}>Manage categories</h3>
        <Tabs
          id="controlled-tab-example"
          activeKey={activeTab}
          onSelect={(k) => dispatch(setActiveCategoryTab(k))}
        >
          <Tab eventKey="allCategories" title="All Categories">
            <Row>
              <Col md={10}>
                <Form.Control
                  className="rounded"
                  type="text"
                  placeholder="Search category"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value.toLowerCase())}
                ></Form.Control>
              </Col>
              <Col md={2}>
                <div className={styles.productListScreen}>
                  <LinkContainer to="/admin/add-category">
                    <Button variant="btn btn-sm btn-outline-success rounded notInTableButton">
                      Add category
                    </Button>
                  </LinkContainer>
                </div>
              </Col>
            </Row>
            {renderTable()}
          </Tab>
          <Tab eventKey="trash" title="Categories in trash">
            {renderTable()}
          </Tab>
        </Tabs>
      </Container>
    </div>
  );
};

export default CategoriesScreen;
