//rute
import OrdersScreen from "../screens/Admin/OrdersScreen/OrdersScreen";
import ProductListScreen from "../screens/Admin/ProductListScreen/ProductListScreen";
import AddProductScreen from "../screens/Admin/AddProductScreen/AddProductScreen";
import EditProductScreen from "../screens/Admin/EditProductScreen/EditProductScreen";
import CategoriesScreen from "../screens/Admin/CategoriesScreen/CategoriesScreen";
import AddCategoryScreen from "../screens/Admin/AddCategoryScreen/AddCategoryScreen";
import EditCategoryScreen from "../screens/Admin/EditCategoryScreen/EditCategoryScreen";
import ManageCustomersScreen from "../screens/Admin/ManageCustomersScreen/ManageCustomersScreen";
import SingleOrderScreenAdmin from "../screens/Admin/SingleOrderAdmin/SingleOrderScreenAdmin";
import OneCustomerOrdersScreen from "../screens/Admin/OneCustomerOrdersScreen/OneCustomerOrdersScreen";
import EditOrderScreen from "../screens/Admin/EditOrderScreen/EditOrderScreen";
import VendorListScreen from "../screens/Admin/VendorsListScreen/VendorListScreen";
import AddVendorScreen from "../screens/Admin/AddVendorScreen/AddVendorScreen";
import EditVendorScreen from "../screens/Admin/EditVendorScreen/EditVendorScreen";
import OneCustomerDetailsScreen from "../screens/Admin/OneCustomerDetailsScreen/OneCustomerDetailsScreen";
import CartScreenAdmin from "../screens/CartScreen/CartScreenAdmin";
import CheckoutAdmin from "../screens/CheckoutScreen/CheckoutAdmin";
import CreateCustomerScreen from "../screens/Admin/CreateCustomerScreen/CreateCustomerScreen";

export const adminRoutes = [
  {
    title: "Orders",
    path: "/admin/orders",
    component: OrdersScreen,
    show: false,
  },
  {
    title: "Products",
    path: "/admin/products",
    component: ProductListScreen,
    show: false,
  },
  {
    title: "Add Product",
    path: "/admin/add-product",
    component: AddProductScreen,
    show: false,
  },
  {
    title: "Edit Product",
    path: "/admin/product/:id",
    component: EditProductScreen,
    show: false,
  },
  {
    title: "Categories",
    path: "/admin/categories",
    component: CategoriesScreen,
    show: false,
  },
  {
    title: "Add Category",
    path: "/admin/add-category",
    component: AddCategoryScreen,
    show: false,
  },
  {
    title: "Edit Category",
    path: "/admin/category/:id",
    component: EditCategoryScreen,
    show: false,
  },
  {
    title: "Customers",
    path: "/admin/customers",
    component: ManageCustomersScreen,
    show: false,
  },
  {
    title: "Order Admin",
    path: "/admin/order/:id",
    component: SingleOrderScreenAdmin,
    show: false,
  },
  {
    title: "Customer Orders Admin",
    path: "/admin/customer-orders/customer/:id",
    component: OneCustomerOrdersScreen,
    show: false,
  },
  {
    title: "Customer Orders Admin",
    path: "/admin/order/edit/:id",
    component: EditOrderScreen,
    show: false,
  },
  {
    title: "Vendors List",
    path: "/admin/vendors/",
    component: VendorListScreen,
    show: false,
  },
  {
    title: "Add Vendor",
    path: "/admin/add-vendor/",
    component: AddVendorScreen,
    show: false,
  },
  {
    title: "Edit Vendor",
    path: "/admin/edit-vendor/:id",
    component: EditVendorScreen,
    show: false,
  },
  {
    title: "Edit Customer",
    path: "/admin/edit-customer/:id",
    component: OneCustomerDetailsScreen,
    show: false,
  },
  {
    title: "Admin Cart",
    path: "/cart-admin",
    component: CartScreenAdmin,
    show: false,
  },
  {
    title: "Admin Checkout",
    path: "/checkout-admin",
    component: CheckoutAdmin,
    show: false,
  },
  {
    title: "Admin Create Customer",
    path: "/admin/create-customer",
    component: CreateCustomerScreen,
    show: false,
  },
];
