/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Form, Row, Col, Container, Button } from "react-bootstrap";
import styles from "./OneCustomerDetailsScreen.module.css";
import { useDispatch, useSelector } from "react-redux";
import { adminEditCustomersAttempt, adminEditCustomerDataAttempt, resetAdminEditCustomers, resetAdminEditCustomerData, adminDeleteCustomerAttempt, resetAdminDeleteCustomer } from "../../../redux/actions/orders";
import Loader from "../../../components/Loader/Loader";
import { useParams } from "react-router-dom";
import Select from "react-select";
import { useToasts } from "react-toast-notifications";
import history from '../../../utils/history';

const OneCustomerDetailsScreen = () => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { id } = useParams();
  const {
    customers,
    adminEditCustomersAttempt: verifyCustomerAttempt,
    adminDeleteCustomerAttempt: deleteCustomerAttempt,
    adminEditCustomerDataAttempt: editCustomerDataAttempt,
    adminEditCustomersSuccess,
    adminEditCustomerDataSuccess,
    adminDeleteCustomerSuccess
  } = useSelector((state) => state.orders);

  const oneCustomer = customers.find((customer) => customer.id === Number(id));

  const [profileState, setProfileState] = useState({
    username: "",
    phone_number: "",
    first_name: "",
    last_name: "",
    country: "",
    address: "",
    townCity: "",
    state: "",
    zipcode: "",
    verified: "",
    company: "",
    email: "",
  });

  useEffect(() => {
    if (oneCustomer) {
      setProfileState({
        username: oneCustomer?.username,
        phone_number: oneCustomer?.phone_number,
        first_name: oneCustomer?.first_name,
        last_name: oneCustomer?.last_name,
        country: oneCustomer?.country,
        address: oneCustomer?.address,
        townCity: oneCustomer?.townCity,
        state: oneCustomer?.state,
        zipcode: oneCustomer?.zipcode,
        email: oneCustomer?.email,
        verified: {
          label: oneCustomer?.verified ? "Verified" : "Not verified",
          value: oneCustomer?.verified,
        },
        company: oneCustomer?.company,
      });
    }
  }, [oneCustomer]);

  useEffect(() => {
    if (adminEditCustomersSuccess) {
      addToast(`Customer updated succesfully`, {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 2000,
        onDismiss: () => {
          dispatch(resetAdminEditCustomers());
        },
      });
    }
  }, [adminEditCustomersSuccess]);

  useEffect(() => {
    if (adminEditCustomerDataSuccess) {
      addToast(`Customer updated succesfully`, {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 2000,
        onDismiss: () => {
          dispatch(resetAdminEditCustomerData());
        },
      });
    }
  }, [adminEditCustomerDataSuccess]);

useEffect(() => {
    if (adminDeleteCustomerSuccess) {
      addToast(`Customer deleted succesfully`, {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 2000,
        onDismiss: () => {
          dispatch(resetAdminDeleteCustomer());
          history.push('/admin/customers')
        },
      });
    }
  }, [adminDeleteCustomerSuccess]);

  useEffect(() => {
    document.title = "Miraco - Customer profile";
    window.scrollTo({
      top: 0,
    });
  }, []);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      adminEditCustomersAttempt({
        data_to_server: {
          verified: profileState.verified?.value,
        },
        customerId: id,
      })
    );
  };

  const submitHandler2 = (e) => {
    e.preventDefault();
    dispatch(
      adminEditCustomerDataAttempt({
        data_to_server: {
          email: profileState.email,
          username: profileState.email,
          phone_number: profileState.phone_number,
          first_name: profileState.first_name,
          last_name: profileState.last_name,
          country: profileState.country,
          address: profileState.address,
          townCity: profileState.townCity,
          state: profileState.state,
          zipcode: profileState.zipcode,
          company: profileState.company,
        },
        customerId: id,
      })
    );
  };

  const deleteHandler = (e) => {
    e.preventDefault();
    dispatch(
      adminDeleteCustomerAttempt({
        userId: id,
      })
    );
  };

  const handleChange = (e) => {
    setProfileState({
      ...profileState,
      [e.target.name]: e.target.value,
    });
  };

  const renderVerifiedPicker = () => {
    const options = [
      {
        label: "Verified",
        value: true,
      },
      { label: "Not verified", value: false },
    ];

    const style = {
      control: (base) => ({
        ...base,
        border: 0,
        // This line disable the blue border
        boxShadow: "none",
      }),
    };

    return (
      <>
        <Form.Label>Select verified status</Form.Label>
        <Select
          value={profileState.verified}
          onChange={(selectedOption) =>
            setProfileState({ ...profileState, verified: selectedOption })
          }
          options={options}
          placeholder="Select verified status"
          className="abc"
          classNamePrefix="react-select"
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary25: "rgba(27, 154, 62, 0.2)",
              primary: "rgba(27, 154, 62, 0.4)",
              primary50: "#DEEBF4",
            },
          })}
          styles={style}
        />
      </>
    );
  };

  return (
    <div className={styles.updateProfilePage}>
      <Container>
        <Row className="justify-content-md-center">
          <Col xs={12} md={6}>
            <Form onSubmit={submitHandler} className={styles.loginForm}>
              <h3 className={styles.signInText}>Verify customer</h3>
              <Form.Group controlId="verified">
                {renderVerifiedPicker()}
              </Form.Group>
              <Button
                type="submit"
                variant="btn btn-sm btn-outline-success rounded notInTableButton"
              >
                {verifyCustomerAttempt ? <Loader /> : "Confirm changes"}
              </Button>
            </Form>
            <hr />
            <Form onSubmit={submitHandler2} className={styles.loginForm}>
              <h3 className={styles.signInText}>Edit customer details</h3>
              <Form.Group controlId="company">
                <Form.Label>Company</Form.Label>
                <Form.Control
                  className={styles.myInput}
                  type="text"
                  name="company"
                  placeholder="Enter customer company name"
                  value={profileState.company}
                  onChange={handleChange}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId="email">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  className={styles.myInput}
                  type="email"
                  name="email"
                  placeholder="Enter email"
                  value={profileState.email}
                  onChange={handleChange}
                  disabled
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId="password">
                <Form.Label>Phone number</Form.Label>
                <Form.Control
                  className={styles.myInput}
                  type="text"
                  name="phone_number"
                  placeholder="Enter phone number"
                  value={profileState.phone_number}
                  onChange={handleChange}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId="password">
                <Form.Label>First name</Form.Label>
                <Form.Control
                  className={styles.myInput}
                  type="text"
                  name="first_name"
                  placeholder="Enter first name"
                  value={profileState.first_name}
                  onChange={handleChange}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId="password">
                <Form.Label>Last name</Form.Label>
                <Form.Control
                  className={styles.myInput}
                  type="text"
                  name="last_name"
                  placeholder="Enter last name"
                  value={profileState.last_name}
                  onChange={handleChange}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId="password">
                <Form.Label>Customer country</Form.Label>
                <Form.Control
                  className={styles.myInput}
                  type="text"
                  name="country"
                  placeholder="Enter country"
                  value={profileState.country}
                  onChange={handleChange}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId="password">
                <Form.Label>Address</Form.Label>
                <Form.Control
                  className={styles.myInput}
                  type="text"
                  name="address"
                  placeholder="Enter customer address"
                  value={profileState.address}
                  onChange={handleChange}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId="password">
                <Form.Label>Town/City</Form.Label>
                <Form.Control
                  className={styles.myInput}
                  type="text"
                  name="townCity"
                  placeholder="Enter customer town/city"
                  value={profileState.townCity}
                  onChange={handleChange}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId="password">
                <Form.Label>State</Form.Label>
                <Form.Control
                  className={styles.myInput}
                  type="text"
                  name="state"
                  placeholder="Enter customer state"
                  value={profileState.state}
                  onChange={handleChange}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId="password">
                <Form.Label>Zip</Form.Label>
                <Form.Control
                  className={styles.myInput}
                  type="text"
                  name="zipcode"
                  placeholder="Enter your zip"
                  value={profileState.zipcode}
                  onChange={handleChange}
                ></Form.Control>
              </Form.Group>
              <Button
                type="submit"
                variant="btn btn-sm btn-outline-success rounded notInTableButton"
              >
                {editCustomerDataAttempt ? <Loader /> : "Confirm changes"}
              </Button>
            </Form>
            <hr />
            <Form onSubmit={deleteHandler} className={styles.loginForm}>
              <h3 className={styles.signInText}>Delete customer</h3>
              <Button
                type="submit"
                variant="btn btn-sm btn-outline-danger rounded notInTableButton"
              >
                {deleteCustomerAttempt ? <Loader /> : "Delete customer"}
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default OneCustomerDetailsScreen;
