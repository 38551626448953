import Pagination from "react-bootstrap/Pagination";
// import Select from "react-select";
// import Form from "react-bootstrap/Form";

const PaginationComponent = ({
  paginate,
  nextPage,
  prevPage,
  currentPage,
  totalPageNumber,
  visibilityAllPageNumbers,
  changeVisibilityAllPageNumbers,
  // selectedPageToJump,
  // setSelectedPageToJump,
}) => {
  const pageNumbers = [];

  for (let i = 1; i <= totalPageNumber; i++) {
    pageNumbers.push(i);
  }

  // const renderJumpToPageSelect = () => {
  //   const style = {
  //     control: (base) => ({
  //       ...base,
  //       border: 0,
  //       // This line disable the blue border
  //       boxShadow: "none",
  //     }),
  //   };

  //   const pagesToJumpArray = Array.from(
  //     { length: totalPageNumber },
  //     (_, i) => i + 1
  //   ).map((item) => {
  //     return {
  //       value: item,
  //       label: item,
  //     };
  //   });

  //   return (
  //     <div
  //       style={{
  //         display: "flex",
  //         marginLeft: "40px",
  //         alignItems: "center",
  //       }}
  //     >
  //       <Form.Label className="mr-2">Jump to page</Form.Label>
  //       <Select
  //         value={selectedPageToJump}
  //         onChange={(selectedOption) => {
  //           setSelectedPageToJump(selectedOption);
  //           paginate(selectedOption?.value);
  //         }}
  //         options={pagesToJumpArray}
  //         placeholder="Select page"
  //         className="abcsd"
  //         classNamePrefix="react-select"
  //         theme={(theme) => ({
  //           ...theme,
  //           borderRadius: 0,
  //           colors: {
  //             ...theme.colors,
  //             primary25: "rgba(27, 154, 62, 0.2)",
  //             primary: "rgba(27, 154, 62, 0.4)",
  //             primary50: "#DEEBF4",
  //           },
  //         })}
  //         styles={style}
  //       />
  //     </div>
  //   );
  // };

  const renderPaginatoin = () => {
    if (totalPageNumber > 9) {
      return (
        <>
          <Pagination>
            <Pagination.First
              style={{ visibility: currentPage > 1 ? "visible" : "hidden" }}
              onClick={() => paginate(1)}
            />
            <Pagination.Prev
              style={{ visibility: currentPage > 1 ? "visible" : "hidden" }}
              onClick={() => prevPage()}
            />
            <Pagination.Item
              onClick={() => paginate(1)}
              active={currentPage === 1}
            >
              {1}
            </Pagination.Item>

            {currentPage >= 5 && (
              <Pagination.Ellipsis
                onClick={() => changeVisibilityAllPageNumbers(true)}
              />
            )}

            {currentPage > 3 && (
              <Pagination.Item onClick={() => paginate(currentPage - 2)}>
                {currentPage - 2}
              </Pagination.Item>
            )}
            {currentPage > 2 && (
              <Pagination.Item onClick={() => paginate(currentPage - 1)}>
                {currentPage - 1}
              </Pagination.Item>
            )}

            {currentPage !== 1 && currentPage !== totalPageNumber && (
              <Pagination.Item onClick={() => paginate(currentPage)} active>
                {currentPage}
              </Pagination.Item>
            )}

            {currentPage < totalPageNumber - 2 && (
              <Pagination.Item onClick={() => paginate(currentPage + 1)}>
                {currentPage + 1}
              </Pagination.Item>
            )}

            {currentPage < totalPageNumber - 1 &&
              currentPage + 2 !== totalPageNumber && (
                <Pagination.Item onClick={() => paginate(currentPage + 2)}>
                  {currentPage + 2}
                </Pagination.Item>
              )}

            {currentPage + 2 === totalPageNumber && (
              <Pagination.Item onClick={() => paginate(currentPage + 1)}>
                {currentPage + 1}
              </Pagination.Item>
            )}

            {currentPage === totalPageNumber - 4 && (
              <Pagination.Item onClick={() => paginate(currentPage + 3)}>
                {currentPage + 3}
              </Pagination.Item>
            )}

            {currentPage < totalPageNumber - 4 && (
              <Pagination.Ellipsis
                onClick={() => changeVisibilityAllPageNumbers(true)}
              />
            )}

            <Pagination.Item
              onClick={() => paginate(totalPageNumber)}
              active={currentPage === totalPageNumber}
            >
              {totalPageNumber}
            </Pagination.Item>
            <Pagination.Next
              style={{
                visibility:
                  currentPage < totalPageNumber ? "visible" : "hidden",
              }}
              onClick={() => nextPage()}
            />
            <Pagination.Last
              style={{
                visibility:
                  currentPage < totalPageNumber ? "visible" : "hidden",
              }}
              onClick={() => paginate(totalPageNumber)}
            />
          </Pagination>
          {/* {renderJumpToPageSelect()} */}
        </>
      );
    } else {
      return (
        <>
          <Pagination>
            <Pagination.First
              style={{ visibility: currentPage > 1 ? "visible" : "hidden" }}
              onClick={() => paginate(1)}
            />
            <Pagination.Prev
              style={{ visibility: currentPage > 1 ? "visible" : "hidden" }}
              onClick={() => prevPage()}
            />

            {pageNumbers.map((num) => {
              return (
                <Pagination.Item
                  key={num}
                  active={currentPage === num}
                  onClick={() => paginate(num)}
                >
                  {num}
                </Pagination.Item>
              );
            })}
            <Pagination.Next
              style={{
                visibility:
                  currentPage < totalPageNumber ? "visible" : "hidden",
              }}
              onClick={() => nextPage()}
            />
            <Pagination.Last
              style={{
                visibility:
                  currentPage < totalPageNumber ? "visible" : "hidden",
              }}
              onClick={() => paginate(totalPageNumber)}
            />
          </Pagination>
        </>
      );
    }
  };

  const renderAllPageNumbers = () => {
    return (
      <div className="d-flex flex-wrap">
        {pageNumbers.map((num) => {
          return (
            <Pagination>
              <Pagination.Item
                key={num}
                onClick={() => {
                  paginate(num);
                  changeVisibilityAllPageNumbers(false);
                }}
                active={currentPage === num}
              >
                {num}
              </Pagination.Item>
            </Pagination>
          );
        })}
      </div>
    );
  };

  return visibilityAllPageNumbers ? renderAllPageNumbers() : renderPaginatoin();
};

export default PaginationComponent;
