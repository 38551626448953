import Slider from "react-slick";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styles from "./Carousel.module.css";
import { Link } from "react-router-dom";
import banner001 from "../../../assets/images/banner001.png";
// import banner002 from "../../../assets/images/banner002.png";
import banner003 from "../../../assets/images/banner003.png";
import banner004 from "../../../assets/images/banner004.png";
import banner005 from "../../../assets/images/banner005.png";
import banner006 from "../../../assets/images/banner006.png";
import useWindowDimensions from "../../utils/useWindowDimensions";

function ControlledCarousel() {
  const { width } = useWindowDimensions();

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplaySpeed: 2500,
    slidesToShow: 1,
    autoplay: true,
    arrows: false,
  };

  const renderSlider1Text =
    width < 991 ? (
      <>
        We carry different lines of product <br /> such as Frozen food, Cookies,
        Sausages, <br />
        Vegetable Spreads, Jams and many more...
      </>
    ) : (
      <>
        We carry different lines of product such as Frozen food,
        <br />
        Cookies, Sausages, Vegetable Spreads, Jams and many more...
      </>
    );

  const renderSlider3Text =
    width < 991 ? (
      <>
        We are not only supplying retail stores,
        <br /> but we also supply other distributors <br /> and retail chains
        all around Canada.
      </>
    ) : (
      <>
        {" "}
        We are not only supplying retail stores, but we also supply
        <br />
        other distributors and retail chains all around Canada.
      </>
    );

  return (
    <Slider {...settings} pauseOnHover={false}>
      <div>
        <Row>
          <Col md={6} sm={12}>
            <div className={styles.sliderCatchPhraseDiv}>
              <div className={styles.smalAndBigHeading}>
                <p className={styles.smallHeading}>
                  Wholesale Quality European Food
                </p>
                <h1 className={styles.bigHeading}>
                  MIRACO EURO <br /> FOOD LTD.
                </h1>
                <p className={styles.textAfter}>{renderSlider1Text}</p>
                <div className={styles.buttonPart}>
                  <Link
                    to="/products"
                    className="btn btn-outline-success w-50 mt-0 rounded notInTableButton"
                  >
                    Start shopping
                  </Link>
                </div>
              </div>
            </div>
          </Col>
          <Col md={6} sm={12}>
            <img className="img-fluid" src={banner004} alt="First slide" />
          </Col>
        </Row>
      </div>
      <div>
        <Row>
          <Col md={6} sm={12}>
            <div className={styles.sliderCatchPhraseDiv}>
              <div className={styles.smalAndBigHeading}>
                <p className={styles.smallHeading}>
                  Wholesale Quality European Food
                </p>
                <h1 className={styles.bigHeading}>
                  FAST DELIVERY <br />
                  AND CUSTOMER CARE
                </h1>
                <p className={styles.textAfter}>
                  We offer very good service, either we deliver <br /> locally
                  or we ship to other cities and provinces.
                </p>
                <div className={styles.buttonPart}>
                  <Link
                    to="/products"
                    className="btn btn-outline-success w-50 mt-0 rounded notInTableButton"
                  >
                    Start shopping
                  </Link>
                </div>
              </div>
            </div>
          </Col>
          <Col md={6} sm={12}>
            <img
              className="img-fluid"
              // src="https://nonprofitssource.com/wp-content/uploads/2018/11/Vector-Smart-Object.png"
              // src="https://www.pngitem.com/pimgs/m/241-2413601_groceries-transparent-images-png-groceries-png-png-download.png"
              src={banner001}
              alt="First slide"
            />
          </Col>
        </Row>
      </div>
      <div>
        <Row>
          <Col md={6} sm={12}>
            <div className={styles.sliderCatchPhraseDiv}>
              <div className={styles.smalAndBigHeading}>
                <p className={styles.smallHeading}>
                  Wholesale Quality European Food
                </p>
                <h1 className={styles.bigHeading}>
                  MIRACO EURO <br /> FOOD LTD.
                </h1>
                <p className={styles.textAfter}>{renderSlider1Text}</p>
                <div className={styles.buttonPart}>
                  <Link
                    to="/products"
                    className="btn btn-outline-success w-50 mt-0 rounded notInTableButton"
                  >
                    Start shopping
                  </Link>
                </div>
              </div>
            </div>
          </Col>
          <Col md={6} sm={12}>
            <img className="img-fluid" src={banner003} alt="First slide" />
          </Col>
        </Row>
      </div>
      <div>
        <Row>
          <Col md={6} sm={12}>
            <div className={styles.sliderCatchPhraseDiv}>
              <div className={styles.smalAndBigHeading}>
                <p className={styles.smallHeading}>
                  Wholesale Quality European Food
                </p>
                <h1 className={styles.bigHeading}>
                  DISTRIBUTING <br /> OVER 1000 DIFERENT
                  <br />
                  PRODUCTS
                </h1>
                <p className={styles.textAfter}>{renderSlider3Text}</p>
                <div className={styles.buttonPart}>
                  <Link
                    to="/products"
                    className="btn btn-outline-success w-50 mt-0 rounded notInTableButton"
                  >
                    Start shopping
                  </Link>
                </div>
              </div>
            </div>
          </Col>
          <Col md={6} sm={12}>
            <img className="img-fluid" src={banner005} alt="First slide" />
          </Col>
        </Row>
      </div>
      <div>
        <Row>
          <Col md={6} sm={12}>
            <div className={styles.sliderCatchPhraseDiv}>
              <div className={styles.smalAndBigHeading}>
                <p className={styles.smallHeading}>
                  Wholesale Quality European Food
                </p>
                <h1 className={styles.bigHeading}>
                  FAST DELIVERY <br />
                  AND CUSTOMER CARE
                </h1>
                <p className={styles.textAfter}>
                  We offer very good service, either we deliver <br /> locally
                  or we ship to other cities and provinces.
                </p>
                <div className={styles.buttonPart}>
                  <Link
                    to="/products"
                    className="btn btn-outline-success w-50 mt-0 rounded notInTableButton"
                  >
                    Start shopping
                  </Link>
                </div>
              </div>
            </div>
          </Col>
          <Col md={6} sm={12}>
            <img
              className="img-fluid"
              // src="https://nonprofitssource.com/wp-content/uploads/2018/11/Vector-Smart-Object.png"
              // src="https://www.pngitem.com/pimgs/m/241-2413601_groceries-transparent-images-png-groceries-png-png-download.png"
              src={banner006}
              alt="First slide"
            />
          </Col>
        </Row>
      </div>
    </Slider>
  );
}

export default ControlledCarousel;
