import React from "react";
import logo from "../../../assets/images/logo.png";
import styles from "./Footer.module.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { IoLocationSharp, IoCallSharp } from "react-icons/io5";
import { IoMdMail } from "react-icons/io";
import { FaFax } from "react-icons/fa";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer>
      <Container>
        <Row>
          <Col>
            <div className={styles.footerContent}>
              <Link to="/">
                <img className="site-logo" alt="site-logo" src={logo} />
              </Link>
              <div className={styles.footerTexts}>
                <div className={styles.oneFuterItem}>
                  <p className={styles.oneFuterItemText}>
                    <span className={styles.oneFuterItemIcon}>
                      <IoLocationSharp />
                    </span>
                    6464 Beresford street, Burnaby BC
                  </p>
                </div>
                <div className={styles.oneFuterItem}>
                  <p className={styles.oneFuterItemText}>
                    <span className={styles.oneFuterItemIcon}>
                      <IoMdMail />
                    </span>
                    info@miracoeurofood.com
                  </p>
                </div>
                <div className={styles.oneFuterItem}>
                  <p className={styles.oneFuterItemText}>
                    <span className={styles.oneFuterItemIcon}>
                      <IoCallSharp />
                    </span>
                    +1 604 568 0060
                  </p>
                </div>
                <div className={styles.oneFuterItem}>
                  <p className={styles.oneFuterItemText}>
                    <span className={styles.oneFuterItemIcon}>
                      <FaFax />
                    </span>
                    +1 604 568 0488
                  </p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <div className={styles.copyrightBar}>
        <p className={styles.copyrightText}>
          &copy; {new Date().getFullYear()} Miraco Euro Food LTD. - Wholesale
          Quality European Food
        </p>
      </div>
    </footer>
  );
}

export default Footer;
