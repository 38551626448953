/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import styles from "./CartScreen.module.css";
import QuantityInput from "./QuantityInput";
import { AiOutlineClose } from "react-icons/ai";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { removeFromCart } from "../../redux/actions/cart";
import {
  saveCreatedOrder,
  redirectToRegistration,
} from "../../redux/actions/orders";
import history from "../../utils/history";
import "./buttons.css";
import { billingCanBeSubmited } from "../../utils/validate";
import emptyCart from "../../../assets/images/empty-cart.svg";
import placeholder from "../../../assets/images/placeholder.png";
import { useNetwork } from "../../utils/checkInternet";

function CartScreen() {
  const isConnected = useNetwork();

  useEffect(() => {
    document.title = "Miraco - Cart";
  }, []);

  const dispatch = useDispatch();
  const { authToken } = useSelector((state) => state.auth);
  const { savedOrder } = useSelector((state) => state.orders);
  const { cartProducts, totalPrice } = useSelector((state) => state.cart);

  const {
    phone_number,
    first_name,
    last_name,
    email,
    address,
    townCity,
    state,
    zipcode,
  } = useSelector((state) => state.profile);

  const [checkoutState, setCheckoutState] = useState({
    companyName: "",
    country: "Canada",
    address: "",
    townCity: "",
    state: "",
    zip: "",
    email: "",
    phone: "",
    orderNotes: "",
  });

  useEffect(() => {
    if (savedOrder?.billing?.address) {
      setCheckoutState({
        companyName: savedOrder?.billing?.companyName,
        country: "Canada",
        address: savedOrder?.billing?.address,
        townCity: savedOrder?.billing?.townCity,
        state: savedOrder?.billing?.state,
        zip: savedOrder?.billing?.zip,
        email: savedOrder?.billing?.email,
        phone: savedOrder?.billing?.phone,
        orderNotes: savedOrder?.billing?.orderNotes,
      });
    } else {
      setCheckoutState({
        companyName: "",
        country: "Canada",
        email,
        phone: phone_number,
        first_name,
        last_name,
        address,
        townCity,
        state,
        zip: zipcode,
        orderNotes: "",
      });
    }
  }, []);

  const isDisabled =
    !billingCanBeSubmited(
      checkoutState?.country,
      checkoutState?.address,
      checkoutState?.townCity,
      checkoutState?.state,
      checkoutState?.zip,
      checkoutState?.email,
      checkoutState?.phone
    ) || !isConnected;

  const renderCartItems = () => {
    if (cartProducts.length === 0) {
      return null;
    } else {
      return (
        <>
          <div className={styles.heading}>
            <div className={styles.heading_flag}></div>

            <div className={styles.heading_name}>
              <div>
                <p>Product</p>
              </div>
            </div>

            <div className={styles.heading_population}>
              <div>
                <p>Price</p>
              </div>
            </div>

            <div className={styles.heading_gini}>
              <div>Taxable</div>
            </div>

            <div className={styles.heading_population}>
              <div>
                <p className="text-center">Quantity</p>
              </div>
            </div>

            <div className={styles.heading_gini}>
              {/* <div>Remove from cart</div> */}
            </div>
          </div>

          {cartProducts.map((product, index) => (
            <React.Fragment key={product.id}>
              {index === 0 ? <hr /> : null}
              <div className={styles.row}>
                <div className={styles.flag}>
                  <img
                    src={product.image ? product.image : placeholder}
                    alt={product.image}
                    className="img-fluid"
                  />
                </div>

                <div className={styles.name}>
                  <p>
                    {product.name}{" "}
                    <span style={{ fontWeight: "bold", color: "#1b9a3e" }}>
                      {product?.quantity > 9 && product?.actions === "10-1"
                        ? "+1 GRATIS"
                        : null}
                    </span>
                  </p>
                </div>

                <div className={styles.population}>
                  {authToken ? (
                    <p>
                      {" "}
                      {"CAD "}
                      {(
                        product?.price -
                        product?.price * (product?.special / 100)
                      ).toFixed(2)}{" "}
                    </p>
                  ) : (
                    <p>Login to see price</p>
                  )}
                </div>

                <div className={styles.gini}>
                  {product?.sales_tax_code === "G" ? "Yes" : "No"}
                </div>

                <div className={styles.population}>
                  <div className="quantity buttons_added">
                    <QuantityInput foundedProducts={product} />
                  </div>
                </div>

                <div className={styles.gini}>
                  <span
                    className={styles.removeFromCartIcon}
                    onClick={() => dispatch(removeFromCart(product))}
                  >
                    <AiOutlineClose />
                  </span>
                </div>
              </div>
              <hr />
            </React.Fragment>
          ))}
        </>
      );
    }
  };

  const renderBillingForm = () => {
    let grandTotalPrice = cartProducts
      .map((item) => {
        let pom_price = item?.special
          ? item?.price - item?.price * (item.special / 100)
          : item?.price;

        return item?.sales_tax_code === "G"
          ? pom_price * item?.quantity + pom_price * item?.quantity * 0.05
          : pom_price * item?.quantity;
      })
      .reduce((acc, next) => acc + next, 0);

    return (
      <Form className={styles.billingForm}>
        <h5 className={styles.billingHeading}>Billing Details</h5>
        <Form.Row>
          <Form.Group md={6} as={Col} sm={12} controlId="formGridCompanyName">
            <Form.Label>Company name (optional)</Form.Label>
            <Form.Control
              className={styles.myInput}
              placeholder="Company name"
              value={checkoutState.companyName}
              onChange={(e) =>
                setCheckoutState({
                  ...checkoutState,
                  companyName: e.target.value,
                })
              }
            />
          </Form.Group>

          <Form.Group md={6} as={Col} sm={12} controlId="formGridCountry">
            <Form.Label>Country</Form.Label>
            <Form.Control
              className={styles.myInput}
              type="text"
              placeholder="Country"
              value={checkoutState.country}
              disabled
            />
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col} sm={12} controlId="formGridAddress">
            <Form.Label>Address</Form.Label>
            <Form.Control
              className={styles.myInput}
              placeholder="1234 Main St"
              value={checkoutState.address}
              onChange={(e) =>
                setCheckoutState({ ...checkoutState, address: e.target.value })
              }
            />
          </Form.Group>
          <Form.Group as={Col} sm={12} controlId="formGridCity">
            <Form.Label>Town / City</Form.Label>
            <Form.Control
              className={styles.myInput}
              placeholder="Town / City"
              value={checkoutState.townCity}
              onChange={(e) =>
                setCheckoutState({ ...checkoutState, townCity: e.target.value })
              }
            />
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group md={6} as={Col} sm={12} controlId="formGridState">
            <Form.Label>State</Form.Label>
            <Form.Control
              className={styles.myInput}
              placeholder="State"
              value={checkoutState.state}
              onChange={(e) =>
                setCheckoutState({ ...checkoutState, state: e.target.value })
              }
            />
          </Form.Group>

          <Form.Group md={6} as={Col} sm={12} controlId="formGridZip">
            <Form.Label>Zip</Form.Label>
            <Form.Control
              className={styles.myInput}
              placeholder="ZIP"
              value={checkoutState.zip}
              onChange={(e) =>
                setCheckoutState({
                  ...checkoutState,
                  zip: e.target.value.replace(/\D/g, ""),
                })
              }
            />
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col} sm={12} controlId="formGridEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control
              className={styles.myInput}
              type="email"
              placeholder="Email"
              value={checkoutState.email}
              onChange={(e) =>
                setCheckoutState({ ...checkoutState, email: e.target.value })
              }
            />
          </Form.Group>

          <Form.Group as={Col} sm={12} controlId="formGridPhone">
            <Form.Label>Phone</Form.Label>
            <Form.Control
              className={styles.myInput}
              type="text"
              placeholder="Phone"
              value={checkoutState.phone}
              onChange={(e) =>
                setCheckoutState({
                  ...checkoutState,
                  phone: e.target.value.replace(/\D/g, ""),
                })
              }
            />
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col} sm={12} controlId="formTextArea">
            <Form.Label>Order notes (optional)</Form.Label>
            <Form.Control
              className={styles.myInput}
              as="textarea"
              rows={3}
              placeholder="Notes about your order, e.g. special notes for delivery."
              value={checkoutState.orderNotes}
              onChange={(e) =>
                setCheckoutState({
                  ...checkoutState,
                  orderNotes: e.target.value,
                })
              }
            />
          </Form.Group>
        </Form.Row>

        <Button
          variant="btn btn-outline-success rounded w-100 notInTableButton"
          disabled={isDisabled}
          onClick={() => {
            if (!authToken) {
              dispatch(redirectToRegistration());
            }
            history.push("/checkout");
            dispatch(
              saveCreatedOrder({
                products: cartProducts,
                billing: checkoutState,
                orderPrice: totalPrice,
                tax: grandTotalPrice - parseFloat(totalPrice?.toFixed(2)),
                grandTotal: grandTotalPrice,
              })
            );
          }}
        >
          Review and pay
        </Button>
      </Form>
    );
  };

  const renderPrice = () => {
    // let grandTotalPrice = cartProducts
    //   .map((item) =>
    //     item?.sales_tax_code === "G"
    //       ? item?.price * item?.quantity + item?.price * item?.quantity * 0.05
    //       : item?.price * item?.quantity
    //   )
    //   .reduce((acc, next) => acc + next, 0);

    return (
      <>
        <h5 className={styles.totalPriceHeading}>
          Estimated total {totalPrice?.toFixed(2)}
        </h5>
        {/* <h5 className={styles.totalPriceHeading}>
          Tax{" "}
          {(grandTotalPrice - parseFloat(totalPrice?.toFixed(2)))?.toFixed(2)}
        </h5>
        <h5 className={styles.totalPriceHeading}>
          Grand Total: CA$ {grandTotalPrice?.toFixed(2)}
        </h5> */}
      </>
    );
  };

  return (
    <div className={styles.checkoutScreen}>
      <Container>
        <Row>
          {cartProducts.length > 0 ? (
            <>
              <Col md={7} sm={12}>
                <div className={styles.cartContainer}>
                  <h4 className={styles.shoppingCartHeading}>Shopping Cart</h4>
                  {renderCartItems()}
                  {cartProducts.length > 0 && authToken ? renderPrice() : null}
                </div>
              </Col>
              <Col>
                <div className={styles.billingFormContainer}>
                  {renderBillingForm()}
                </div>
              </Col>
            </>
          ) : (
            <div className={styles.noProductsMessageDiv}>
              <img src={emptyCart} alt="cart-empty" />
              <h4 className={styles.noItemsInCartText}>
                You don't have products <br /> in cart.
              </h4>
              <h5 className={styles.noItemsInCartText}>Add some products</h5>
              <Link
                className="btn btn-outline-success mt-2 rounded notInTableButton"
                to="/products"
              >
                Shop now
              </Link>
            </div>
          )}
        </Row>
      </Container>
    </div>
  );
}

export default CartScreen;
