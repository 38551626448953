export const ADMIN_CREATE_ORDER_ATTEMPT = "ADMIN_CREATE_ORDER_ATTEMPT";
export const ADMIN_CREATE_ORDER_SUCCESS = "ADMIN_CREATE_ORDER_SUCCESS";
export const ADMIN_CREATE_ORDER_ERROR = "ADMIN_CREATE_ORDER_ERROR";

export const adminCreateOrderAttempt = (data) => ({
  type: ADMIN_CREATE_ORDER_ATTEMPT,
  data,
});

export const adminCreateOrderSuccess = (data) => ({
  type: ADMIN_CREATE_ORDER_SUCCESS,
  data,
});

export const adminCreateOrderError = (data) => ({
  type: ADMIN_CREATE_ORDER_ERROR,
  data,
});

export const SAVE_CREATED_ORDER = "SAVE_CREATED_ORDER";
export const DELETE_CREATED_ORDER = "DELETE_CREATED_ORDER";
export const REDIRECT_TO_REGISTRATION = "REDIRECT_TO_REGISTRATION";

export const saveCreatedOrder = (data) => ({
  type: SAVE_CREATED_ORDER,
  data,
});

export const deleteCreatedOrder = () => ({
  type: DELETE_CREATED_ORDER,
});

export const redirectToRegistration = () => ({
  type: REDIRECT_TO_REGISTRATION,
});

export const CREATE_ORDER_ATTEMPT = "CREATE_ORDER_ATTEMPT";
export const CREATE_ORDER_SUCCESS = "CREATE_ORDER_SUCCESS";
export const CREATE_ORDER_ERROR = "CREATE_ORDER_ERROR";

export const createOrderAttempt = (data) => ({
  type: CREATE_ORDER_ATTEMPT,
  data,
});

export const createOrderSuccess = (data) => ({
  type: CREATE_ORDER_SUCCESS,
  data,
});

export const createOrderError = (data) => ({
  type: CREATE_ORDER_ERROR,
  data,
});

export const RESET_CREATE_ORDER = "RESET_CREATE_ORDER";

export const resetCreateOrder = (data) => ({
  type: RESET_CREATE_ORDER,
  data,
});

export const GET_MY_ORDERS_ATTEMPT = "GET_MY_ORDERS_ATTEMPT";
export const GET_MY_ORDERS_SUCCESS = "GET_MY_ORDERS_SUCCESS";
export const GET_MY_ORDERS_ERROR = "GET_MY_ORDERS_ERROR";

export const getMyOrdersAttempt = (data) => ({
  type: GET_MY_ORDERS_ATTEMPT,
  data,
});

export const getMyOrdersSuccess = (data) => ({
  type: GET_MY_ORDERS_SUCCESS,
  data,
});

export const getMyOrdersError = (data) => ({
  type: GET_MY_ORDERS_ERROR,
  data,
});

export const REDIRECT_FROM_CREATE_ORDER = "REDIRECT_FROM_CREATE_ORDER";

export const redirectFromCreateOrder = () => ({
  type: REDIRECT_FROM_CREATE_ORDER,
});

export const CLEAR_REDIRECT_FROM_CREATE_ORDER =
  "CLEAR_REDIRECT_FROM_CREATE_ORDER";

export const clearRedirectFromCreateOrder = () => ({
  type: CLEAR_REDIRECT_FROM_CREATE_ORDER,
});

//ADMIN

export const ADMIN_GET_CUSTOMER_ORDERS_ATTEMPT =
  "ADMIN_GET_CUSTOMER_ORDERS_ATTEMPT";
export const ADMIN_GET_CUSTOMER_ORDERS_SUCCESS =
  "ADMIN_GET_CUSTOMER_ORDERS_SUCCESS";
export const ADMIN_GET_CUSTOMER_ORDERS_ERROR =
  "ADMIN_GET_CUSTOMER_ORDERS_ERROR";

export const adminGetCustomerOrdersAttempt = (data) => ({
  type: ADMIN_GET_CUSTOMER_ORDERS_ATTEMPT,
  data,
});

export const adminGetCustomerOrdersSuccess = (data) => ({
  type: ADMIN_GET_CUSTOMER_ORDERS_SUCCESS,
  data,
});

export const adminGetCustomerOrdersError = (data) => ({
  type: ADMIN_GET_CUSTOMER_ORDERS_ERROR,
  data,
});

export const ADMIN_GET_CUSTOMERS_ATTEMPT = "ADMIN_GET_CUSTOMERS_ATTEMPT";
export const ADMIN_GET_CUSTOMERS_SUCCESS = "ADMIN_GET_CUSTOMERS_SUCCESS";
export const ADMIN_GET_CUSTOMERS_ERROR = "ADMIN_GET_CUSTOMERS_ERROR";

export const adminGetCustomersAttempt = (data) => ({
  type: ADMIN_GET_CUSTOMERS_ATTEMPT,
  data,
});

export const adminGetCustomersSuccess = (data) => ({
  type: ADMIN_GET_CUSTOMERS_SUCCESS,
  data,
});

export const adminGetCustomersError = (data) => ({
  type: ADMIN_GET_CUSTOMERS_ERROR,
  data,
});

export const ADMIN_GET_CUSTOMERS_NO_PAGINATION_ATTEMPT = "ADMIN_GET_CUSTOMERS_NO_PAGINATION_ATTEMPT";
export const ADMIN_GET_CUSTOMERS_NO_PAGINATION_SUCCESS = "ADMIN_GET_CUSTOMERS_NO_PAGINATION_SUCCESS";
export const ADMIN_GET_CUSTOMERS_NO_PAGINATION_ERROR = "ADMIN_GET_CUSTOMERS_NO_PAGINATION_ERROR";

export const adminGetCustomersNoPaginationAttempt = (data) => ({
  type: ADMIN_GET_CUSTOMERS_NO_PAGINATION_ATTEMPT,
  data,
});

export const adminGetCustomersNoPaginationSuccess = (data) => ({
  type: ADMIN_GET_CUSTOMERS_NO_PAGINATION_SUCCESS,
  data,
});

export const adminGetCustomersNoPaginationError = (data) => ({
  type: ADMIN_GET_CUSTOMERS_NO_PAGINATION_ERROR,
  data,
});

//edit customera
export const ADMIN_EDIT_CUSTOMERS_ATTEMPT = "ADMIN_EDIT_CUSTOMERS_ATTEMPT";
export const ADMIN_EDIT_CUSTOMERS_SUCCESS = "ADMIN_EDIT_CUSTOMERS_SUCCESS";
export const ADMIN_EDIT_CUSTOMERS_ERROR = "ADMIN_EDIT_CUSTOMERS_ERROR";
export const RESET_ADMIN_EDIT_CUSTOMERS = "RESET_ADMIN_EDIT_CUSTOMERS";

export const adminEditCustomersAttempt = (data) => ({
  type: ADMIN_EDIT_CUSTOMERS_ATTEMPT,
  data,
});

export const adminEditCustomersSuccess = (data) => ({
  type: ADMIN_EDIT_CUSTOMERS_SUCCESS,
  data,
});

export const adminEditCustomersError = (data) => ({
  type: ADMIN_EDIT_CUSTOMERS_ERROR,
  data,
});

export const resetAdminEditCustomers = () => ({
  type: RESET_ADMIN_EDIT_CUSTOMERS,
});

//edit order

export const EDIT_ORDER_BILLING_DETAILS_ATTEMPT =
  "EDIT_ORDER_BILLING_DETAILS_ATTEMPT";
export const EDIT_ORDER_BILLING_DETAILS_SUCCESS =
  "EDIT_ORDER_BILLING_DETAILS_SUCCESS";
export const EDIT_ORDER_BILLING_DETAILS_ERROR =
  "EDIT_ORDER_BILLING_DETAILS_ERROR";

export const editOrderBillingDetailsAttempt = (data) => ({
  type: EDIT_ORDER_BILLING_DETAILS_ATTEMPT,
  data,
});

export const editOrderBillingDetailsSuccess = (data) => ({
  type: EDIT_ORDER_BILLING_DETAILS_SUCCESS,
  data,
});

export const editOrderBillingDetailsError = (data) => ({
  type: EDIT_ORDER_BILLING_DETAILS_ERROR,
  data,
});

export const EDIT_ORDER_DELETE_ITEM_ATTEMPT = "EDIT_ORDER_DELETE_ITEM_ATTEMPT";
export const EDIT_ORDER_DELETE_ITEM_SUCCESS = "EDIT_ORDER_DELETE_ITEM_SUCCESS";
export const EDIT_ORDER_DELETE_ITEM_ERROR = "EDIT_ORDER_DELETE_ITEM_ERROR";

export const editOrderDeleteItemAttempt = (data) => ({
  type: EDIT_ORDER_DELETE_ITEM_ATTEMPT,
  data,
});

export const editOrderDeleteItemSuccess = (data) => ({
  type: EDIT_ORDER_DELETE_ITEM_SUCCESS,
  data,
});

export const editOrderDeleteItemError = (data) => ({
  type: EDIT_ORDER_DELETE_ITEM_ERROR,
  data,
});

export const EDIT_ORDER_CHANGE_QUANTITY_ATTEMPT =
  "EDIT_ORDER_CHANGE_QUANTITY_ATTEMPT";
export const EDIT_ORDER_CHANGE_QUANTITY_SUCCESS =
  "EDIT_ORDER_CHANGE_QUANTITY_SUCCESS";
export const EDIT_ORDER_CHANGE_QUANTITY_ERROR =
  "EDIT_ORDER_CHANGE_QUANTITY_ERROR";

export const editOrderChangeQuantityAttempt = (data) => ({
  type: EDIT_ORDER_CHANGE_QUANTITY_ATTEMPT,
  data,
});

export const editOrderChangeQuantitySuccess = (data) => ({
  type: EDIT_ORDER_CHANGE_QUANTITY_SUCCESS,
  data,
});

export const editOrderChangeQuantityError = (data) => ({
  type: EDIT_ORDER_CHANGE_QUANTITY_ERROR,
  data,
});

export const EDIT_ORDER_ADD_TO_ORDER_ATTEMPT =
  "EDIT_ORDER_ADD_TO_ORDER_ATTEMPT";
export const EDIT_ORDER_ADD_TO_ORDER_SUCCESS =
  "EDIT_ORDER_ADD_TO_ORDER_SUCCESS";
export const EDIT_ORDER_ADD_TO_ORDER_ERROR = "EDIT_ORDER_ADD_TO_ORDER_ERROR";

export const editOrderAddToOrderAttempt = (data) => ({
  type: EDIT_ORDER_ADD_TO_ORDER_ATTEMPT,
  data,
});

export const editOrderAddToOrderSuccess = (data) => ({
  type: EDIT_ORDER_ADD_TO_ORDER_SUCCESS,
  data,
});

export const editOrderAddToOrderError = (data) => ({
  type: EDIT_ORDER_ADD_TO_ORDER_ERROR,
  data,
});

export const RESET_ORDER_EDITING = "RESET_ORDER_EDITING";

export const resetOrderEditing = () => ({
  type: RESET_ORDER_EDITING,
});

export const ADMIN_EDIT_CUSTOMER_DATA_ATTEMPT = "ADMIN_EDIT_CUSTOMER_DATA_ATTEMPT";
export const ADMIN_EDIT_CUSTOMER_DATA_SUCCESS = "ADMIN_EDIT_CUSTOMER_DATA_SUCCESS";
export const ADMIN_EDIT_CUSTOMER_DATA_ERROR = "ADMIN_EDIT_CUSTOMER_DATA_ERROR";

export const adminEditCustomerDataAttempt = (data) => ({
  type: ADMIN_EDIT_CUSTOMER_DATA_ATTEMPT,
  data,
});

export const adminEditCustomerDataSuccess = (data) => ({
  type: ADMIN_EDIT_CUSTOMER_DATA_SUCCESS,
  data,
});

export const adminEditCustomerDataError = (data) => ({
  type: ADMIN_EDIT_CUSTOMER_DATA_ERROR,
  data,
});

export const RESET_ADMIN_EDIT_CUSTOMER_DATA = "RESET_ADMIN_EDIT_CUSTOMER_DATA";

export const resetAdminEditCustomerData = () => ({
  type: RESET_ADMIN_EDIT_CUSTOMER_DATA,
});

export const ADMIN_CREATE_CUSTOMER_ATTEMPT = "ADMIN_CREATE_CUSTOMER_ATTEMPT";
export const ADMIN_CREATE_CUSTOMER_SUCCESS = "ADMIN_CREATE_CUSTOMER_SUCCESS";
export const ADMIN_CREATE_CUSTOMER_ERROR = "ADMIN_CREATE_CUSTOMER_ERROR";

export const adminCreateCustomerAttempt = (data) => ({
  type: ADMIN_CREATE_CUSTOMER_ATTEMPT,
  data,
});

export const adminCreateCustomerSuccess = (data) => ({
  type: ADMIN_CREATE_CUSTOMER_SUCCESS,
  data,
});

export const adminCreateCustomerError = (data) => ({
  type: ADMIN_CREATE_CUSTOMER_ERROR,
  data,
});

export const RESET_ADMIN_CREATE_CUSTOMER = "RESET_ADMIN_CREATE_CUSTOMER";

export const resetAdminCreateCustomer = () => ({
  type: RESET_ADMIN_CREATE_CUSTOMER,
});

export const ADMIN_DELETE_CUSTOMER_ATTEMPT = "ADMIN_DELETE_CUSTOMER_ATTEMPT";
export const ADMIN_DELETE_CUSTOMER_SUCCESS = "ADMIN_DELETE_CUSTOMER_SUCCESS";
export const ADMIN_DELETE_CUSTOMER_ERROR = "ADMIN_DELETE_CUSTOMER_ERROR";

export const adminDeleteCustomerAttempt = (data) => ({
  type: ADMIN_DELETE_CUSTOMER_ATTEMPT,
  data,
});

export const adminDeleteCustomerSuccess = (data) => ({
  type: ADMIN_DELETE_CUSTOMER_SUCCESS,
  data,
});

export const adminDeleteCustomerError = (data) => ({
  type: ADMIN_DELETE_CUSTOMER_ERROR,
  data,
});

export const RESET_ADMIN_DELETE_CUSTOMER = "RESET_ADMIN_DELETE_CUSTOMER";

export const resetAdminDeleteCustomer = () => ({
  type: RESET_ADMIN_DELETE_CUSTOMER,
});