export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const ADD_QUANTITY = "ADD_QUANTITY";
export const SUBTRACT_QUANTITY = "SUBTRACT_QUANTITY";
export const ADD_CUSTOM_QUANTITY = "ADD_CUSTOM_QUANTITY";
export const REMOVE_CART_PRODUCTS = "REMOVE_CART_PRODUCTS";

export const addToCart = (data) => ({
  type: ADD_TO_CART,
  data,
});

export const removeFromCart = (data) => ({
  type: REMOVE_FROM_CART,
  data,
});

export const addQuantity = (data) => ({
  type: ADD_QUANTITY,
  data,
});

export const subtractQuantity = (data) => ({
  type: SUBTRACT_QUANTITY,
  data,
});

export const addCustomQuantity = (data) => ({
  type: ADD_CUSTOM_QUANTITY,
  data,
});

export const removeCartProducts = (data) => ({
  type: REMOVE_CART_PRODUCTS,
  data,
});
