import { takeEvery, put, call } from "redux-saga/effects";
import axios from "axios";
import history from "../../utils/history";
import {
  GET_RESET_CODE_ATTEMPT,
  getResetCodeSuccess,
  getResetCodeError,
} from "../actions/auth";

import { serverURL } from "../../../env";

// eslint-disable-next-line import/no-anonymous-default-export
export default function* () {
  yield takeEvery(GET_RESET_CODE_ATTEMPT, handleRequest);
}

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else {
    var err = new Error(response.statusText);
    err.response = response;
    throw err;
  }
}

function requestCode(requestURL, options) {
  return axios({
    method: options.method,
    url: requestURL,
    data: options.body,
  }).then(checkStatus);
}

function* handleRequest(payload) {
  const requestURL = `${serverURL}/users/create_password_reset_code/`;
  const options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: payload.data,
  };

  try {
    const response = yield call(requestCode, requestURL, options);

    yield put(getResetCodeSuccess(response.data));
    history.push("/validateCode");
  } catch (err) {
    yield put(getResetCodeError(err?.response?.data?.error));
  }
}
