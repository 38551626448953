/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import styles from "./CheckoutScreen.module.css";
import { useSelector, useDispatch } from "react-redux";
import { createOrderAttempt } from "../../redux/actions/orders";
import history from "../../utils/history";
import { Container, Table, Row, Col, Button } from "react-bootstrap";
import { FaMapMarkerAlt, FaEnvelope, FaPhoneAlt, FaInfo } from "react-icons/fa";
import Loader from "../../components/Loader/Loader";
import { useNetwork } from "../../utils/checkInternet";

const CheckoutScreen = () => {
  const isConnected = useNetwork();

  const dispatch = useDispatch();
  const { redirectToRegistration, createOrderAttempt: loader } = useSelector(
    (state) => state.orders
  );
  const { authToken } = useSelector((state) => state.auth);
  const { company } = useSelector((state) => state.profile);
  const { savedOrder } = useSelector((state) => state.orders);
  const tax = savedOrder?.tax;
  const grandTotal = savedOrder?.grandTotal;

  useEffect(() => {
    document.title = "Miraco - Checkout";
    if (redirectToRegistration && !authToken) {
      history.push("/login");
    } else if (!authToken) {
      history.push("/");
    } else if (authToken) {
      window.scrollTo({
        top: 0,
      });
    }
  }, [dispatch, redirectToRegistration, authToken]);

  const renderTable = () => {
    return (
      <Table hover responsive className="table-sm">
        <thead>
          <tr>
            <th>Product</th>
            <th>Bar code</th>
            <th>Price per item</th>
            <th>Taxable</th>
            <th>Quantity</th>
          </tr>
        </thead>
        <tbody>
          {savedOrder?.products?.map((order) => {
            return (
              <tr key={order.id}>
                <td>{order?.name}</td>
                <td>{order?.barcode}</td>
                <td>{order?.price}</td>
                <td>{order?.sales_tax_code === "G" ? "Yes" : "No"}</td>
                <td>
                  {order?.quantity}
                  <span style={{ fontWeight: "bold", color: "#1b9a3e" }}>
                    {order?.quantity > 9 && order?.actions === "10-1"
                      ? ` +1 GRATIS = `
                      : null}
                  </span>
                  {order?.quantity > 9 && order?.actions === "10-1"
                    ? order?.quantity + 1
                    : null}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  };

  const placeOrderHandler = () => {
    const items = savedOrder.products.map((product) => {
      return {
        id: product?.id,
        quantity:
          product?.quantity > 9 && product?.actions === "10-1"
            ? product?.quantity + 1
            : product?.quantity,
      };
    });

    if (isConnected) {
      dispatch(
        createOrderAttempt({
          purchased_items: items,
          price: grandTotal,
          address: savedOrder?.billing?.address,
          companyName: savedOrder?.billing?.companyName ?? company,
          country: savedOrder?.billing?.country,
          email: savedOrder?.billing?.email,
          orderNotes: savedOrder?.billing?.orderNotes,
          phone: savedOrder?.billing?.phone,
          state: savedOrder?.billing?.state,
          townCity: savedOrder?.billing?.townCity,
          zipcode: savedOrder?.billing?.zip,
        })
      );
    }
  };

  return (
    <div className={styles.checkoutPage}>
      <Container fluid>
        <Row>
          <Col md={8} sm={12}>
            <div className={styles.checkoutProductsDiv}>
              <Container>
                <div className={styles.checkoutHeader}>
                  <h5 className={styles.itemsInCartHeader}>
                    Items in your cart
                  </h5>
                  <span
                    className={styles.itemsInCartHeaderSpan}
                    onClick={() => history.push("/cart")}
                  >
                    Edit your order
                  </span>
                </div>
                {renderTable()}
              </Container>
            </div>
          </Col>
          <Col md={4} sm={12}>
            <div className={styles.checkoutTotalDiv}>
              <Container>
                <h5 className={styles.shippingDetailsHeader}>
                  Shipping details
                </h5>
                <div>
                  <p className={styles.checkoutCompanyName}>
                    {savedOrder?.billing?.companyName
                      ? savedOrder?.billing?.companyName
                      : null}{" "}
                  </p>
                  <div className={styles.checkoutAddressDiv}>
                    <p className={styles.commonCheckouBillingText}>
                      <FaMapMarkerAlt className={styles.bilingIcon} />
                      {savedOrder?.billing?.address},{" "}
                      {savedOrder?.billing?.state}, {savedOrder?.billing?.zip},{" "}
                      {savedOrder?.billing?.townCity},{" "}
                      {savedOrder?.billing?.country}
                    </p>
                  </div>
                  <div className={styles.checkoutContactDiv}>
                    <p className={styles.commonCheckouBillingText}>
                      <FaEnvelope className={styles.bilingIcon} />{" "}
                      {savedOrder?.billing?.email}
                    </p>
                    <p className={styles.commonCheckouBillingText}>
                      <FaPhoneAlt className={styles.bilingIcon} />{" "}
                      {savedOrder?.billing?.phone}
                    </p>
                  </div>
                  {savedOrder?.billing?.orderNotes ? (
                    <p className={styles.commonCheckouBillingText}>
                      <FaInfo className={styles.bilingIcon} />
                      {savedOrder?.billing?.orderNotes}
                    </p>
                  ) : null}
                </div>
                <h5 className={styles.orderPriceHeading}>Order price</h5>
                <div className={styles.totalPriceDiv}>
                  <div className={styles.commonPriceDiv}>
                    <p className={styles.subtotalHeading}>Subtotal</p>
                    <p className={styles.price}>
                      $CAD {savedOrder?.orderPrice?.toFixed(2)}
                    </p>
                  </div>
                  <div className={styles.commonPriceDiv}>
                    <p className={styles.taxHeading}>Tax</p>
                    <p className={styles.taxPrice}>$CAD {tax?.toFixed(2)}</p>
                  </div>
                  <hr className={styles.horizontalLine} />
                  <div className={styles.commonPriceDiv}>
                    <p className={styles.totalHeading}>Grand Total</p>
                    <p className={styles.totalPrice}>
                      $CAD {grandTotal?.toFixed(2)}
                    </p>
                  </div>
                </div>
                <Button
                  onClick={placeOrderHandler}
                  variant="btn btn-outline-success rounded w-100 notInTableButton mt-5"
                >
                  {loader ? <Loader /> : "Place your order"}
                </Button>
              </Container>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CheckoutScreen;
