export const GET_PRODUCTS_FOR_OFFLINE_SAVE_ATTEMPT =
  "GET_PRODUCTS_FOR_OFFLINE_SAVE_ATTEMPT";
export const GET_PRODUCTS_FOR_OFFLINE_SAVE_SUCCESS =
  "GET_PRODUCTS_FOR_OFFLINE_SAVE_SUCCESS";
export const GET_PRODUCTS_FOR_OFFLINE_SAVE_ERROR =
  "GET_PRODUCTS_FOR_OFFLINE_SAVE_ERROR";

export const getProductsForOfflineSaveAttempt = (data) => ({
  type: GET_PRODUCTS_FOR_OFFLINE_SAVE_ATTEMPT,
  data,
});

export const getProductsForOfflineSaveSuccess = (data) => ({
  type: GET_PRODUCTS_FOR_OFFLINE_SAVE_SUCCESS,
  data,
});

export const getProductsForOfflineSaveError = (data) => ({
  type: GET_PRODUCTS_FOR_OFFLINE_SAVE_ERROR,
  data,
});

export const FILTER_OFFLINE_PRODUCTS = "FILTER_OFFLINE_PRODUCTS"

export const filterOfflineProducts = (data) => ({
  type: FILTER_OFFLINE_PRODUCTS,
  data,
});