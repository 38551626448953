import { EMAIL_LOGOUT_SUCCESS } from "../actions/auth";
import {
  ADD_TO_CART,
  REMOVE_FROM_CART,
  ADD_QUANTITY,
  SUBTRACT_QUANTITY,
  ADD_CUSTOM_QUANTITY,
  REMOVE_CART_PRODUCTS,
} from "../actions/cart";

const defaultState = {
  cartProducts: [],
  totalPrice: 0,
};

export const cartReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ADD_TO_CART:
      let addedItem = action.data;
      // console.log(addedItem);
      //check if the action id exists in the cartProducts
      let existed_item = state.cartProducts.find(
        (item) => action.data.id === item.id
      );
      if (existed_item) {
        addedItem.quantity += existed_item.box_quantity;
        return {
          ...state,
          totalPrice: state.totalPrice + addedItem.price * addedItem.quantity,
        };
      } else {
        addedItem.quantity = addedItem.box_quantity;
        //calculating the total
        let newTotal =
          state.totalPrice +
          (addedItem.special
            ? addedItem.price * addedItem.quantity -
              addedItem.price * addedItem.quantity * (addedItem.special / 100)
            : addedItem.price * addedItem.quantity);

        return {
          ...state,
          cartProducts: [...state.cartProducts, addedItem],
          totalPrice: newTotal,
        };
      }

    case REMOVE_FROM_CART:
      let itemToRemove = action.data;
      let new_items = state.cartProducts.filter(
        (item) => action.data.id !== item.id
      );

      //calculating the total
      let newTotal =
        state.totalPrice -
        (itemToRemove.special
          ? itemToRemove.price -
            itemToRemove.price * (itemToRemove.special / 100)
          : itemToRemove.price) *
          itemToRemove.quantity;
      return {
        ...state,
        cartProducts: new_items,
        totalPrice: newTotal,
      };

    case ADD_QUANTITY:
      let itemAdded = state.cartProducts.find(
        (item) => action.data.id === item.id
      );
      itemAdded.quantity += itemAdded.box_quantity;
      let newPriceTotal =
        state.totalPrice +
        (itemAdded.special
          ? itemAdded.price * itemAdded.box_quantity -
            itemAdded.price * itemAdded.box_quantity * (itemAdded.special / 100)
          : itemAdded.price * itemAdded.box_quantity);
      return {
        ...state,
        totalPrice: newPriceTotal,
      };

    case SUBTRACT_QUANTITY:
      let _itemAdded = state.cartProducts.find(
        (item) => action.data.id === item.id
      );
      //if the qt == 0 then it should be removed
      if (_itemAdded.quantity === _itemAdded.box_quantity) {
        let new_items = state.cartProducts.filter(
          (item) => action.data.id !== item.id
        );
        let newTotal =
          state.totalPrice -
          (_itemAdded.special
            ? _itemAdded.price * _itemAdded.box_quantity -
              _itemAdded.price *
                _itemAdded.box_quantity *
                (_itemAdded.special / 100)
            : _itemAdded.price * _itemAdded.box_quantity);
        return {
          ...state,
          cartProducts: new_items,
          totalPrice: newTotal,
        };
      } else {
        _itemAdded.quantity -= _itemAdded.box_quantity;
        let newTotal =
          state.totalPrice -
          (_itemAdded.special
            ? _itemAdded.price * _itemAdded.box_quantity -
              _itemAdded.price *
                _itemAdded.box_quantity *
                (_itemAdded.special / 100)
            : _itemAdded.price * _itemAdded.box_quantity);
        return {
          ...state,
          totalPrice: newTotal,
        };
      }

    case ADD_CUSTOM_QUANTITY:
      const price = state.cartProducts.map((item) => {
        let pom_price = item.special
          ? item.price - item.price * (item.special / 100)
          : item.price;
        return pom_price * item.quantity;
      });

      const ukPrice = price.reduce((acc, next) => acc + next, 0);

      return {
        ...state,
        totalPrice: ukPrice,
      };

    case REMOVE_CART_PRODUCTS:
      return defaultState;

    case EMAIL_LOGOUT_SUCCESS:
      return defaultState;

    default:
      return state;
  }
};
