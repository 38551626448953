/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import styles from "./CartScreen.module.css";
import QuantityInput from "./QuantityInput";
import { AiOutlineClose } from "react-icons/ai";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { removeFromCart } from "../../redux/actions/cart";
import {
  saveCreatedOrder,
  redirectToRegistration,
  adminGetCustomersNoPaginationAttempt,
} from "../../redux/actions/orders";
import history from "../../utils/history";
import "./buttons.css";
import emptyCart from "../../../assets/images/empty-cart.svg";
import placeholder from "../../../assets/images/placeholder.png";
import { useNetwork } from "../../utils/checkInternet";
import Select from "react-select";

function CartScreen() {
  const isConnected = useNetwork();
  const dispatch = useDispatch();

  useEffect(() => {
    document.title = "Miraco - Cart";
    dispatch(adminGetCustomersNoPaginationAttempt());
  }, []);

  const { authToken } = useSelector((state) => state.auth);
  const { cartProducts, totalPrice } = useSelector((state) => state.cart);
  const { customersNoPagination, savedOrder } = useSelector(
    (state) => state.orders
  );

  const pomCustomer = {
    label: savedOrder?.billing?.label,
    value: savedOrder?.billing?.value,
  };

  const [selectedCustomer, setSelectedCustomer] = useState(
    pomCustomer?.label ? pomCustomer : ""
  );

  const renderCustomerSelect = () => {
    const options = customersNoPagination?.map((vendor) => {
      return {
        label: vendor?.company,
        value: vendor?.id,
        data: vendor,
      };
    });

    const style = {
      control: (base) => ({
        ...base,
        border: 0,
        // This line disable the blue border
        boxShadow: "none",
      }),
    };

    let grandTotalPrice = cartProducts
      .map((item) => {
        let pom_price = item?.special
          ? item?.price - item?.price * (item.special / 100)
          : item?.price;

        return item?.sales_tax_code === "G"
          ? pom_price * item?.quantity + pom_price * item?.quantity * 0.05
          : pom_price * item?.quantity;
      })
      .reduce((acc, next) => acc + next, 0);

    return (
      <>
        <Select
          value={selectedCustomer}
          onChange={(selectedOption) => setSelectedCustomer(selectedOption)}
          options={options}
          placeholder="Select customer"
          className="abc"
          classNamePrefix="react-select"
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary25: "rgba(27, 154, 62, 0.2)",
              primary: "rgba(27, 154, 62, 0.4)",
              primary50: "#DEEBF4",
            },
          })}
          styles={style}
        />
        <Button
          variant="btn btn-outline-success rounded w-100 notInTableButton mt-5"
          disabled={!selectedCustomer}
          onClick={() => {
            if (!authToken) {
              dispatch(redirectToRegistration());
            }
            history.push("/checkout-admin");
            dispatch(
              saveCreatedOrder({
                products: cartProducts,
                billing: selectedCustomer,
                orderPrice: totalPrice,
                tax: grandTotalPrice - parseFloat(totalPrice?.toFixed(2)),
                grandTotal: grandTotalPrice,
              })
            );
          }}
        >
          Review and pay
        </Button>
      </>
    );
  };

  const renderCartItems = () => {
    if (cartProducts.length === 0) {
      return null;
    } else {
      return (
        <>
          <div className={styles.heading}>
            <div className={styles.heading_flag}></div>

            <div className={styles.heading_name}>
              <div>
                <p>Product</p>
              </div>
            </div>

            <div className={styles.heading_population}>
              <div>
                <p>Price</p>
              </div>
            </div>

            <div className={styles.heading_gini}>
              <div>Taxable</div>
            </div>

            <div className={styles.heading_population}>
              <div>
                <p className="text-center">Quantity</p>
              </div>
            </div>

            <div className={styles.heading_gini}>
              {/* <div>Remove from cart</div> */}
            </div>
          </div>

          {cartProducts.map((product, index) => (
            <React.Fragment key={product.id}>
              {index === 0 ? <hr /> : null}
              <div className={styles.row}>
                <div className={styles.flag}>
                  <img
                    src={product.image ? product.image : placeholder}
                    alt={product.image}
                    className="img-fluid"
                  />
                </div>

                <div className={styles.name}>
                  <p>
                    {product.name}{" "}
                    <span style={{ fontWeight: "bold", color: "#1b9a3e" }}>
                      {product?.quantity > 9 && product?.actions === "10-1"
                        ? "+1 GRATIS"
                        : null}
                    </span>
                  </p>
                </div>

                <div className={styles.population}>
                  {authToken ? (
                    <p>
                      {" "}
                      {"CAD "}
                      {(
                        product?.price -
                        product?.price * (product?.special / 100)
                      ).toFixed(2)}{" "}
                    </p>
                  ) : (
                    <p>Login to see price</p>
                  )}
                </div>

                <div className={styles.gini}>
                  {product?.sales_tax_code === "G" ? "Yes" : "No"}
                </div>

                <div className={styles.population}>
                  <div className="quantity buttons_added">
                    <QuantityInput foundedProducts={product} />
                  </div>
                </div>

                <div className={styles.gini}>
                  <span
                    className={styles.removeFromCartIcon}
                    onClick={() => dispatch(removeFromCart(product))}
                  >
                    <AiOutlineClose />
                  </span>
                </div>
              </div>
              <hr />
            </React.Fragment>
          ))}
        </>
      );
    }
  };

  const renderPrice = () => {
    // let grandTotalPrice = cartProducts
    //   .map((item) =>
    //     item?.sales_tax_code === "G"
    //       ? item?.price * item?.quantity + item?.price * item?.quantity * 0.05
    //       : item?.price * item?.quantity
    //   )
    //   .reduce((acc, next) => acc + next, 0);

    return (
      <>
        <h5 className={styles.totalPriceHeading}>
          Estimated total {totalPrice?.toFixed(2)}
        </h5>
        {/* <h5 className={styles.totalPriceHeading}>
          Tax{" "}
          {(grandTotalPrice - parseFloat(totalPrice?.toFixed(2)))?.toFixed(2)}
        </h5>
        <h5 className={styles.totalPriceHeading}>
          Grand Total: CA$ {grandTotalPrice?.toFixed(2)}
        </h5> */}
      </>
    );
  };

  return (
    <div className={styles.checkoutScreen}>
      <Container>
        <Row>
          {cartProducts.length > 0 ? (
            <>
              <Col md={7} sm={12}>
                <div className={styles.cartContainer}>
                  <h4 className={styles.shoppingCartHeading}>Shopping Cart</h4>
                  {renderCartItems()}
                  {cartProducts.length > 0 && authToken ? renderPrice() : null}
                </div>
              </Col>
              <Col>
                <div className={styles.billingFormContainer}>
                  <h4 className={styles.shoppingCartHeading}>
                    Select customer for order
                  </h4>
                  {renderCustomerSelect()}
                </div>
              </Col>
            </>
          ) : (
            <div className={styles.noProductsMessageDiv}>
              <img src={emptyCart} alt="cart-empty" />
              <h4 className={styles.noItemsInCartText}>
                You don't have products <br /> in cart.
              </h4>
              <h5 className={styles.noItemsInCartText}>Add some products</h5>
              <Link
                className="btn btn-outline-success mt-2 rounded notInTableButton"
                to="/products"
              >
                Shop now
              </Link>
            </div>
          )}
        </Row>
      </Container>
    </div>
  );
}

export default CartScreen;
