import React, { useEffect } from "react";
import { Router, Route, Redirect, Switch } from "react-router-dom";
import Header from "./js/components/Header/Header";
import Footer from "./js/components/Footer/Footer";
import { publicRoutes } from "./js/routes/publicRoutes";
import { customerRoutes } from "./js/routes/customerRoutes";
import { adminRoutes } from "./js/routes/adminRoutes";
import { customerRoutesNotVerified } from "./js/routes/customerRoutesNotVerified";
import history from "./js/utils/history";
import { useSelector, useDispatch } from "react-redux";
import OfflineBar from "./js/components/OfflineBar/OfflineBar";
import { getProfileAttempt } from "./js/redux/actions/profile";

function MainApp() {
  const { form, verified, user } = useSelector((state) => state.auth);
  const publicRoutesToShow = publicRoutes.filter((route) => route.show);
  const customerRoutesToShow = customerRoutes.filter((route) => route.show);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user) {
      const intervalId = setInterval(() => {
        //assign interval to a variable to clear it.
        dispatch(getProfileAttempt({ customerId: user }));
      }, 60000);

      return () => clearInterval(intervalId); //This is important
    }
  }, []);

  const showPublicRoutes = () => {
    if (form === "") {
      return publicRoutes.map((prop, key) => {
        return (
          <Route
            exact
            path={prop.path}
            render={(props) => <prop.component {...props} />}
            key={key}
          />
        );
      });
    } else {
      return publicRoutes
        .filter(
          (route) => route.title !== "Login" && route.title !== "Register"
        )
        .map((prop, key) => {
          return (
            <Route
              exact
              path={prop.path}
              render={(props) => <prop.component {...props} />}
              key={key}
            />
          );
        });
    }
  };

  const showCustomerRoutes = () => {
    if (form === "Customer" && !verified) {
      return customerRoutesNotVerified.map((prop, key) => {
        return (
          <Route
            exact
            path={prop.path}
            render={(props) => <prop.component {...props} />}
            key={key}
          />
        );
      });
    } else {
      return customerRoutes.map((prop, key) => {
        return (
          <Route
            exact
            path={prop.path}
            render={(props) => <prop.component {...props} />}
            key={key}
          />
        );
      });
    }
  };

  const showAdminRoutes = () => {
    return [...adminRoutes, ...publicRoutes].map((prop, key) => {
      return (
        <Route
          exact
          path={prop.path}
          render={(props) => <prop.component {...props} />}
          key={key}
        />
      );
    });
  };

  return (
    <Router history={history}>
      <OfflineBar />
      <Header
        routes={
          form === "Customer"
            ? [...publicRoutesToShow, ...customerRoutesToShow]
            : publicRoutesToShow
        }
      />
      <Switch>
        {showPublicRoutes()}
        {form === "Customer" ? showCustomerRoutes() : null}
        {form === "Admin" ? showAdminRoutes() : null}
        <Redirect from="*" to="/" />
      </Switch>
      <Footer />
    </Router>
  );
}

export default MainApp;
