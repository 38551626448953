import { takeEvery, put, call, select } from "redux-saga/effects";
import axios from "axios";

import {
    ADMIN_CREATE_ORDER_ATTEMPT,
    adminCreateOrderSuccess,
    adminCreateOrderError,
    resetCreateOrder,
    deleteCreatedOrder,
    adminGetCustomerOrdersAttempt
} from "../actions/orders";

import { removeCartProducts } from "../actions/cart";

import history from "../../utils/history";

import { serverURL } from "../../../env";

// eslint-disable-next-line import/no-anonymous-default-export
export default function* () {
    yield takeEvery(ADMIN_CREATE_ORDER_ATTEMPT, handleCreate);
}

function checkStatus(response) {
    if (response.status >= 200 && response.status < 300) {
        return response;
    } else {
        var err = new Error(response.statusText);
        err.response = response;
        throw err;
    }
}

function createOrder(requestURL, options) {
    return axios({
        method: options.method,
        headers: options.headers,
        url: requestURL,
        data: options.body,
    }).then(checkStatus);
}

function* handleCreate(payload) {
    const user_token = yield select(({ auth }) => auth.authToken);
    const requestURL = `${serverURL}/orders/admin_order/details/`;
    const options = {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: user_token,
        },
        body: payload.data,
    };

    try {
        const response = yield call(createOrder, requestURL, options);
        yield put(adminCreateOrderSuccess(response.data));
        yield put(resetCreateOrder());
        yield put(removeCartProducts());
        yield put(deleteCreatedOrder());
        yield put(adminGetCustomerOrdersAttempt({ sort: "-created_at" }));
        history.push(`/admin/order/${response.data?.order?.id}`);
    } catch (err) {
        yield put(adminCreateOrderError(err));
    }
}

