/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import styles from "./AddVendorScreen.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  addVendorAttempt,
  resetAddVendor,
} from "../../../redux/actions/vendors";
import { useToasts } from "react-toast-notifications";
import { Form, Row, Col, Container, Button } from "react-bootstrap";
import { SiteMap } from "../../../components/BreadCrumbs/BreadCrumbs";

const AddProductScreen = () => {
  const navLinks = [
    { href: "/admin/vendors", name: "Vendors" },
    { href: `/admin/add-vendor`, name: "Add vendor" },
  ];
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { addVendorSuccess } = useSelector((state) => state.vendors);

  const [vendorName, setVendorName] = useState("");
  const [primaryContact, setPrimaryContact] = useState("");
  const [mainPhone, setMainPhone] = useState("");
  const [vendorEmail, setVendorEmail] = useState("");
  const [vendorFax, setVendorFax] = useState("");

  useEffect(() => {
    if (addVendorSuccess) {
      setVendorName("");
      setPrimaryContact("");
      setMainPhone("");
      setVendorEmail("");
      setVendorFax("");
      addToast(`Vendor added succesfully`, {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 2000,
        onDismiss: () => {
          dispatch(resetAddVendor());
        },
      });
    }
  }, [addVendorSuccess]);

  const addCategoryHandler = (e) => {
    e.preventDefault();
    dispatch(
      addVendorAttempt({
        name: vendorName,
        primary_contact: primaryContact,
        main_phone: mainPhone,
        email: vendorEmail,
        fax: vendorFax,
      })
    );
  };

  return (
    <div className={styles.addCategoryScreen}>
      <Container>
        <SiteMap hrefIn={`/admin/add-vendor`} items={navLinks} />
        <Row className="justify-content-md-center">
          <Col xs={12} md={6}>
            <Form onSubmit={addCategoryHandler}>
              <Form.Group controlId="desc">
                <Form.Label>Vendor name</Form.Label>
                <Form.Control
                  className={styles.myInput}
                  type="text"
                  placeholder="Enter vendor name"
                  value={vendorName}
                  onChange={(e) => {
                    setVendorName(e.target.value);
                  }}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId="desc">
                <Form.Label>Vendor email</Form.Label>
                <Form.Control
                  className={styles.myInput}
                  type="text"
                  placeholder="Enter vendor email"
                  value={vendorEmail}
                  onChange={(e) => {
                    setVendorEmail(e.target.value);
                  }}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId="desc">
                <Form.Label>Main phone</Form.Label>
                <Form.Control
                  className={styles.myInput}
                  type="text"
                  placeholder="Enter main phone"
                  value={mainPhone}
                  onChange={(e) => {
                    setMainPhone(e.target.value);
                  }}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId="desc">
                <Form.Label>Primary contact</Form.Label>
                <Form.Control
                  className={styles.myInput}
                  type="text"
                  placeholder="Enter primary contact"
                  value={primaryContact}
                  onChange={(e) => {
                    setPrimaryContact(e.target.value);
                  }}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId="desc">
                <Form.Label>Fax</Form.Label>
                <Form.Control
                  className={styles.myInput}
                  type="text"
                  placeholder="Enter fax number"
                  value={vendorFax}
                  onChange={(e) => {
                    setVendorFax(e.target.value);
                  }}
                ></Form.Control>
              </Form.Group>
              <Button
                variant="btn btn-outline-success rounded notInTableButton"
                type="submit"
                disabled={!vendorName}
              >
                Create vendor
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AddProductScreen;
