import { Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";

export const SiteMap = ({ hrefIn, items }) => {
  return (
    <Breadcrumb>
      {items.map((item) =>
        item.href === hrefIn ? (
          <Breadcrumb.Item key={item.name} active>
            {item.name}
          </Breadcrumb.Item>
        ) : (
          <Breadcrumb.Item
            key={item.name}
            linkProps={{ to: item.href }}
            linkAs={Link}
          >
            {item.name}
          </Breadcrumb.Item>
        )
      )}
    </Breadcrumb>
  );
};
