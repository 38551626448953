import { takeEvery, put, call, select } from "redux-saga/effects";
import axios from "axios";

import {
  CREATE_CATEGORY_ATTEMPT,
  createCategorySuccess,
  createCategoryError,
} from "../actions/categories";

import { serverURL } from "../../../env";

// eslint-disable-next-line import/no-anonymous-default-export
export default function* () {
  yield takeEvery(CREATE_CATEGORY_ATTEMPT, handleCreate);
}

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else {
    var err = new Error(response.statusText);
    err.response = response;
    throw err;
  }
}

function createCategory(requestURL, options) {
  return axios({
    method: options.method,
    headers: options.headers,
    url: requestURL,
    data: options.body,
  }).then(checkStatus);
}

function* handleCreate(payload) {
  const user_token = yield select(({ auth }) => auth.authToken);
  const requestURL = `${serverURL}/categories/category/details/`;
  const options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: user_token,
    },
    body: payload.data,
  };

  try {
    const response = yield call(createCategory, requestURL, options);

    yield put(createCategorySuccess(response.data));
  } catch (err) {
    yield put(createCategoryError(err));
  }
}
