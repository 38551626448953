/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import styles from "./EditOrderScreen.module.css";
import CartQuantityInput from "./CartQuantityInput";
import ModalQuantityInput from "./ModalQuantityInput";
import { AiOutlineClose } from "react-icons/ai";
import { useSelector, useDispatch } from "react-redux";
import { billingCanBeSubmited } from "../../utils/validate";
import placeholder from "../../../assets/images/placeholder.png";
import { useLocation } from "react-router-dom";
import { SiteMap } from "../../components/BreadCrumbs/BreadCrumbs";
import Pagination from "../../components/Pagination/Pagination";
import { Modal } from "react-responsive-modal";
import { getProductsAttempt } from "../../redux/actions/products";
import { useToasts } from "react-toast-notifications";
import {
  editOrderBillingDetailsAttempt,
  editOrderDeleteItemAttempt,
  editOrderChangeQuantityAttempt,
  editOrderAddToOrderAttempt,
  resetOrderEditing,
} from "../../redux/actions/orders";
import history from "../../utils/history";

function CartScreen() {
  let location = useLocation();

  const navLinks = [
    { href: "/my-orders", name: "My orders" },
    {
      href: `/order/${location?.singleOrder?.id}`,
      name: `Order #${location?.singleOrder?.id}`,
    },
    { href: `/order/${location?.singleOrder?.email}`, name: "Edit order" },
  ];

  const { addToast } = useToasts();

  const dispatch = useDispatch();
  const { authToken } = useSelector((state) => state.auth);
  const {
    adminProducts: products,
    next,
    previous,
    count,
  } = useSelector((state) => state.products);
  const { editOrderBillingDetailsSuccess } = useSelector(
    (state) => state.orders
  );
  const [open, setOpen] = useState(false);
  const [showQuantity, setShowQuantity] = useState(false);
  const [itemId, setItemId] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [itemsInOrder, setItemsInOrder] = useState(
    location?.singleOrder?.purchased_items?.map((product) => {
      let proizvod = product?.product;
      proizvod.quantity = product?.quantity;
      return proizvod;
    })
  );

  const [deletedItems, setDeletedItems] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [visibilityAllPageNumbers, setVisibilityAllPageNumbers] =
    useState(false);
  const [selectedPageToJump, setSelectedPageToJump] = useState("");

  const [checkoutState, setCheckoutState] = useState({
    companyName: "",
    country: "Canada",
    address: "",
    townCity: "",
    state: "",
    zip: "",
    email: "",
    phone: "",
    orderNotes: "",
    price: "",
  });

  useEffect(() => {
    document.title = "Miraco - Edit order";
    setCheckoutState({
      companyName: location?.singleOrder?.companyName,
      country: "Canada",
      address: location?.singleOrder?.address,
      townCity: location?.singleOrder?.townCity,
      state: location?.singleOrder?.state,
      zip: location?.singleOrder?.zipcode,
      email: location?.singleOrder?.email,
      phone: location?.singleOrder?.phone,
      orderNotes: location?.singleOrder?.orderNotes,
    });
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
    if (currentPage === 1) {
      dispatch(getProductsAttempt({ search: "" }));
    } else if (currentPage === Number(next?.slice(-1))) {
      let pom_next = next.substring(0, next.indexOf("page"));
      pom_next = `${pom_next}page=${currentPage}`;
      dispatch(getProductsAttempt({ payloadUrl: pom_next }));
    } else if (currentPage > Number(next?.slice(-1))) {
      let pom_next = next.substring(0, next.indexOf("page"));
      pom_next = `${pom_next}page=${currentPage}`;
      dispatch(getProductsAttempt({ payloadUrl: pom_next }));
    } else {
      dispatch(getProductsAttempt({ payloadUrl: previous }));
    }
  }, [currentPage]);

  const isDisabled =
    !billingCanBeSubmited(
      checkoutState?.country,
      checkoutState?.address,
      checkoutState?.townCity,
      checkoutState?.state,
      checkoutState?.zip,
      checkoutState?.email,
      checkoutState?.phone
    ) || itemsInOrder?.length === 0;

  let deletedProducts = location?.singleOrder?.purchased_items?.filter(
    function (item) {
      return deletedItems.indexOf(item?.product?.id) > -1;
    }
  );

  const callDelete = () => {
    return deletedProducts.map((item) => {
      return dispatch(
        editOrderDeleteItemAttempt({
          itemId: item?.id,
        })
      );
    });
  };

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  const paginate = (pageNum) => setCurrentPage(pageNum);

  const nextPage = () => setCurrentPage(currentPage + 1);

  const prevPage = () => setCurrentPage(currentPage - 1);

  const changeVisibilityAllPageNumbers = (visibility) =>
    setVisibilityAllPageNumbers(visibility);

  const setJumpedPage = (pageObj) => setSelectedPageToJump(pageObj);

  const renderModal = () => {
    return (
      <Modal open={open} onClose={onCloseModal} center>
        <h4 className="my-4">Select products to add</h4>
        <div className="d-flex">
          <Form.Control
            className="rounded mySearchInput"
            type="text"
            placeholder="Search by name"
            value={searchQuery}
            onChange={(e) => {
              setSearchQuery(e.target.value.toLowerCase());
              if (e.target.value.length === 0) {
                dispatch(getProductsAttempt({ search: "" }));
              }
            }}
          ></Form.Control>
          <button
            className="confirmSearch"
            type="submit"
            disabled={!searchQuery}
            onClick={() => {
              dispatch(getProductsAttempt({ search: searchQuery }));
            }}
          >
            Search
          </button>
        </div>
        <div
          style={{
            maxHeight: "500px",
            overflowY: "scroll",
          }}
        >
          {renderItemsToAddInCart()}
          <div className={styles.paginationWrapper}>
            <Pagination
              postsPerPage={16}
              totalPosts={count}
              paginate={paginate}
              nextPage={nextPage}
              prevPage={prevPage}
              totalPageNumber={Math.ceil(count / 16)}
              currentPage={currentPage}
              visibilityAllPageNumbers={visibilityAllPageNumbers}
              changeVisibilityAllPageNumbers={changeVisibilityAllPageNumbers}
              setSelectedPageToJump={setJumpedPage}
              selectedPageToJump={selectedPageToJump}
            />
          </div>
        </div>
      </Modal>
    );
  };

  const renderCartItems = () => {
    if (itemsInOrder?.length === 0) {
      return null;
    } else {
      return (
        <>
          <div className={styles.heading}>
            <div className={styles.heading_flag}></div>

            <div className={styles.heading_name}>
              <div>
                <p>Product</p>
              </div>
            </div>

            <div className={styles.heading_population}>
              <div>
                <p>Price</p>
              </div>
            </div>

            <div className={styles.heading_gini}>
              <div>Taxable</div>
            </div>

            <div className={styles.heading_population}>
              <div>
                <p>Quantity</p>
              </div>
            </div>

            {itemsInOrder?.length > 1 && (
              <div className={styles.heading_gini} />
            )}
          </div>

          {itemsInOrder?.map((product, index) => (
            <React.Fragment key={product.id}>
              {index === 0 ? <hr /> : null}
              <div className={styles.row}>
                <div className={styles.flag}>
                  <img
                    src={product?.image ? product?.image : placeholder}
                    alt={product?.image}
                    className="img-fluid"
                  />
                </div>

                <div className={styles.name}>
                  <p>{product?.name}</p>
                </div>

                <div className={styles.population}>
                  {authToken ? (
                    <p>
                      {" "}
                      {"CAD "}
                      {(
                        product?.price -
                        product?.price * (product?.special / 100)
                      ).toFixed(2)}{" "}
                    </p>
                  ) : (
                    <p>Login to see price</p>
                  )}
                </div>

                <div className={styles.gini}>
                  {product?.sales_tax_code === "G" ? "Yes" : "No"}
                </div>

                <div className={styles.population}>
                  <div className="quantity buttons_added">
                    <CartQuantityInput
                      itemsInOrder={itemsInOrder}
                      setItemsInOrder={setItemsInOrder}
                      foundedProduct={product}
                    />
                  </div>
                </div>

                {itemsInOrder.length > 1 ? (
                  <div className={styles.gini}>
                    <span
                      className={styles.removeFromCartIcon}
                      onClick={() => {
                        setDeletedItems([...deletedItems, product?.id]);
                        setItemsInOrder(
                          itemsInOrder?.filter(
                            (item) => item?.id !== product?.id
                          )
                        );
                      }}
                    >
                      <AiOutlineClose />
                    </span>
                  </div>
                ) : null}
              </div>
              <hr />
            </React.Fragment>
          ))}
        </>
      );
    }
  };

  const renderItemsToAddInCart = () => {
    if (products?.length === 0) {
      return null;
    } else {
      return (
        <>
          <div className={styles.heading}>
            <div className={styles.heading_flag}></div>

            <div className={styles.heading_name}>
              <div>
                <p>Product</p>
              </div>
            </div>

            <div className={styles.heading_population}>
              <div>
                <p>Quantity</p>
              </div>
            </div>

            <div className={styles.heading_population}>
              <div>
                <p>Price</p>
              </div>
            </div>

            <div className={styles.heading_gini}>
              <div>Taxable</div>
            </div>
          </div>

          {products?.map((product, index) => {
            const foundedProducts = itemsInOrder?.find(
              (cartProduct) => cartProduct?.id === product?.id
            );

            return (
              <React.Fragment key={product?.id}>
                {index === 0 ? <hr /> : null}
                <div className={styles.row}>
                  <div className={styles.flag}>
                    <img
                      src={product?.image ? product?.image : placeholder}
                      alt={product?.image}
                      className="img-fluid"
                    />
                  </div>

                  <div className={styles.name}>
                    <p>{product?.name}</p>
                  </div>

                  <div className={styles.population}>
                    {(showQuantity &&
                      itemId === product.id &&
                      foundedProducts?.id === product?.id) ||
                    foundedProducts?.id === product?.id ? (
                      <div className="quantity buttons_added">
                        <ModalQuantityInput
                          itemsInOrder={itemsInOrder}
                          setItemsInOrder={setItemsInOrder}
                          foundedProduct={foundedProducts}
                        />
                      </div>
                    ) : (
                      <Button
                        onClick={() => {
                          setShowQuantity(true);
                          setItemId(product.id);
                          product.quantity = product.box_quantity;
                          setItemsInOrder([...itemsInOrder, product]);
                          addToast(
                            `${product?.box_quantity} ${product?.name} added to order`,
                            {
                              appearance: "success",
                              autoDismiss: true,
                              autoDismissTimeout: 2000,
                            }
                          );
                        }}
                        variant="btn btn-outline-success rounded w-50 mx-auto notInTableButton"
                      >
                        Add to order
                      </Button>
                    )}
                  </div>

                  <div className={styles.population}>
                    {authToken ? (
                      <p>
                        {" "}
                        {"CAD "}
                        {(
                          product?.price -
                          product?.price * (product?.special / 100)
                        ).toFixed(2)}{" "}
                      </p>
                    ) : (
                      <p>Login to see price</p>
                    )}
                  </div>

                  <div className={styles.gini}>
                    {product?.sales_tax_code === "G" ? "Yes" : "No"}
                  </div>
                </div>
                <hr />
              </React.Fragment>
            );
          })}
        </>
      );
    }
  };

  const renderBillingForm = () => {
    return (
      <Form className={styles.billingForm}>
        <h5 className={styles.billingHeading}>Billing Details</h5>
        <Form.Row>
          <Form.Group md={6} as={Col} sm={12} controlId="formGridCompanyName">
            <Form.Label>Company name (optional)</Form.Label>
            <Form.Control
              className={styles.myInput}
              placeholder="Company name"
              value={checkoutState.companyName}
              onChange={(e) =>
                setCheckoutState({
                  ...checkoutState,
                  companyName: e.target.value,
                })
              }
            />
          </Form.Group>

          <Form.Group md={6} as={Col} sm={12} controlId="formGridCountry">
            <Form.Label>Country</Form.Label>
            <Form.Control
              className={styles.myInput}
              type="text"
              placeholder="Country"
              value={checkoutState.country}
              disabled
            />
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col} sm={12} controlId="formGridAddress">
            <Form.Label>Address</Form.Label>
            <Form.Control
              className={styles.myInput}
              placeholder="1234 Main St"
              value={checkoutState.address}
              onChange={(e) =>
                setCheckoutState({ ...checkoutState, address: e.target.value })
              }
            />
          </Form.Group>
          <Form.Group as={Col} sm={12} controlId="formGridCity">
            <Form.Label>Town / City</Form.Label>
            <Form.Control
              className={styles.myInput}
              placeholder="Town / City"
              value={checkoutState.townCity}
              onChange={(e) =>
                setCheckoutState({ ...checkoutState, townCity: e.target.value })
              }
            />
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group md={6} as={Col} sm={12} controlId="formGridState">
            <Form.Label>State</Form.Label>
            <Form.Control
              className={styles.myInput}
              placeholder="State"
              value={checkoutState.state}
              onChange={(e) =>
                setCheckoutState({ ...checkoutState, state: e.target.value })
              }
            />
          </Form.Group>

          <Form.Group md={6} as={Col} sm={12} controlId="formGridZip">
            <Form.Label>Zip</Form.Label>
            <Form.Control
              className={styles.myInput}
              placeholder="ZIP"
              value={checkoutState.zip}
              onChange={(e) =>
                setCheckoutState({
                  ...checkoutState,
                  zip: e.target.value.replace(/\D/g, ""),
                })
              }
            />
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col} sm={12} controlId="formGridEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control
              className={styles.myInput}
              type="email"
              placeholder="Email"
              value={checkoutState.email}
              onChange={(e) =>
                setCheckoutState({ ...checkoutState, email: e.target.value })
              }
            />
          </Form.Group>

          <Form.Group as={Col} sm={12} controlId="formGridPhone">
            <Form.Label>Phone</Form.Label>
            <Form.Control
              className={styles.myInput}
              type="text"
              placeholder="Phone"
              value={checkoutState.phone}
              onChange={(e) =>
                setCheckoutState({
                  ...checkoutState,
                  phone: e.target.value.replace(/\D/g, ""),
                })
              }
            />
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col} sm={12} controlId="formTextArea">
            <Form.Label>Order notes (optional)</Form.Label>
            <Form.Control
              className={styles.myInput}
              as="textarea"
              rows={3}
              placeholder="Notes about your order, e.g. special notes for delivery."
              value={checkoutState.orderNotes}
              onChange={(e) =>
                setCheckoutState({
                  ...checkoutState,
                  orderNotes: e.target.value,
                })
              }
            />
          </Form.Group>
        </Form.Row>

        <Button
          variant="btn btn-outline-success rounded w-100 notInTableButton"
          disabled={isDisabled}
          onClick={() => {
            if (deletedProducts.length > 0) {
              callDelete();
            }
            if (editedProducts.length > 0) {
              callEditQuantity();
            }
            if (difference.length > 0) {
              callAddToOrder();
            }

            dispatch(
              editOrderBillingDetailsAttempt({
                data_to_server: {
                  ...checkoutState,
                  price: itemsInOrder
                    ?.map((item) => {
                      let pom_price = item?.special
                        ? item?.price - item?.price * (item.special / 100)
                        : item?.price;
                      return item?.sales_tax_code === "G"
                        ? pom_price * item?.quantity +
                            pom_price * item?.quantity * 0.05
                        : pom_price * item?.quantity;
                    })
                    ?.reduce((acc, next) => acc + next, 0),
                },
                orderId: location?.singleOrder?.id,
              })
            );
          }}
        >
          Confirm changes
        </Button>
      </Form>
    );
  };

  const renderPrice = () => {
    let totalPrice = itemsInOrder
      ?.map((item) => {
        let pom_price = item?.special
          ? item?.price - item?.price * (item.special / 100)
          : item?.price;
        return pom_price * item?.quantity;
      })
      ?.reduce((acc, next) => acc + next, 0);

    let grandTotalPrice = itemsInOrder
      ?.map((item) => {
        let pom_price = item?.special
          ? item?.price - item?.price * (item.special / 100)
          : item?.price;
        return item?.sales_tax_code === "G"
          ? pom_price * item?.quantity + pom_price * item?.quantity * 0.05
          : pom_price * item?.quantity;
      })
      ?.reduce((acc, next) => acc + next, 0);

    return (
      <>
        <h5 className={styles.totalPriceHeading}>
          Estimated total {totalPrice?.toFixed(2)}
        </h5>
        <h5 className={styles.totalPriceHeading}>
          Tax {(grandTotalPrice - totalPrice)?.toFixed(2)}
        </h5>
        <h5 className={styles.totalPriceHeading}>
          Grand Total: CA$ {grandTotalPrice?.toFixed(2)}
        </h5>
      </>
    );
  };

  let editedProducts = location?.singleOrder?.purchased_items?.filter(function (
    item
  ) {
    return (
      itemsInOrder?.map((item) => item?.id)?.indexOf(item?.product?.id) > -1
    );
  });

  const changedQuantity = () => {
    return editedProducts?.map((product) => {
      const foundedProducts = itemsInOrder.find(
        (item) => item.id === product?.product?.id
      );
      if (product?.product?.id === foundedProducts?.id) {
        return {
          ...product,
          quantity: foundedProducts?.quantity,
        };
      } else {
        return product;
      }
    });
  };

  const callEditQuantity = () => {
    return changedQuantity().map((item) => {
      return dispatch(
        editOrderChangeQuantityAttempt({
          itemId: item?.id,
          data_to_server: {
            quantity: item?.quantity,
          },
        })
      );
    });
  };

  let difference = itemsInOrder?.filter(function (item) {
    return (
      location?.singleOrder?.purchased_items
        ?.map((item) => item?.product?.id)
        ?.indexOf(item?.id) === -1
    );
  });

  const callAddToOrder = () => {
    dispatch(
      editOrderAddToOrderAttempt({
        orderId: location?.singleOrder?.id,
        data_to_server: {
          price: itemsInOrder
            ?.map((item) =>
              item?.sales_tax_code === "G"
                ? item?.price * item?.quantity +
                  item?.price * item?.quantity * 0.05
                : item?.price * item?.quantity
            )
            ?.reduce((acc, next) => acc + next, 0),
          purchased_items: difference?.map((item) => {
            return {
              id: item?.id,
              quantity: item?.quantity,
            };
          }),
        },
      })
    );
  };

  useEffect(() => {
    if (editOrderBillingDetailsSuccess) {
      addToast(`Order edited succesfully`, {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 2000,
        onDismiss: () => {
          dispatch(resetOrderEditing());
          history.goBack();
        },
      });
    }
  }, [editOrderBillingDetailsSuccess]);

  return (
    <div className={styles.checkoutScreen}>
      {renderModal()}
      <Container>
        <Row>
          <Col md={7} sm={12}>
            <SiteMap
              hrefIn={`/order/${location?.singleOrder?.email}`}
              items={navLinks}
            />
            <div className={styles.cartContainer}>
              <div className="d-flex align-items-center mb-3">
                <h4 className={styles.shoppingCartHeading}>Ordered items</h4>
                <span
                  className={styles.itemsInCartHeaderSpan}
                  onClick={onOpenModal}
                >
                  Add more items to order
                </span>
              </div>
              <h6>
                Notes: You canot delete all items from order, and changes will
                be saved once you click on 'Confirm changes'
              </h6>
              {renderCartItems()}
              {authToken ? renderPrice() : null}
            </div>
          </Col>
          <Col>
            <div className={styles.billingFormContainer}>
              {renderBillingForm()}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default CartScreen;
